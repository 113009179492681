<template>
	<div>
    
		<div class="top">
			<div class="top_left">点击紫色文本，选择近义词即可替换</div>
			<div class="top_right_btn">恢复原文</div>	
				
			
		</div>


		<!-- 内容 -->
		<div class="content" >
			<!-- <div v-for="(item,index) in similarArr" :key="index"
				style="margin: 2rpx;position: relative;display: inline-block;">
				<span @click.stop="openDialog($event,index)" :class="item.hasSynonym? 'red': ''"
					style="white-space: nowrap" v-if="item.word != '，|br，'">{{item.word}}</span>
				<div :class="dialogStyle" v-show="isShowDialog && currentClickIndex==index">
					<div class="dialog_top">
						<div
							:class="wordItem == similarArr[index].word? 'choose_color dialog_item': 'dialog_item'"
							v-for="(wordItem,wordIndex) in similarWordArr" :key="wordIndex"
							@click.stop="chooseWord(wordIndex)">{{wordItem}}</div>
					</div>
					<div class="dialog_bottom">
						<div class="recover_all btn_size" @click="recoverOldWord">恢复</div>
						<div class="close_dialog btn_size" @click="closeDialog">取消</div>
					</div>
				</div>
			</div> -->
		</div>

		

		<!-- 按钮区 -->
		<div class="bottom_btn">
			<div class="cancle" @click="closeSimilarWordPage">取消</div>
			<div class="success_btn" :class="channel == 'fromWX'? 'wx_btn_bgc' : 'app_btn_bgc'" @click="comfireUSe">确认使用</div>
		</div>

		<!--弹窗-->
		<div class="toastCon">
			<div class="upCon">
				<div class="upItem" v-for="count in 4" :key="count">文案</div>
			</div>
			<div class="downCon">
				恢复原文案
			</div>
				
			
		</div>
	</div>
</template>
<script type="text/javascript" src="//js.cdn.aliyun.dcloud.net.cn/dev/uni-app/uni.webview.0.1.52.js"></script>
<script>
	import {
		editor
	} from "../api/server.js"
	import wx from 'weixin-js-sdk'
	import { Toast } from 'vant';
	import { Dialog } from 'vant';
	export default {
    components: {
    },
		data() {
			return {
				windowHeight: 0,
				text: "",
				brand: "",
				similarArr: [],
				isShowDialog: false,
				currentClickIndex: null,
				similarWordArr: [],
				dialogStyle: '',
				originPage: "",
				channel: '',
			}
		},
		mounted() {
			this.channel = localStorage.getItem('channel');
			this.isSimilarWord();
			this.windowHeight = (document.documentElement.clientHeight * 0.7) + "px";
		},
		methods: {
			isSimilarWord() {  
				Toast.loading({message: "加载中...",duration: 0,loadingType: 'spinner'})
				let that = this;
				let param = {
					text: localStorage.getItem("content")
					// text: "这个坐姿豪放靠泡面度日的女人即将成为豪门阔太这个女人叫福子她恶补艺术知识与富太雅真侃侃而谈临走还夸富太真漂亮"
				}
				editor.isSimilarWord(param).then(res => {
					Toast.clear();
					if(res.data.data.freeVipBuy) {
						Dialog.confirm({
							title: '提示',
							message: res.data.data.msg || "该功能为付费会员专属功能，请升级超级会员",
							confirmButtonText: res.data.data.confirmText || "去升级"
						}).then(() => {
							if(localStorage.getItem("channel") == "fromWX") {
								wx.miniProgram.redirectTo({
									url: "/pages/priceOptionMonth/priceOption"
								})
							}else {
								uni.redirectTo({
									url: "/pages/buyVip/buyVip"
								})
							}
						}).catch(() => {
								window.history.go(-1);
						});
						return
					}
					for(var i = 0; i< res.data.data.length; i++) {
						if(res.data.data[i].word == "，" && res.data.data[i+1].word == "|") {
							res.data.data[i].word = res.data.data[i].word + res.data.data[i+1].word + 
																			res.data.data[i+2].word + res.data.data[i+3].word
							res.data.data.splice(i+1,1);
							res.data.data.splice(i+1,1);
							res.data.data.splice(i+1,1);
							// res.data.data.splice(i+1,1);
						}
					}
					console.log("xxxxxxxxxxxxxxxx",res.data.data);
					res.data.data.forEach(v => {
						v.oldWord = v.word;
					})
					this.similarArr = res.data.data;
				}).catch(err => {
					Toast.fail("网络异常")
				})
			},

			openDialog(e, i) {
				if (parseInt(e.clientX) > 210) {
					this.dialogStyle = 'dialog_box_right';
				} else {
					this.dialogStyle = 'dialog_box_left';
				}
				this.similarWordArr = [];
				if (this.similarArr[i].hasSynonym) {
					this.isShowDialog = !this.isShowDialog;
					this.currentClickIndex = i;
					let param = {
						text: this.similarArr[i].word
					}
					editor.SimilarWordReplace(param).then(res => {
						this.similarWordArr = res.data.data;
					})
				}
			},

			chooseWord(e) {
				let index = this.currentClickIndex;
				let newWord = this.similarWordArr[e];
				this.similarArr[index].word = newWord;
				this.isShowDialog = false;
			},

			recoverOldWord() {
				let index = this.currentClickIndex;
				let oldWord = this.similarArr[index].oldWord;
				this.similarArr[index].word = oldWord;
				this.isShowDialog = false;
			},

			closeDialog() {
				this.isShowDialog = false;
			},

			recoverAll() {
				this.text = localStorage.getItem('content');
				this.isSimilarWord();
			},

			comfireUSe() {
				let text = '';
				this.similarArr.forEach(v => {
					text += v.word;
				})
				if (text.length > 0) {
					localStorage.setItem('content', text);
					this.$emit("goFinish",100);
				}
			},

			closeSimilarWordPage() {
				this.$emit("goFinish",100);
			},

		}
	}
</script>

<style lang="less" scoped>
	/* pages/similarWord/similarWord.wxss */
	.top {
		margin:.9rem;
		height: 4.7rem;
		background: #E9E9E9;
		border-radius: 1.1rem;
		padding: 0 1.3rem 0 .9rem;
		display: flex;
		align-items: center;
		justify-content: space-between;
		box-sizing: border-box;
	}

	.top_left {
		font-size: 1.1rem;
		font-family: Microsoft YaHei-Regular, Microsoft YaHei;
		font-weight: 400;
		color: #666666;
		letter-spacing: .1rem;
	}

	.top_right_btn {
		width: 9.3rem;
		height: 2.8rem;
		line-height: 2.8rem;
		border-radius: 1.1rem;
		border: .1rem solid #212121;
		text-align: center;
		font-size: 1.3rem;
		font-family: Microsoft YaHei-Regular, Microsoft YaHei;
		font-weight: 400;
		color: #333333;
	}

	.recover_icon {
		width: 1.5rem;
		height: 1.5rem;
	}

	.content {
		padding:.6rem 1.8rem 1.2rem 1.9rem;
		width: 100%;
		height: calc(100vh - 12.7rem);
		box-sizing: border-box;
		font-size: 1.3rem;
		overflow-y: auto;
		white-space: wrap;
		background-color: #fff;
	}

	.bottom_btn {
		padding: 1rem 1.9rem 0 1.9rem;
		position: fixed;
		bottom: 0;
		left: 0;
		display: flex;
		justify-content: space-between;
		width: 100%;
		height: 7.1rem;
		background-color: #fff ;
		z-index: 1;
		box-sizing: border-box;
	}

	.cancle {
		width: 10.3rem;
		height: 3.7rem;
		line-height: 3.7rem;
		border-radius: 1.5rem;
		border: .1rem solid #212121;
		text-align: center;
		font-size: 1.3rem;
		font-family: Microsoft YaHei-Regular, Microsoft YaHei;
		font-weight: 400;
		color: #333333;
	}

	.success_btn {
		width: 18.7rem;
		height: 3.7rem;
		line-height: 3.7rem;
		background: #212121;
		border-radius: 1.5rem;
		text-align: center;
		font-size: 1.3rem;
		font-family: Microsoft YaHei-Regular, Microsoft YaHei;
		font-weight: 400;
		color: #FFFFFF;
		
	}

	.toastCon{
		position: absolute;
		top:20rem;
		left: 10rem;
		height: 7.7rem;
		border-radius: .7rem;
		background: #FFFFFF;
		box-shadow: 0 .3rem .6rem .1rem rgba(0,0,0,0.16);
		.upCon{
			height: 4.5rem;
			padding:1.1rem .2rem 1.1rem .9rem;
			background: linear-gradient(132deg, #C3E0F1 0%, #FFFFFF 100%);
			border-radius: .7rem .7rem 0 0 ;
			display: flex;
			.upItem{
				height: 2.2rem;
				line-height: 2.2rem;
				background: #FFFFFF;
				border-radius: .6rem;
				border: .1rem solid #212121;
				text-align: center;
				padding:0 .7rem;
				font-size: 1.3rem;
				font-family: Microsoft YaHei-Regular, Microsoft YaHei;
				font-weight: 400;
				color: #333333;
				margin-right: .7rem;
			}
		}

		.downCon{
			height: 3.2rem;
			width: 100%;
			background: transparent;
			border-radius: 0 0 .7rem .7rem ;
			padding-top:.8rem;
			text-align: center;
			box-sizing: border-box;
			font-size: 1.1rem;
			font-family: Microsoft YaHei-Regular, Microsoft YaHei;
			font-weight: 400;
			color: #333333;
			
		}

	}




	.red {
		color: #ff3e33;
		cursor: pointer;
	}

	.dialog_box_left {
		padding-bottom: 0.6rem;
		position: absolute;
		left: -30%;
		z-index: 10;
		width: 15rem;
		background-color: #fff;
		box-shadow: 0.1rem 0.35rem 0.9rem 0.15rem rgba(0, 0, 0, 0.4);
	}

	.dialog_box_right {
		padding-bottom: 0.6rem;
		position: absolute;
		right: -30%;
		z-index: 10;
		width: 15rem;
		background-color: #fff;
		box-shadow: 0.1rem 0.35rem 0.9rem 0.15rem rgba(0, 0, 0, 0.4);
	}

	.dialog_top {
		padding: 0.6rem 0.5rem 0.1rem 0.8rem;
		white-space: normal;
		width: 100%;
		box-sizing: border-box;
	}

	.dialog_item {
		display: inline-block;
		padding: 0 1.3rem;
		margin-right: 0.5rem;
		margin-bottom: 0.5rem;
		border: 1px solid #DADADA;
		border-radius: 0.3rem;
		height: 2.4rem;
		line-height: 2.4rem;
		white-space: nowrap;
	}

	.dialog_bottom {
		margin-top: 0.5rem;
		padding-right: 1.3rem;
		display: flex;
		justify-content: flex-end;
		width: 100%;
		box-sizing: border-box;
	}

	.btn_size {
		width: 4.1rem;
		height: 2.4rem;
		border-radius: 0.2rem;
		line-height: 2.4rem;
		text-align: center;
		font-size: 1.2rem;
	}

	.recover_all {
		margin-right: 0.6rem;
		background-color: #409EFF;
		color: #ffffff;
		border: 1px solid #409EFF;
	}

	.close_dialog {
		background-color: #e7f3ff;
		color: #409EFF;
		border: 1px solid #409EFF;
	}

	.choose_color {
		background-color: #07A300;
		border: 1px solid #07A300;
		color: #fff;
	}
</style>
