
export default {

    methods: {
        getTime(t) {                                //根据时间戳来解析返回格式为 xxxx.x.x xx:xx:xx 的格式
            const date = new Date(t);
            const year = date.getFullYear();
            const month = date.getMonth() + 1;
            const day = date.getDate();
            const hours = date.getHours();
            const minute = date.getMinutes();
            const seconds = date.getSeconds();
            return `${year}.${month}.${day} ${hours.toString().length > 1 ? hours : '0' + hours.toString()}:${minute.toString().length > 1 ? minute : '0' + minute.toString()}:${seconds.toString().length > 1 ? seconds : '0' + seconds.toString()}`
        },
        getTimeSprit(t) {                                //根据时间戳来解析返回格式为 xxxx-x-x xx:xx 的格式
            const date = new Date(t);
            const year = date.getFullYear();
            const month = date.getMonth() + 1;
            const day = date.getDate();
            const hours = date.getHours();
            const minute = date.getMinutes();
            // const seconds = date.getSeconds();
            return `${year}-${month}-${day} ${hours.toString().length > 1 ? hours : '0' + hours.toString()}:${minute.toString().length > 1 ? minute : '0' + minute.toString()}`
        },
        getTimeYearMonthSun(t) {                                //根据时间戳来解析返回格式为 xxxx.x.x  的格式
            const date = new Date(t);
            const year = date.getFullYear();
            const month = date.getMonth() + 1;
            const day = date.getDate();
            return `${year}年${month}月${day}日`
        },
        getTimeWhenNo(t) {                                //根据时间戳来解析返回格式为 xxxx.x.x  的格式
            const date = new Date(t);
            const year = date.getFullYear();
            const month = date.getMonth() + 1;
            const day = date.getDate();
            return `${year}.${month}.${day}`
        },
    },
}