<template>
    <div>
        <van-popup v-model="isshow" round position="bottom" :style="{ height: '57.7rem' }" >
            <div class="skillContainer" v-html="skillContent">
                

            </div>
        </van-popup>

    </div>
</template>

<script>
import { constants } from 'buffer';
import { editor } from '../../api/server';
export default {
    props:{
        show: {
            type: Boolean,
            default: false
        }
    },
    data(){
        return{ 
            skillContent:"",
            isshow: false
        }
    },
    watch: {
        show() {
            this.isshow = this.show;
            if(this.show) {
                this.getHtml();
                this.getWxLink();
                let that = this;
                setTimeout(function(){
                    let img1 = document.getElementById('skillClose');
                    let img2 = document.getElementById('skillOpen');
                    img1.addEventListener("click",that.toCloseToast);
                    img2.addEventListener("click",that.toPublic);
                },1000);
            }
        }
    },
    methods:{
        toCloseToast(){
            this.$emit('controlWriteSkillToast');
        },
        getHtml(){
            editor.writingSkills().then(res=>{
                if(res.data.code == 0 ){
                    this.skillContent = res.data.data;
                }
            })
        },
        getWxLink() {
            editor.getWxLink().then(res => {
                this.wxLink = res.data.data.serviceNumberUrl;
            })
        },
        toPublic(){
            this.$emit("open",true);
            window.open(this.wxLink,'_blank');
            // let a = document.createElement("a");
            // a.href = this.wxLink;
            // a.click()
        }
    },
    mounted(){
        this.getHtml();
        this.getWxLink();
    }
}
</script>

<style lang="less" scoped>
    .skillContainer{
        width: 100%;
        height: 100%;
        background: linear-gradient(180deg, #FFE8C5 0%, #FFFFFF 100%);
    }
</style>