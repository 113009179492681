<template>
    <div>
			<div :class="gtoastClass">
			<img :src="toastImg" style="width: 2.2rem;height: 2.2rem;margin-right: .6rem;" />
			{{toastMsg}}
		</div>
		
	</div>
</template>

<script>
export default {
    props:['toastImg','toastMsg','gtoastClass'],
    data(){
        return{
			
    }
}
}
</script>

<style lang="less" scoped>
.cartActToast{
		white-space: nowrap;
		background: #000000;
		border-radius: 1.6rem;
		opacity: 0.75;
		backdrop-filter: blur(2rem);
		display: flex;
		flex-wrap: nowrap;
		align-items: center;
		justify-content: center;
		padding:1.5rem;
		font-size:1.5rem;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #FFFFFF;
		position: absolute;
		top:50%;
		left: 50%;
		transform: translate(-50%,-50%);
		z-index:9999999;
		
		
	}
	.showToast{
		animation:showToastAni 1.5s ;
	}
	.hiddenToast{
		animation:hiddenToastAni 1.5s;
	}
	@keyframes showToastAni{
		0%{opacity:0;}
		100%{opacity: 0.75;}
		
	}
	@keyframes hiddenToastAni{
		0%{opacity:0.75;}
		100%{opacity:0;}
		
	}

</style>

