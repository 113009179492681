<template>
  <div  class="po-container" >
        <div class="po-top">
            <div class="top-left cen">
                <img :src="isBlack?require('../../assets/img/poblishHelpBgBlack.png'):require('../../assets/img/poblishHelp.png')" @click.stop="controlTip">
                <div @click.stop="controlMoreType" :style="isBlack?'color: #FFFFFF;':''">
                    <span>{{gradeList[gradeChooseIndex].value}}</span>
                    <img :src="isBlack?require('../../assets/img/poblishMoreBgBlack.png'):require('../../assets/img/poblishMore.png')" class="more-type">
                </div>
                <div class="historyRecord" @click="history" :style="isBlack?'color: #FFFFFF;background: rgba(255,255,255,0.1);':''">历史记录</div>
            </div>
            <div class="top-right cen">
                <div class="keyboard" @click.stop="inputFocus" :style="isBlack?'opacity: 0.6;':''">
                    <img src="../../assets/img/keyboard.png">
                </div>
                <img :src="isBlack?require('../../assets/img/polishCloseBgBlack.png'):require('../../assets/img/verifyListClose.png')" @click.stop="toClose">
            </div>
        </div>
        <!-- <div class="po-center">
            <div class="cen-left">
                改写范围
            </div>
            <div class="cen-right cen"> -->
                <!-- <div class="free-choose" :style="selectIndex == 1? 'background: rgb(98,98,101);color: #FFFFFF;':''" @click.stop="selectIndex = 1">自选</div> -->
                <!-- <div class="whole-article" :style="selectIndex == 2? 'background: rgb(98,98,101);color: #FFFFFF;':''"  >全篇</div>
            </div>
        </div> -->
        <div class="po-main">
            <div class="main-start" v-if="startFlag">
                <img src="../../assets/img/poblishStart.png" alt="">
                <div class="start-btn" @click.stop="toStartPolish">开始改写</div>
            </div>
            <div class="main-search" v-if="searchFlag">
                <div class="search-box">
                    <img src="../../assets/img/searching.gif" >
                    <span>改写中</span>
                </div>
            </div>
            <div class="main-res" :style="isBlack? 'color: #fff': ''" v-if="resultFlag" v-html="polishContent" id="polishResult"></div>
        </div>

        <div class="po-bottom" v-if="bottomFlag">
            <div class="bo-replaceAll" v-if="replaceFlag" @click="toReplaceAll">
                <img src="../../assets/img/poblish-click.png" >
                <span>替换全部</span>
            </div>
            <div class="bo-select" v-if="selectFlag">
                <div class="btn1" @click="replacePolish">
                    <img src="../../assets/img/refresh.png" >
                    <span>换一换</span>
                </div>
                <div class="btn2" @click="toReplaceSelect">
                    <img src="../../assets/img/poblish-click.png" >
                    <span>替换所选</span>
                </div>
            </div>

        </div>
        <!-- 选择等级弹窗-->
        <div class="grade-toast" v-if="gradeToastFlag" >
           <div class="grade-item" v-for="(item,index) in gradeList" :key="index" @click.stop="gradeChooseControl(index)">
                {{item.value}}
           </div>
        </div>

        <!-- 功能说明弹窗 -->
        <div class="tip-toast" v-if="tipToastFlag">
            <div class="tip-up"></div>
            <div class="tip-down">
                改写功能可修饰文章语句，改写文章以增加文采。文案越多，改写效果越明显。请注意改写效果与文字数、文案内容有关，效果不明显可增加改写范围或修改文案内容。
            </div>
        </div>
  </div>
</template>

<script>
export default {
    props:['polishContent','poSearchFlag','controlPolishToast','isBlack'],
    watch:{
        poSearchFlag:{
            handler:function(){
                if(this.poSearchFlag){
                    this.startFlag = false;
                    this.searchFlag = true;
                    this.bottomFlag = true;
                }else{
                    this.searchFlag = false;
                    //显示校验的结果
                    if(this.polishContent){
                        this.resultFlag = true;
                        this.$nextTick(_=>{
                            this.addPoListener();
                        })
                    }else{
                        this.startFlag = true;
                        this.bottomFlag = false;
                    }
                }
            },
            deep:true
        },
        polishContent:{
            handler:function(){
                this.$nextTick(_=>{
                        this.addPoListener();
                   })
            },
            deep:true
        }

    },
    data(){
        return{
            startFlag:true,
            searchFlag:false,
            resultFlag:false,
            gradeToastFlag:false,
            tipToastFlag:false,
            bottomFlag:false,
            replaceFlag:true,
            selectFlag:false,
            selectIndex:2,
            gradeList:[
               {
                value:'基础改写'
               },
               {
                value:'通用改写'
               },
               {
                value:'超强改写'
               }
            ],
            gradeChooseIndex:0,
            chooseList:[]
        }
    },
    methods:{
        history(){
            this.$emit('history',);

        },
        toClose(){
            this.$emit('toClosePolish');
        },
        inputFocus(){
            this.$emit("focusMyEditor");
        },
        gradeChooseControl(index){
            localStorage.setItem('gradeChooseIndex',index);
            this.gradeChooseIndex = index;
            this.gradeToastFlag = false;
        },
        toStartPolish(){
            let chooseIndex = this.selectIndex;
            let level = this.gradeChooseIndex;
            
             this.$emit('getPolishContent',chooseIndex,level);
            
        },
        controlMoreType(){
            this.tipToastFlag = false;
            this.gradeToastFlag = !this.gradeToastFlag ;
        },
        controlTip(){
            this.gradeToastFlag = false;
            this.tipToastFlag = !this.tipToastFlag;
        },
        toReplaceAll(){
            if(!this.searchFlag){
                let arr = document.querySelectorAll('.poPara');
                let textList = [];
                for (let i = 0;i<arr.length;i++){
                    textList.push(arr[i].innerText);
                }
                this.$emit('toHandlerPoblish',1,textList);
            }
           
        },
        replacePolish(){
            if (this.searchFlag) return
            let content = "";
            let list = document.querySelectorAll(".poPara");
            this.chooseList.sort((a,b)=>{
                return a-b
            });
            this.chooseList.forEach(i=>{
                if(content == ""){
                    content =list[i].innerText;
                }else{
                    content = content +'\\n'+list[i].innerText;
                }
            })
            this.$emit('toHandlerPoblish',2,content,this.chooseList,this.gradeChooseIndex);
            let that = this;
            // setTimeout(()=>{
            //     that.$nextTick(_=>{
            //         let replaceList = document.querySelectorAll(".poPara");
            //         that.chooseList.forEach( i => {
            //             replaceList[i].style.background = "rgba(0,113,255,.13)";
            //         })
            //     })
            // },100)
        },
        toReplaceSelect(){
            let text  = document.querySelectorAll(".poPara");
            let list = [];
            for(let i = 0 ;  i<text.length; i++){
                list.push(text[i].innerText);
            }
            this.chooseList.sort((a,b)=>{
                return a-b
            });
            this.$emit('toHandlerPoblish',4,this.chooseList,list)
        },
        addPoListener(){
            let list = document.querySelectorAll('.poPara');
            let that = this;
            for(let i = 0;i<list.length;i++){
                
                list[i].onclick = function(e){
                    that.$emit('toHandlerPoblish',3,i);
                    let flag = false; 
                    that.chooseList.forEach((item,index)=>{
                        if(item == i){
                            flag = true;
                            that.chooseList.splice(index,1);
                            list[i].style.background = "transparent"
                        }
                    });
                    if(!flag){
                        that.chooseList.push(i);
                        list[i].style.background = "rgba(0,113,255,.13)";
                    }
                    if(that.chooseList.length > 0){
                        that.replaceFlag = false;
                        that.selectFlag = true;
                    }else{
                        that.replaceFlag = true;
                        that.selectFlag = false;
                    }
                    
                }
            }
        },
    },
    mounted(){
        console.log("当前的背景是",this.isBlack);
        this.gradeChooseIndex = localStorage.getItem('gradeChooseIndex')?localStorage.getItem('gradeChooseIndex'):'0';
    }
}
</script>

<style lang="less" scoped>
.po-container{
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 38.5rem;
    background: linear-gradient(135deg, rgba(162,136,239,.15) 0%, rgba( 168,217,233,.15) 100%);
    border-radius: 1.5rem 1.5rem 0 0;
    z-index: 999999;
}
.cen{
    display: flex;
    align-items: center;
}
.po-top{
    width: 100%;
    height: 5.9rem;
    padding: 0 2rem;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .top-left{
        font-size: 1.6rem;
        font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
        font-weight: bold;
        color: #333333;
       >img{
        width: 1.6rem;
        height: 1.6rem;
        margin-right:.5rem;
       }
      .more-type{
        width: 0.9rem;
        height: 0.6rem;
        margin-left: .3rem;
       }
       .historyRecord{
        width: 5.2rem;
        height: 2.2rem;
        background: rgba(33,33,33,0.1);
        border-radius: .8rem;
        font-size: 1rem;
        font-family: MicrosoftYaHei;
        color: #333333;
        text-align: center;
        line-height: 2.2rem;
        margin-left: .6rem;
       }

    }
    .top-right{
       
        .keyboard{
            width: 4.1rem;
            height: 3rem;
            background: #FFFFFF;
            border-radius: 1.2rem;
            padding:.8rem 1rem .6rem 1.1rem;
            box-sizing: border-box;
            margin-right: 1.4rem;
            >img{
                width: 2rem;
                height: 1.6rem;

            }
        }
        >img{
            width: 1.3rem;
            height: 0.8rem;

        }
    }

}
.po-center{
    width: 100%;
    height: 4.3rem;
    padding:0 2rem;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: rgba(255,255,255,0.3);
    .cen-left{
        font-size: 1.4rem;
        font-family: MicrosoftYaHei;
        color: #333333;
    }
    .cen-right{
  
        .free-choose,.whole-article{
            width: 4.3rem;
            height: 2rem;
            border-radius: 1rem;
            font-size: 1.3rem;
            font-family: MicrosoftYaHei;
            color: #333333;
            display: flex;
            align-items: center;
            justify-content: center;
        }
        .free-choose{
            margin-right:.4rem;
        }
        
    }
}
.po-main{
    width: 100%;
    height: 32.9rem;
    .main-start{
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        position: relative;
        >img{
            width: 20.1rem;
            height: 13.8rem;
            margin-top:6.9rem;
        }
        .start-btn{
            position: absolute;
            top:12rem;
            left: 50%;
            transform: translateX(-50%);
            width: 9.8rem;
            height: 3rem;
            background: linear-gradient(132deg, #CEC1F5 0%, #CEE3F3 100%);
            border-radius: 1.5rem;
            line-height: 3rem;
            text-align: center;
            font-size: 1.3rem;
            font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
            font-weight: bold;
            color: #333333;
        }
    }
    .main-search{
        width: 100%;
        height: 100%;
        font-size: 1.4rem;
        font-family: MicrosoftYaHei;
        color: #6E7C91;
        display: flex;
        justify-content: center;
        padding-top:2.7rem;
        box-sizing: border-box;
        .search-box{
            height: 1.9rem;
            >img{
                width: 1.6rem;
                height: 1.6rem;
                position: relative;
                left: 0;
                top: .5rem;
                margin-right: .9rem;
            }
        }
    }
    .main-res{
        height: 26.4rem;
        overflow-y: auto;
        padding:1.6rem 2rem;
        font-size: 1.4rem;
        font-family: MicrosoftYaHei;
        color: #333;
        line-height: 2.2rem;
        letter-spacing: .1rem;
    }

}
.po-bottom{
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 6.5rem;
    background: #FFFFFF;
    padding:.8rem 2rem 0 1.9rem;
    box-sizing: border-box;
    z-index:9999;
    .bo-replaceAll{
        width: 100%;
        height: 3.4rem;
        background: #000000;
        border-radius: .8rem;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 1.2rem;
        font-family: MicrosoftYaHei;
        color: #FFFFFF;
        >img{
            width: 1.4rem;
            height: 1.8rem;
            margin-right: .4rem;
        }
    }
    .bo-select{
        display: flex;
        justify-content: space-between;
        .btn1{
           
            background: #F9F9F9;
           
            color: #333333;
            >img{
                width: 1.4rem;
                height: 1.4rem;
                margin-right: .2rem;
            }
        }
        .btn2{
            
            background: #000000;
           
            color: #FFFFFF;
            >img{
                width: 1.4rem;
                height: 1.8rem;
                margin-right: .4rem;
            }
        }
        .btn1,.btn2{
            width: 15rem;
            height: 3.4rem;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: .8rem;
            font-size: 1.2rem;
            font-family: MicrosoftYaHei;
        }

    }
}
.grade-toast{
    width: 9.2rem;
    height: 11.7rem;
    background: #FFFFFF;
    border-radius: 1rem;
    padding: 0 .4rem;
    box-sizing: border-box;
    position: absolute;
    top:5rem;
    left: 2.5rem;
    z-index: 999;
    .grade-item{
        width: 100%;
        height: 3.8rem;
        display: flex;
        justify-content: center;
        align-items: center;
        border-bottom: .1rem solid #EEEEEE;
        font-size: 1.4rem;
        font-family: MicrosoftYaHei;
        color: #333333;
        cursor: pointer;
    }
    .grade-item:last-child{
        border-bottom: none;
    }
}
.tip-toast{
    .tip-up{
        position: absolute;
        top:4rem;
        left: 2rem;
        width: 0;
        height: 0;
        border-top: .8rem solid transparent;
        border-bottom:.8rem solid rgba(0,0,0,.75);
        border-left: .8rem solid transparent;
        border-right: .8rem solid transparent;


    }
    .tip-down{
        position: absolute;
        top:5.6rem;
        left: 1rem;
        width: 24.6rem;
        height: 8.4rem;
        background: rgba(0,0,0,.75);
        font-size: 1.2rem;
        font-family: MicrosoftYaHei;
        color: #FFFFFF;
        line-height: 1.6rem;
        padding:1rem .9rem 1rem .9rem;
        box-sizing: border-box;
        border-radius:1.1rem ;
        
    }
}
</style>    