<template>
    <div>
        <div class="main">
           
            <div class="header">
                <div class="tip">内含多个短视频平台敏感词库📕，让创作少违规，少限流！</div>
                <div class="recover">恢复原文</div>
            </div>
            <div class="center">
                
                    <div id="myEditor" class="eidtor" contenteditable="true" v-html="result" @click="divClick"></div>
                
                <!-- <div>
                    <textarea maxlength="4500" id="textarea" :value="text" @input="textInput"></textarea>
                    <div class="my_editor" id="myEditor" contenteditable="true" v-html="result"></div>
                </div> -->

            </div>

            <div class="bottomLine"></div>
            <div class="bottom-btn">
                <div class="my-btn-pain" :class="channel == 'fromWX' ? 'wx_btn_border1' : 'app_btn_border1'"
                    style="margin-right: 3.3rem;" @click="goBack">重新检测</div>
                <div class="my-btn" :class="channel == 'fromWX' ? 'wx_btn_bgc' : 'app_btn_bgc'" @click="goFinish">确认使用
                </div>
            </div>

            
                <!--建议修改的弹窗--><!--原文案的弹窗-->
                <div id="suggest" class="suggest" v-if="suggestToast" style="top:20rem;left:5rem">
                    <div class="suggestContent"  @click="toChangeBadWord('2')" :style="isSuggest?'':'background: linear-gradient(135deg, #BFBFBF 0%, #FFFFFF 100%);'">
                        <div class="content">{{isSuggest?userDefinedArr[0].customText:beforeContent}}</div>
                        <div class="label">{{isSuggest?'建议修改':'原文案'}}</div>
                    </div>
                    <div class="userDefined" @click="toUserDefined">自定义</div>
                </div>


                <!--自定义的弹窗-->
                <div class="userDefinedCon" v-show="useDefinedToast">
                    <div class="user-header">{{toastItem}}
                        <div class="user-label">敏感词</div>
                    </div>
                    <div>
                        <input type="text"  class="user-inp" placeholder="请输入字词" v-model="userDefinedInput"/>
                    </div>

                    <div class="user-line"></div>
                    <div class="user-btn" @click="toDefined">确定</div>
                    <div class="user-btn" @click="useDefinedToast = false;currentDom.style.background = '#fff';userDefinedInput=''">取消</div>


                </div>
        </div>
    </div>
</template>

<script>
import {
    editor
} from "../api/server.js"
import { ContactCard, Toast } from 'vant';
import {enterToDIv} from '../utils/editor.js'
export default {
    data() {
        return {
            text: "",
            result: "",
           
            isSuggest:false,
            useDefinedToast:false,
            suggestToast:false,   //控制自定义弹窗是否显示
            toastItem:"",
            userDefinedInput:"",
            userDefinedArr:[],
            currentDom:"", //保存点击敏感词的dom节点
            currentIndex:"",
            changeArr:[],
            beforeContent:"",
            channel: "",
            
        }
    },
    mounted() {
        this.channel = localStorage.getItem('channel');
        this.text = localStorage.getItem('content');
        this.text? this.filterBadWords():"";
    },
    methods: {

        //点击自定义显示自定义弹窗
        toUserDefined(){
            this.suggestToast = false;
            this.useDefinedToast = true;

        },
        //点击自定义确定修改文案
        toDefined(){
            let param={ 
                customText:this.userDefinedInput,
                masterName:this.toastItem
            }
            
            editor.userDefinedBad(param).then(res => {
              if(res.data.code === 0){
                // Toast.success("修改成功！");
                // console.log('修改成功');
                this.toChangeBadWord(1);
                
              }else{
                Toast.fail(res.data.msg);
              }
            }).catch(err =>{
                console.log("checkerr=", err);
                Toast.fail("网络异常");
            })
            this.useDefinedToast = false;
        },

        //判断敏感词是否自定过
        badIsChange(param,e){  
            editor.badWordCheck(param).then(res => {
                this.userDefinedArr = [...res.data.data];
                if(this.userDefinedArr.length !==0){//判断是否自定义过
                    this.isSuggest = true;
                    this.suggestToast = true;
                    this.badToastPosition(e);
                }else{                         
                    this.useDefinedToast = true;
                }    
            }).catch(err => {
                console.log("checkerr=", err);
                Toast.fail("网络异常");
            })
        },

        //修改敏感词
        toChangeBadWord(i){
            let content ;
            if(i==1){//自定义修改
                 content = this.userDefinedInput;
                 this.userDefinedInput='';
            }else{
                content = this.isSuggest? this.userDefinedArr[0].customText:this.beforeContent;
            }

            //保存修改前的文本
            let flag = false;
            if(this.changeArr.length!==0){
                this.changeArr.forEach((e,i,a)=>{
                    if(e.index == this.currentIndex){
                        a[i]={index:this.currentIndex,value:this.currentDom.innerText};
                        flag = true;
                    }
                })
                if(!flag){
                    this.changeArr.push({index:this.currentIndex,value:this.currentDom.innerText});
                }
            }else{
                this.changeArr.push({index:this.currentIndex,value:this.currentDom.innerText});
            }

            this.currentDom.innerHTML = content;
            //去掉背景色
            this.currentDom.style.color = "#333";
            this.currentDom.style.display = "inline-block";
            this.currentDom.style.borderBottom =".2rem #FF8000 solid";
            this.currentDom.style.background = "#fff";
            this.suggestToast = false;

        },
        //设置弹窗的位置
        badToastPosition(e){
            this.$nextTick(_=>{
                let b = e.target.getBoundingClientRect();
                let dom = document.getElementById('suggest');
                dom.style.top = (b.top+b.height+5)/10 +'rem';
                if(b.left>200&&b.left<220){
                    dom.style.left = '18rem';
                }
                else if(b.left>=220){
                    dom.style.left ='20rem';
                }
                else{
                    dom.style.left = (b.left-14)/10 +'rem';    
                }
                  
            })
        },
        //重新检测
        goBack() {
            let reg2 = /<br>/g;
		    let txt = document.getElementById('myEditor').innerHTML.replace(reg2,`<div  class="my_br"><div><span  style="display: block">，|br，</span></div></div>`);
            
            this.text = enterToDIv(txt);
            if (this.text) {
                this.filterBadWords();
            } else {
                Toast.fail("请输入检测内容");
            }
        },
        //确定使用
        goFinish() {
           
		   
            localStorage.setItem('content', document.getElementById('myEditor').innerText);
            this.$emit("goFinish", 100);    
        },
        //敏感词检测
        filterBadWords() {
            Toast.loading({
                message: '敏感词检测中...',
                forbidClick: true,
                duration: 0,
                loadingType: 'spinner'
            });
            editor.filterWords({
                text: this.text
            }).then(res => {
                Toast.clear();
                if (res) {
                    this.result = res.data.data.resultText;
                    this.$nextTick(_=>{
                        let arr = document.querySelectorAll("#myEditor span");
                        let that = this;
                        for (let i = 0;i<arr.length;i++){
                            arr[i].onclick = function(e){
                                //显示背景颜色
                                  arr[i].style.background = "#F6F280";
                                 that.toastItem = arr[i].innerText; //获取当前点击节点的敏感词
                                 that.currentDom = arr[i]; //保存当前点击的节点
                                 that.currentIndex = i;

                                // 判断是否修改过
                                let flag = false;
                                if(that.changeArr.length!==0){
                                    that.changeArr.forEach(i=>{
                                        if(i.index == that.currentIndex){
                                            that.isSuggest = false;
                                            that.beforeContent = i.value;
                                            that.suggestToast = true;
                                            that.badToastPosition(e);
                                            flag = true;
                                        }
                                    })
                                    if(!flag){
                                        that.badIsChange({masterName: that.toastItem},e);
                                    }
                                }else{
                                
                                    that.badIsChange({masterName: that.toastItem},e);
                                }
                            }
                        }   
                    })   
                } else {
                    Toast.fail("检测失败");
                }
            }).catch(err => {   
                console.log("checkerr=", err);
                Toast.clear();
                Toast.fail("网络异常");
            })
        },
        divClick(e){
            if(e.target.className){
                this.suggestToast = false;
            }

            
        }
    }
}
</script>

<style lang="less" scoped>
.my_editor {
    -webkit-user-modify: read-write-plaintext-only;
    -webkit-user-select: text;
    user-select: text;
}
.main {
    
    width: 100%;
    
}

.userDefinedCon{
    
    width: 23.3rem;
    height: 17.2rem;
    background: linear-gradient(180deg, #FFFCB2 0%, #FFFFFF 100%);
    box-shadow: 0 .3rem  .6rem .1rem rgba(0,0,0,0.16);
    border-radius: .7rem;
    padding-top:.9em;
    box-sizing: border-box;

    position: absolute;
    top:50%;
    left:50%;
    transform: translate(-50%,-50%);
   
    
    .user-header{
        height: 3.1rem;
        padding-left:.6rem;
        font-size: 1.5rem;
        font-family: Microsoft YaHei-Bold, Microsoft YaHei;
        font-weight: bold;
        color: #333333;
        display: flex;
        margin-left: 1.1rem;

        .user-label{
            height: 2rem;
            padding-top:.5rem;
            box-sizing: border-box;
            font-size: 1.1rem;
            font-family: Microsoft YaHei-Regular, Microsoft YaHei;
            font-weight: 400;
            color: #666;
            margin-left: 1.1rem;
        }

    }
    .user-inp{
        margin-left: 1.1rem;
        width: 21.1rem;
        height: 3rem;
        background: #FFFFFF;
        border-radius: .7rem;
        border: .1px solid #9A9A9A;
        padding-left: .6rem;

        font-size: 1.3rem;
        font-family: Microsoft YaHei-Regular, Microsoft YaHei;
        font-weight: 400;
        
    }
    .user-line{
        margin:.9rem .4rem .7rem;
        height: .1rem;
        background: #FEFEFE;
    }
    .user-btn{
        margin:0 1.1rem .6rem;
        height: 3.4rem;
        line-height: 3.4rem;
        border-radius: .7rem;
        border: .1rem solid #666;
        text-align: center;
        font-size: 1.3rem;
        font-family: Microsoft YaHei-Regular, Microsoft YaHei;
        font-weight: 400;
        color: #333333;
    }
}

.suggest{
        position: absolute;
       
        
        height: 6.5rem;
        background: #FFFFFF;
        box-shadow: 0 .3rem .6rem  .1rem rgba(0,0,0,0.16);
        border-radius: .7rem;
       

        .suggestContent{
            
            height: 50%;
            background: linear-gradient(135deg, #F5F17C 0%, #FFFFFF 100%);
            padding:.7rem .7rem 0 1.1rem;
            box-sizing: border-box;
            border-radius: .7rem .7rem 0 0;
            font-family: Microsoft YaHei-Bold, Microsoft YaHei;
            display: flex;
            
            

            .content{
                font-size: 1.5rem;
                font-weight: bold;
                color: #333333;
            }
            .label{
                margin:.2rem 0  0 4.5rem; 
                font-size: 1.3rem;
                font-weight: 400;
                color: #DCDCDC;
            }
        }

        .userDefined{
            height: 50%;
            padding:.7rem 0 0 1.1rem;
            font-size: 1.3rem;
            font-family: Microsoft YaHei-Regular, Microsoft YaHei;
            font-weight: 400;
            color: #333333;
        }
    }

.header{
    margin:.9rem;
    height: 4.7rem;
    background: #E9E9E9;
    border-radius: 1.1rem;
    padding: .9rem 1.3rem 0 .9rem;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;

    .tip{
        width: 18.6rem;
        height: 3rem;
        font-size: 1.1rem;
        font-family: Microsoft YaHei-Regular, Microsoft YaHei;
        font-weight: 400;
        color: #666666;
        letter-spacing: .1rem;
    }
    .recover{
        width: 9.3rem;
        height: 2.8rem;
        line-height: 2.8rem;
        border-radius: 1.1rem;
        border:.1rem solid #999;
        text-align: center;
        font-size: 1.3rem;
        font-family: Microsoft YaHei-Regular, Microsoft YaHei;
        font-weight: 400;
        color: #333333;
       
    }
}

.center {
    width: 100;
    height: calc(100vh - 14.2rem);
    padding:.8rem 1.8rem  1.7rem 1.8rem;
    box-sizing: border-box;
    background:#fff;

    .eidtor{
        width: 100%;
        height: 100%;
        font-size: 1.4rem;
        font-family: Microsoft YaHei-Regular, Microsoft YaHei;
        font-weight: 400;
        color: #333333;
        line-height: 2.1rem;
        overflow-y:auto;
    }
   
}






.bottomLine{
    width: 100%;
    height: .6rem;
    background: #FBFBFB;
    position: fixed;
    bottom:7.1rem;
}

.bottom-btn {
    width: 100%;
    height: 7.1rem;
    position: fixed;
    bottom: 0;
    padding:1.1rem 1.9rem 0 1.9rem;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;

}

.my-btn {

    width: 18.7rem;
    height: 3.7rem;
    line-height: 3.7rem;
    background: #212121;
    border-radius: 1.5rem;
    text-align: center;
    font-size: 1.3rem;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
    color: #FFFFFF;
}

.my-btn-pain {

    width: 10.3rem;
    height: 3.7rem;
    line-height: 3.7rem;
    border-radius: 1.5rem;
    border: .1rem solid #212121;
    text-align: center;
    font-size: 1.3rem;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
    color: #333333;

   
}
</style>
