<template>
  <div>
    <div class="model" v-if="show" :style=" 'z-index:'+  z_index +';'">
      <div class="dialog">
        <div :class="image ? 'msg_box2' : 'msg_box'" v-html="text"></div>
        <div class="dialog_imgBox" v-if="image">
          <img class="dialog_img"  :src="image" alt="" :style="imageStyle">
        </div>
        <div class="bottom_btn">
          <div @click="cancel" class="btn" v-if="!isOneBtn" style="border-right: .1rem solid #E5E5E5;color: #333;">{{cancelText}}</div>
          <div @click="confirm" class="btn" :style="(isOneBtn? 'width: calc(100% /1);': '') + 'color:' + confirmTextColor +';'">{{confirmText}}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      text: {
        type: String,
        default: ""
      },
      show: {
        type: Boolean,
        default: false
      },
      isOneBtn: {
        type: Boolean,
        default: false
      },
      confirmText: {
        type: String,
        default: "确定"
      },
      cancelText: {
        type: String,
        default: "取消"
      },
      confirmTextColor:{
        type: String,
        default: "#FF4D4F"
      },
      z_index:{
        type:Number,
        default:999
      },
      image:{
        type: String,
        default: ""
      },
      imageStyle:{
        type: String,
        default: ""
      }
    },
    data() {
      return {}
    },
    methods: {
      cancel() {
        this.$emit("cancel");
      },
      confirm() {
        this.$emit("confirm");
      },
    },
    mounted() {}
  }
</script>

<style lang="less" scoped>
  .model {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
   
    background-color: rgba(0, 0, 0, .65);
    .dialog {
      position: absolute;
      top: 50%;
      left: 50%;
      margin-left: -12.5rem;
      margin-top: -6.1rem;
      width: 25rem;
      min-height: 12.2rem;
      background-color: #c5c5c5;
      border-radius: .8rem;
      .dialog_imgBox{
        display: flex;
        justify-content: center;
        align-items: center;
        .dialog_img{
          width: 9.1rem;
          height: 6.9rem;
        }
      }
      .msg_box2 {
        display: flex;
        align-items: center;
        justify-content: center;
        padding-top: 1.5rem;
        padding-bottom: 1rem;
        width: 100%;
        box-sizing: border-box;
        font-size: 1.4rem;
        color: #333;
      }
      .msg_box {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 1.5rem;
        width: 100%;
        min-height: 7.8rem;
        box-sizing: border-box;
        
        font-size: 1.4rem;
        color: #333;
      }
      .bottom_btn {
        display: flex;
        align-items: center;
        width: 100%;
        border-top: .1rem solid #E5E5E5;
        .btn {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 50%;
          height: 4.4rem;
          font-size: 1.6rem;
          color: #FF4D4F;
        }
      }
    }
  }
</style>