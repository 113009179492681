<template>
    <div class="FeeContainer">
        <div class="topSearch">
            <div class="searchIcon" @click="toSearchFee">
                <img src="../assets/img/feeSearchIcon.png">
            </div>
            <input type="text" placeholder="搜索我的词库" v-model="FeeSearchInputTxt">
            <div class="searchClear" @click="toClearInput" v-if="FeeSearchInputTxt">
                <img src="../assets/img/feeSearchClose.png">
            </div>
        </div>

        <div class="feePanel" @scroll="scrollEvent">
            <div class="feeList" v-for="(item,index) in dataList" :key="index">
                <div>{{item}}</div>
                <div @click="removeFeeOf(item,index)">移除</div>
            </div>
            <div v-if=" !dataList.length"  class="feeEmpty">暂无文本被添加到免改词库中</div>
        </div>
        <myDialog  v-show="comfirmToastIsShow" :text="toastText" :show="comfirmToastIsShow" :confirmText="confirmText" @cancel="cancel" @confirm="confirm"></myDialog>

        <globalToast v-if="globalToastIsShow" :toastImg="gToastImg" :toastMsg="gToastMsg" :gtoastClass="gtoastClass"></globalToast>
       
    </div>
</template>

<script>
import { editor } from "../api/server.js";
import myDialog from "../components/myDialog/myDialog"
import globalToast from "../components/globalToast/globalToast.vue"

export default {
    components:{myDialog,globalToast},
    data(){
        return{
            page:1,
            size:10,
            totalCount:0,
            dataList:[],
            tempList:[],
            FeeSearchInputTxt:'',
            comfirmToastIsShow:false,
            toastText:'您确定要移除以下项目吗？',
            confirmText: '移除',
            removeWord:'',
            globalToastIsShow:false,
            gToastImg:'',
            gToastMsg:'',
            gtoastClass:'',
            isSearching:false,
            isLoading:false,
            removeIndex:""

        }
    },
    methods:{

        scrollEvent(e){
            console.log();
            if(e.srcElement.scrollTop + e.srcElement.clientHeight == e.srcElement.scrollHeight ){ 
                if(this.isLoading) return; 
                if(this.totalCount  == this.dataList.length) return;
                this.page = this.page + 1;
                this.getFeeWordList();
            }
        },
        

        getFeeWordList(){
           
            let param ={
                page:this.page,
                size:this.size
            }
            this.isLoading = true;
            editor.getNotModifyWord(param).then(res => {
                if(res.data.code==0){
                    this.totalCount = res.data.data.total;
                    this.dataList = [...this.dataList,...res.data.data.list];
                    
                }else{
                    this.toShowGlobalToast(1,"获取免改词库失败");
                }

            }).catch(err =>{
                console.log('err=',err);
            })
            this.isLoading = false;
        },
        toSearchFee(){

            if(!this.FeeSearchInputTxt) {
                this.globalToastIsShow = true;  
                this.toShowGlobalToast(1,"请输入搜索内容");
                return;  
            } 
            
            while(this.totalCount !== this.dataList.length){
                this.page = this.page + 1;
                this.getFeeWordList();
            }
            let reg = RegExp(this.FeeSearchInputTxt);
          
            let arr =this.dataList.filter((item)=>{
                return reg.test(item);
            });
            if(arr.length==0){
                this.globalToastIsShow = true;  
                this.toShowGlobalToast(1,"免改词库中没有改词");
                return;
            }
            
            this.tempList = this.dataList;
            this.dataList = arr;
            this.isSearching = true;
           


        },
        toClearInput(){
            this.FeeSearchInputTxt = "";
            this.getFeeWordList();
        },
        removeFeeOf(words,index){
            this.removeWord = words;
            this.toastText = `<div><div  style=" width: 100%;height: 1.9rem;text-align: center;margin:1.5rem 0 1rem 0;">您确定要移除以下项目吗？</div>
                                <div style=" width: 100%;text-align: center;">[${words}]</div></div>`;
            this.removeIndex = index;
            this.comfirmToastIsShow = true;
            

        },
         //微笑弹窗
         toShowGlobalToast(img,msg){
                if(img === 1){//失败
                  this.gToastImg = require('../assets/img/failure.png');
                }else{
                  this.gToastImg = require('../assets/img/success.png');
                }
                this.gToastMsg = msg;
                this.gtoastClass='cartActToast showToast';
                this.gToastIshow = true;
                this.$nextTick(_=>{
                  let that = this ;
                    setTimeout(function(){
                        that.gtoastClass='cartActToast hiddenToast'
                          setTimeout(function(){
                            that.globalToastIsShow = false ;
                          },1500);
                    },1500);
                })

          },
        cancel(){
            this.comfirmToastIsShow = false;
            if(this.isSearching== true){
                this.dataList = this.tempList;
                this.isSearching = false;
            }
            

        },
        confirm(){
            editor.removeModifyWord({text:this.removeWord}).then(res => {
                if(res.data.code==0){
                    this.globalToastIsShow = true;
                    this.toShowGlobalToast(2,'移除成功！');
                    this.dataList.splice(this.removeIndex,1);
                    // this.getFeeWordList();
                }else{
                    this.toShowGlobalToast(1,"移除失败");
                }
            })
            this.comfirmToastIsShow = false;
        }

    },
    mounted(){
        this.getFeeWordList();
    }
}
</script>

<style lang="less" scoped>

    .FeeContainer{
        position: relative;
        width: 100%;
        height: 100vh;
        position: fixed;
        top: 0;
        background: linear-gradient(225deg, rgb(254,243,251) 0%, rgb(232,241,246) 100%);
        .topSearch{
            width: 100%;
            height: 4.3rem;
            padding:1.1rem 2rem 0 2rem;
            box-sizing: border-box;
            margin-bottom: .4rem;
            position: relative;
            input{
                width: 33.5rem;
                height: 3.2rem;
                background: #FFFFFF;
                border-radius: 1.6rem;
                font-size: 1.4rem;
                font-family: MicrosoftYaHei;
                color: #333333;
                padding-left: 3rem;
                box-sizing: border-box;
            }
            .searchIcon{
                position: absolute;
                top:2.1rem;
                left: 3rem;
                img{
                    width: 1.2rem;
                    height: 1.2rem;
                }
            }
            .searchClear{
                position: absolute;
                top:2.1rem;
                left:33.3rem;
                img{
                    width: 1.2rem;
                    height: 1.2rem;
                }
            }
        }

        .feePanel{
            height:calc(100vh - 4.7rem);
            overflow-y: auto;
            .feeList{
                width: 100%;
                height: 6.9rem;
                padding:0 2rem;
                box-sizing: border-box;
                display: flex;
                justify-content: space-between;
                align-items: center;
                border-bottom: .1rem solid #EEEEEE;
                font-size: 1.4rem;
                font-family: MicrosoftYaHei;
                color: #333333;
            }
            .feeEmpty{
                width: 100%;
                height: 7rem;
                line-height: 7rem;
                text-align: center;
                font-size: 1.6rem;
            }

        }
      
    }
</style>