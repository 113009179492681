<!--
 * @Description:首页
 * @Author: yyq
 * @Date: 2021-10-29 17:00:00
 * @LastEditors  : yyq
 * @LastEditTime : 2021-10-29 17:00:00
-->

<template>
	<div>
		<div class="mask_box" v-if="!isPhoneFlag">
      <div class="content_box">
        <img src="../assets/img/open_at_phone.png" alt="">
        <div class="content_desc" style="margin-top: 1rem;">
          请前往<span style="font-size: 2.2rem;color: #FA6400;margin: 0 .4rem">移动端</span>打开
        </div>
      </div>
    </div>
		<home @copy="myCopyText" v-if="!isOpenWechatFlag"  @topMenuClick="topMenuClick($event)"></home>
		<similar-word v-if="pageIndex == 1" @goFinish="goFinish($event)"></similar-word>
		<bad-word-ckeck v-if="pageIndex == 2" @goFinish="goFinish($event)"></bad-word-ckeck>
		<global-toast v-if="globalToastIsShow" :toastImg="gToastImg" :toastMsg="gToastMsg" :gtoastClass="gtoastClass"></global-toast>

		<!-- 全局的加载动画 -->
		<my-loading :show="isOpenWechatFlag" loading_text="加载中"></my-loading>

		      <!-- 第一次保存弹窗 -->
			<div class="tanchuangBox" v-if="save">
    		    <div class="tanchuangBox_top">
    		      <img src="../assets/img/fangdabaocun.png" alt="">
    		    </div>
				<div  class="middleBox">
					<div class="tanchuangBox_middle"> 
						<div class="tanchuangBox_middle_1">
							手动保存版本
						</div>
						<div class="tanchuangBox_middle_2">
							记得手动保存！可通过版本找回编辑记录哦~
						</div>
    		    	</div>
				</div>
    		    <div class="confirm_box">
					<div class="confirm_click" @click="saveRemind">  
						知道了
					</div>
    		    </div>
    		  </div>
			</div>
</template>

<script>
	import {audio,editor} from "../api/server.js"
	import globalToast from "../components/globalToast/globalToast.vue"
	import home from "./index1.vue";
	import badWordCkeck from "./badWordCkeck.vue";
	import similarWord from "./similarWord.vue";
	import { Toast } from 'vant';
	import Clipboard from "clipboard";
	import myLoading from "../components/myLoading/myLoading.vue";
	import {
		TextToHtml,
		myInsertTimer,
		changeInsertTimerStyle,
		getText,
		getTextBefore,
		changeDuoyinStyle,
		delectOneStyle,
		computeAmount,
		newChangeInsertTimerval,
		myChangeSpeed,
		myInsertErHuaYin,
		isTextContainBr,
		enterToDIv,
		getNodeTxtwhenDeleteSpeed,
		openChooseModeDialog,
		getNodeTxtwhenDeleteReadNumber,
		replaceSpeedVal
} from "../utils/editor.js"
	export default {
		data() {
			return {
				pageIndex: 100,
				globalToastIsShow: false,
				gToastImg: require('../assets/img/success.png'),
				gToastMsg: "",
				gtoastClass: "",
				timers:"" , 
				isOpenWechatFlag: true,
				save:false,
				isPhoneFlag: true,
			}
		},
		components: {
			home,
			badWordCkeck,
			similarWord,
			globalToast,
			myLoading
		},
		methods: {
			saveRemind(){
				this.save = false;
				audio.updateGuidance().then( res => {

				})
			},
			saveGain(){
				audio.getGuidance().then( res => {
					// console.log('res111' ,res.data.data);
					
					this.save  = res.data.data;
				})
			},
			topMenuClick(e) {
				this.pageIndex = e;
			},
			goFinish(e) {
				this.pageIndex = e;
			},

			// 统一的复制
			myCopyText(o) {
			let dom = document.getElementById(o.id);
			dom.setAttribute("data-clipboard-text",`${o.text}`);
						var clipboard = new Clipboard(o.className);
						clipboard.on('success', e => {
							if(this.timers!==""){
								clearTimeout(this.timers);
								this.globalToastIsShow = false;
								console.log("清除定时器")
							}
							this.gToastMsg = o.msg;
							this.globalToastIsShow = true;
							this.gtoastClass='cartActToast showToast';
							this.timers = setTimeout(_ =>{
								this.gtoastClass='cartActToast hiddenToast';
								setTimeout(_ => {
									this.globalToastIsShow = false ;
								},1500);
							},1600);
							// 释放内存
							clipboard.destroy()
							return 1;
						})
						clipboard.on('error', e => {
							// 释放内存
							clipboard.destroy();
						})
		},
			
		},
		created() {
		},
		mounted() {
			if(!localStorage.getItem("searchStrFlag")) {
				let userAgentInfo = navigator.userAgent;
				let Agents = ["Android", "iPhone","SymbianOS", "Windows Phone","iPad", "iPod"];
				let flag = false;
				for (let v = 0; v < Agents.length; v++) {
					if (userAgentInfo.indexOf(Agents[v]) > 0) {
						flag = true;
						break;
					}
				}
				this.isPhoneFlag = flag;
				this.isOpenWechatFlag = false;
				if (!this.isPhoneFlag) {
					let url = `http://aiwrite.douge.club/?from=${localStorage.getItem("isShareToMakeMoneyParam")}`
					window.location.replace(url);
					return
				}
			}
    if(localStorage.getItem("isShareToMakeMoney") == 1) {
			console.log("首页");
			this.isOpenWechatFlag = true;
      editor.getH5ShareUrl({from: localStorage.getItem("isShareToMakeMoneyParam")}).then(res => {
        if(res.data) {
          window.location.replace(res.data.data)
        }
      })
      return
    }else {
			console.log("首页2222");
			this.isOpenWechatFlag = false;
		}
			this.saveGain()
		},
	}
</script>

<style lang="less" scoped>
/deep/.my_dialog .van-dialog__content {
	height: auto !important;
}
/deep/.my_dialog .van-dialog__footer {
	height: 0 !important;
}
/deep/.my_dialog .van-dialog {
	padding-top: 0;
	height: auto !important;
}
/deep/.van-dialog__footer {
	border-top: 0 !important;
}
/deep/.van-dialog__footer--buttons {
	height: 3rem !important;
}
/deep/.van-action-sheet__item {
	font-size: 1.4rem;
	// padding: 1.5rem;
	height: 4rem;
	border-bottom: 1px solid #dadada;
}
/deep/.van-action-sheet__item:last-child {
	font-size: 1.4rem;
	// padding: 1.5rem;
	height: 4rem;
	border-bottom: 0;
}
/deep/.van-action-sheet__cancel::before {
	background-color: #fff;
}
/deep/.van-action-sheet__cancel {
	font-size: 1.4rem;
	// padding: 1rem;
	height: 4rem;
}

	/deep/.uni-input-input {
		font-size: 20rpx;
		background: #fff;
	}
	
	/deep/.u-icon-minus,/deep/.u-icon-plus {
		width: 48rpx;
		background-color: #f5f7fa !important;
		border-radius: 0;
	}
	
	/deep/.u-number-input {
		margin: 0;
	}
	
	/deep/.u-numberbox {
		margin-left: 18rpx;
		border: 1px solid #f5f7fa;
		border-radius: 6rpx;
	}

	.content {
		// -webkit-touch-callout:none;
		// padding-bottom: 8rem;
		width: 100%;
    // height: 40rem;
    overflow: hidden;
    position: fixed;
    left: 0;
    top: 0;
		box-sizing: border-box;
		touch-action:none;
	}

	.nodata {
		width: 100%;

		// text-align: center;
		.nodata_icon_box {
			padding-top: 2rem;
			margin: 0px auto;
			width: 10.5rem;
			height: 12.5rem;
		}

		.nodata_icon {
			width: 100%;
			height: 100%;
		}

		.nodata_text {
			margin-top: 0.25;
			width: 100%;
			text-align: center;
		}
	}

	.top_box {
		padding: .5rem 0 .5rem 0;
		display: flex;
		width: 100%;
		background-color: #fff;
		.top_item {
			position: relative;
			text-align: center;
			width: 25%;
			.css_sanjiao {
				position: absolute;
				bottom: -2.2rem;
				left: 50%;
				margin-left: -0.6rem;
				background-color: #fff;
				z-index: 96;
				width: 1.2rem;
				height: 1.2rem;
				transform: rotate(45deg);
				box-shadow: 0px 0px 0.3rem 0.2rem rgba(0, 0, 0, 0.2);
			}
			.insert_time_popup {
				position: absolute;
				bottom: -9.6rem;
				left: -6rem;
				width: 29.5rem;
				height: 8rem;
				box-shadow: 0.1rem 0.2rem 0.3rem 0.1rem rgba(0,0,0,0.2);
				background-color: #fff;
				border-radius: 0.3rem;
				z-index: 99;
				.popup_top {
					display: flex;
					align-items: center;
					padding-top: 0.6rem;
					padding-left: 0.5rem;
					.munber_input_box {
						margin-left: 0.8rem;
						display: flex;
						justify-content: center;
						height: 2.4rem;
						width: 9.8rem;
						border: 1px solid #D3D3D3;
						border-radius: 0.3rem;
						overflow: hidden;
						.box_left,.box_right {
							display: flex;
							align-items: center;
							justify-content: center;
							width: 2.4rem;
							height: 2.4rem;
							box-sizing: border-box;
							background-color: #f5f7fa;
						}
						.num_input {
							text-align: center;
							width: 5rem;
							padding: 0.5rem;
						}
					}
					.timer_item {
						margin-right: 0.5rem;
						text-align: center;
						line-height: 2.4rem;
						width: 5rem;
						height: 2.4rem;
						border: 1px solid #DADADA;
						border-radius: 0.3rem;
						font-size: 1.2rem;
					}
					.split_line_y {
						margin-left: 0.3rem;
						width: 1px;
						height: 1.8rem;
						background-color: #DADADA;
					}
				}
				.popup_bottom {
					margin-top: 1.2rem;
					display: flex;
					justify-content: flex-end;
					padding-right: 1.1rem;
					.btn_size {
						text-align: center;
						line-height: 2.4rem;
						width: 5.6rem;
						height: 2.4rem;
						border-radius: 0.2rem;
						font-size: 1.2rem;
					}
					.insert_btn {
						margin-right: 0.9rem;
						background-color: #409EFF;
						color: #fff;
						border: 1px solid #409EFF;
					}
					.recover_val {
						background-color: #e7f3ff;
						border: 1px solid #409EFF;
						color: #409EFF;
					}
				}
			}
				z-index: 99;
			>img {
				width: 3.8rem;
				height: 3.8rem;
			}

			.item_name {
				font-size: 1.3rem;
			}
		}
	}

	.split_line {
		width: 100%;
		height: 0.5rem;
		background-color: #F9F9F9;
	}

	.editor_box {
		position: relative;
		margin: 0.7rem auto 0 auto;
		width: 36rem;
		// height: 40rem;
		// border: 1px solid red;
		background-color: #F9F9F9;
		border-radius: 0.6rem;

		.box_bottom {
			padding: 0.3rem 1.1rem 1.5rem 0;
			position: absolute;
			bottom: 0;
			right: 0;
			display: flex;
			justify-content: flex-end;
			width: 100%;
			z-index: 10;
		}

		.box_top {
			padding: 0.5rem 0.8rem;
			width: 100%;
			height: 50%;
			box-sizing: border-box;
			overflow-y: auto;
		}
	}

.view_text_box {
	-webkit-user-modify: read-write-plaintext-only;
	font-size: 1.5rem;
	line-height: 3.4rem;
	-webkit-user-select:text;
	user-select:text;
	-webkit-user-select: auto;
	outline: none;
	height:100vh;
	overflow-y: auto;
	width: 100%;
	box-sizing: border-box;
}
.view_text_box * {
	-webkit-user-select:text;
}

.positionBox {
	position: relative;
	margin: .7rem auto 0 auto;
	top: 7rem;
	left: 0;
	width: 36rem;
	background-color: #f9f9f9;
}

	.view_text_box:empty:before {
		// content:attr(placeholder);
		content: "\u70b9\u51fb\u6b64\u5904\uff0c\u5f00\u59cb\u8f93\u5165\u6216\u7c98\u8d34\u6587\u672c\u2c\u6700\u591a\u53ef\u8f93\u5165\u34\u35\u30\u30\u5b57";
		color: rgb(117, 117, 117);
	}

	.creat_info_box {
		margin-top: 1.7rem;
		// display: flex;
		// justify-content: space-between;
		padding: 0 0.6rem 0 0.8rem;
		// height: 4.4rem;
		width: 100%;
		.author,.speed_box {
			padding: 0 0.4rem;
			display: flex;
			align-items: center;
			justify-content: space-between;
			background-color: #F9F9F9;
			width: 100%;
			height: 100%;
			border-radius: 0.6rem;
			height: 4.4rem;
		}
	}

	.bottom_btn {
		position: fixed;
		left: 0;
		bottom: 0;
		display: flex;
		justify-content: space-between;
		width: 100%;
		padding: 1rem 0.8rem 0 0.8rem;
		background-color: #fff;
		height: 7rem;
		z-index: 99;
		.listen_btn {
			display: flex;
			align-items: center;
			justify-content: center;
			width: 12rem;
			height: 4rem;
			// background-color: #e8f7e8;
			border-radius: 0.6rem;
			// border: 0.2rem solid #1AAD19;
			font-size: 1.6rem;
			// color: #1AAD19;
			.icon_size {
				margin-right: 0.3rem;
				font-size: 2rem;
			}
		}
		.create_btn {
			display: flex;
			align-items: center;
			justify-content: center;
			color: #fff;
			font-size: 1.6rem;
			width: 21.2rem;
			height: 4rem;
			border-radius: 0.6rem;
			// background-color: #1AAD19;
		}
		.create_btn1 {
			display: flex;
			align-items: center;
			color: #fff;
			font-size: 1.6rem;
			width: 21.2rem;
			height: 4rem;
			border-radius: 0.6rem;
			// background-color: #1AAD19;
			.btn_a,.btn_b {
				height: 100%;
				line-height: 4rem;
				width: 50%;
				text-align: center;
			}
			.border-right {
				border-right: 1px solid #fff;
			}
		}
	}

	.view_text_box1 {
  position: relative;
  max-height: 46rem;
  // padding: 0.6rem 1.8rem 0 1.8rem;
  letter-spacing: 1px;
  font-size: 1.5rem;
  color: #333333;
  line-height: 3.4rem;
  overflow-y: auto;
  .view_input_style {
    font-size: 1.4rem;
    min-width: 1.4rem;
    border: 0;
  }
  .text_item {
    display: flex;
    align-items: center;
    height: 30px;
    // border: 1px solid red;
  }
}

.current_play_color {
  color: #1577ff;
	font-weight: 700;
}

.my_dialog_box {
	position: relative;
	width: 100%;
	color: #333;
	.clsoe_iocn {
		position: absolute;
		top: 1rem;
		right: 1rem;
	}
	.dialog_title,.dialog_title2 {
		font-size: 1.8rem;
		font-weight: 700;
		width: 100%;
		text-align: center;
	}
	.rich_text {
		margin-top: 0.8rem;
		padding: 0 1.2rem;
		text-align: center;
	}
	.dialog_img {
		margin: 0 auto;
		width: 12rem;
		height: 10rem;
		>img {
			width: 100%;
			height: 100%;
		}
	}
	.show_more,.hidden_more {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 100%;
		font-size: 1.3rem;
		color: #999999;
	}
	.dialog_desc {
		margin-top: 0.8rem;
		padding: 0 1.2rem;
		font-size: 1.3rem;
		color: #999999;
		text-align: center;
		box-sizing: border-box;
		width: 100%;
	}
	.desc2 {
		margin-top: .8rem;
		font-size: 1.3rem;
		text-align: center;
	}
	.dialog_btn {
		display: flex;
		align-items: center;
		height: 5rem;
		width: 100%;
		font-size: 1.8rem;
		border-top: 1px solid #E5E5E5;
		.cancle,.confirm {
			display: flex;
			justify-content: center;
			align-items: center;
			text-align: center;
			height: 5rem;
			width: 50%;
		}
	}
}

.tanchuangBox{
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 99999;

  background: rgba(0,0,0,0.65);
  .tanchuangBox_top{
    position: relative;
    img {
      position: absolute;
      right: 4.4rem;
      top: 1rem;
      width: 4.2rem;
      height: 4.2rem;

    }
  }
  .middleBox{
	position: relative;
  }
  .tanchuangBox_middle{
	background-image: url('../assets/img/fabubenji.png');
	background-repeat: no-repeat;
	background-size: contain;
	width: 26.2rem;
	height: 7rem;
	padding-left: 1.3rem;
	box-sizing: border-box;
	position: absolute;
	right: 1rem;
	top: 6rem;
	.tanchuangBox_middle_1{
		font-size: 1.6rem;
		font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
		font-weight: bold;
		color: #FFFFFF;
		padding-top: 1.6rem;
	}
	.tanchuangBox_middle_2{
		font-size: 1.2rem;
		font-family: MicrosoftYaHei;
		color: #FFFFFF;
		margin-top: 0.5rem;
	}
  }
  .confirm_box{
	position: relative;
	.confirm_click{
		display: flex;
		justify-content: center;
		align-items: center;
		font-size: 1.4rem;
		font-family: MicrosoftYaHei;
		color: #FFFFFF;
		border-radius: 1.7rem;
		border: 1px solid #FFFFFF;

		position: absolute;
		right: 10rem;
		top: 14rem;

		width: 7.6rem;
		height: 3.3rem;
	}
  }
}
.mask_box {
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  backdrop-filter: blur(.2rem);
  background: rgba(255,255,255,0.1);
  z-index: 9999999999;
  .content_box {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    font-size: 1.4rem;
    color: #333;
    >img {
      width: 10.8rem;
      height: 7.3rem;
    }
  }
}
</style>

