<template>
  <div class="con" id="con"
    :style="!isSimillarWordCheckFlag && !isBadWordCheckFlag ? 'padding-top: 6rem;background:#ffffff;' : 'padding-top: 0rem;background:#ffffff;'">

    <!-- 近义词的顶部 -->
    <div class="s_top" v-if="isSimillarWordCheckFlag" :style="SbtopBgList[bgIndex].value">
      <div class="top_left">点击蓝色文本，选择近义词即可替换</div>
      <div class="top_right_btn">
        <div @click="recoverOriginalText" class="top_right_btnText" :style="reBgColorList[bgIndex].value">恢复原文</div>
      </div>
    </div>

    <!-- 敏感词的顶部 -->
    <div class="header" v-if="isBadWordCheckFlag" :style="SbtopBgList[bgIndex].value">
      <div class="tip">内含多个短视频平台敏感词库<img src="../assets/img/book.png">让创作少违规，少限流！</div>

      <div class="recover">
        <div @click="recoverOriginalText(2)" :style="reBgColorList[bgIndex].value" class="recoverText">恢复原文</div>
      </div>
    </div>

    <!-- 敏感词的底部 -->
    <!-- <div class="bad-bottomLine"  v-if="isBadWordCheckFlag"></div> -->
    <div class="bad-bottom-btn" v-if="isBadWordCheckFlag">
      <div class="my-btn-pain" @click="goBack" :style="isBlack ? 'border:.1rem solid #eee;color:#fff' : ''">重新检测</div>
      <div class="my-btn" @click="badWordEnsureUse" :style="isBlack ? 'background:#eee;color:#333' : ''">确认使用
      </div>
    </div>

    <!-- 敏感词的弹窗 -->
    <!--建议修改的弹窗--><!--原文案的弹窗-->
    <div id="suggest" class="suggest" v-if="suggestToast" style="top:20rem;left:5rem">
      <div class="suggestContent" @click.stop="toChangeBadWord('2')"
        :style="isSuggest ? '' : 'background: linear-gradient(135deg, #BFBFBF 0%, #FFFFFF 100%);'">
        <div class="content">{{ isSuggest ? userDefinedArr[0].customText : beforeContent }}</div>
        <div class="label">{{ isSuggest ? '建议修改' : '原文案' }}</div>
      </div>
      <div class="userDefined" @click.stop="toUserDefined">自定义</div>
    </div>


    <!--自定义的弹窗-->
    <div id="badWordCheckId" class="userDefinedCon" v-if="useDefinedToast">
      <div class="user-header">{{ toastItem }}
        <div class="user-label">敏感词</div>
      </div>
      <div>
        <input type="text" @focus="badWordFocus" @blur="badWordBlur" class="user-inp" placeholder="请输入字词"
          v-model="userDefinedInput" />
      </div>

      <div class="user-line"></div>
      <div class="user-btn" @click="toDefined">确定</div>
      <div class="user-btn"
        @click="useDefinedToast = false; currentDom.style.background = 'transparent'; userDefinedInput = ''">取消</div>
    </div>



    <!-- 编辑器的底部  键盘未弹起 -->
    <div id="footer" class="footer"
      v-if="!verifyIsShow && !iskeybroadUp && !isSimillarWordCheckFlag && !isBadWordCheckFlag && !polishFlag">
      <div v-for="(item, index) in itemList" :key="index">
        <div class="footer_item"
          :style="isBlack ? 'color: #fff; border: .1rem solid #909090;background:#666;' : 'color: #333; border: .1rem solid #EEE;background:transparent;'"
          @click.stop="todo(index)">
          <div class="item_icon">
            <img :src="item.img" alt="">
          </div>
          <div class="item_name">{{ item.name }}</div>
        </div>
      </div>
      <div class="jianbian" :style="gradientList[bgIndex].value">
      </div>
      <div class="verifyCountDot" v-if="verifyDataList.length">{{ verifyDataList.length }}</div>
    </div>

    <!-- 编辑器的底部  键盘弹起 -->
    <div id="footer-resize" class="footer"
      v-if="!verifyIsShow && iskeybroadUp && !isSimillarWordCheckFlag && !isBadWordCheckFlag"
      style="height: 5.2rem;display: flex;align-items: center;">
      <div v-for="(item, index) in itemList" :key="index" @click.stop="todo(index)">
        <div class="footer_item"
          :style="item.name.length == 0 ? 'width:5rem;border:0' : item.name == '完成' ? 'color: #333333;border: 0;width:4.2rem;height:5.1rem;background:' + bgColorList[bgIndex].value + ';border-radius:0;position:fixed;right:-1.3rem;bottom:' + keyboardHeight * 10 + 'px;z-index:9999999;' : 'color: #333333; border: .1rem solid #EEEEEE;padding: 0 1.5rem;'">
          <div class="item_name" :style="isBlack ? 'color:#fff' : ''">{{ item.name }}</div>
        </div>
      </div>
      <div class="jianbian02"
        :style="gradientList[bgIndex].value + 'bottom:' + keyboardHeight * 10 + 'px;z-index:9999999;'">
      </div>
      <div class="verifyCountDot" style=" top:1rem; left:19.8rem" v-if="verifyDataList.length">{{ verifyDataList.length }}
      </div>
    </div>


    <div class="top_container" v-if="!isSimillarWordCheckFlag && !isBadWordCheckFlag" @click="polishFlag = false">
      <div class="left" @click="controlWriteSkillToast">
        <img :src="editorTopLeftImg" alt="写作技巧" style="height:2.4rem;width:6.8rem">
      </div>
      <div class="right">
        <div class="totalCount" :style="isBlack ? 'color:#fff' : 'color:#666'">{{ fontCount }}字</div>
        <div v-for="(item, index) in iconList" :key="index" @click="topHandler(index)">
          <img :src="item.img" class='iconType'>
        </div>
      </div>
    </div>
    <div class="title_box">
      <input type="text" maxlength="20" placeholder="文章标题" id="title-input" style="color:#333333">
    </div>
    <!-- 输入框 -->
    <div class="editor_box">
      <div res="myeditor" :adjust-position="false" @input="editInputChange($event)" :style="'color:' + settingObj.color + ';font-size: ' + settingObj.fontSize + 'rem;'
        + 'line-height: ' + settingObj.lineHeight + 'rem;' + 'background:  transparent ;'" id="editor"
        :contenteditable="isContenteditable" @focus="onFocus" @click="divClick" class="view_text_box" @keyup="keyup"
        @blur="onBlur" v-html="editorTxt">
      </div>
    </div>

    <!-- 文件夹名称 -->
    <van-popup v-model="newFolder" style="border-radius: 0.8rem " :style="{ height: '18.4rem;' }">
      <div class="FolderBox">
        <div class="FolderBox_top dja">
          文件夹名称
        </div>
        <div class="FolderBox_input dja">
          <input type="text" v-model="FolderName" placeholder="请输入文件夹名称" />
        </div>
        <div class="FolderBox_bottom">
          <div class="FolderBox_btn" style="border-right: .2rem solid #dedede;" @click="cancelAddFolder">
            取消
          </div>
          <div class="FolderBox_btn" style="color: #7254C9;" @click="addFolder">
            确定
          </div>
        </div>
      </div>
    </van-popup>


    <!-- 词典弹窗 -->
    <div class="history_box">
      <van-popup v-model="dictionariesPopup" round position="bottom" :style="{ height: '43.1rem' }">
        <div class="dictionaries_body">

          <div v-if="dictionaryTipLeft">
            <div class="tip01-top" style=" left: 15.5rem;"></div>
            <div class="tip01" style="left: 1.5rem;">在字典查询框里直接输入汉字可查询其拼音及详细解释。</div>
          </div>

          <div v-if="dictionaryTipRight">
            <div class="tip01-top" style=" left: 34.2rem;"></div>
            <div class="tip01" style="left: 19.7rem;">在AI查询框直接输入汉字，可查询与此条件相匹配的一系列结果。</div>
          </div>


          <div class="dictionaries_searchBox">
            <div class="dictionaries_searchBox_box">
              <div>
                <input type="text" @blur="inputBlur" @focus="inputFocus" placeholder="试试搜索 “比喻做事非常顺利”"
                  v-model="dictionariesSearchBox" id="dictionariesSearchBox">
              </div>
              <div class="dic-serchBtn" @click="searchBox" @touchstart="touchHandler(1)" @touchend="touchHandler(1)"
                :style="dicSeachIsTouch ? 'background:#575757' : ''">
                搜索
              </div>
            </div>
          </div>
          <div class="dictionaries_top">
            <div @click="dictionariesTopClikc(0)"
              :class="dictionariesTopIndex == 0 ? 'dictionaries_top_box2' : 'dictionaries_top_box'">
              <div>
                查字典
              </div>
              <div @click="dictionaryTipLeft = !dictionaryTipLeft">
                <img :src="dicIcon1" @touchstart="touchHandler(4, 1)" @touchend="touchHandler(5)">
              </div>
            </div>
            <div @click="dictionariesTopClikc(1)"
              :class="dictionariesTopIndex == 1 ? 'dictionaries_top_box2' : 'dictionaries_top_box'">
              <div>
                AI反查
              </div>
              <div @click="dictionaryTipRight = !dictionaryTipRight">
                <img :src="dicIcon2" @touchstart="touchHandler(4, 2)" @touchend="touchHandler(5)">
              </div>
            </div>
          </div>
          <div class="dictionarier_content">
            <div class="contrastBox_imgBox dja" v-if="dictionaryIsLoading">
              <img src="../assets/img/topmoren.png" alt="">
            </div>

            <div class="dicitonaryIsSearch" v-if="dicitonaryIsSearch">
              <img src="../assets/img/searching.gif" alt="" style="width:1.9rem;height:1.9rem;margin-right: 1rem;">
              <span class="searchingTxt">正在搜索中</span>
            </div>

            <div class="dictionaryEmpty" v-if="dictionaryEmptyIsShow">
              <img src="../assets/img/dictionaryEmpty.png" class="dicEmptyImg">
              <div>没有找到 “<span style="color:#7254C9">{{ dictionaryEmptyText }}</span> ”相关的结果</div>
            </div>

            <div v-if="dictionariesTopIndex == 0">
              <div class="contrastBox" v-if="dictionariesArr.length > 0">
                <div class="contrastBox_list" style="border-bottom: .1rem  solid  rgba(33,33,33,.1);">
                  <div class="contrastBox_list_body" style="margin-bottom: 4.1rem;">
                    拼音 [ {{ dictionariesArr[0].pinyin }} ]
                  </div>
                  <div class="contrastBox_list_copy ">
                    <div class="dja copyText0" @touchstart="touchHandler(2, -4)" @touchend="touchHandler(3)"
                      :style="dicCopyIsTouch == -4 ? 'border: .1rem solid #CCC;color: #CCC;' : ''"
                      @click="copy(dictionariesArr[0].pinyin, '.copyText0', 'copyDictionaryContent0')"
                      id="copyDictionaryContent0">
                      复制
                    </div>
                  </div>
                </div>
                <div class="contrastBox_list">
                  <div class="contrastBox_list_body" v-html="dictionariesArr[0].definition">

                  </div>
                  <div class="contrastBox_list_copy ">
                    <div class="dja copyText1" @touchstart="touchHandler(2, -3)" @touchend="touchHandler(3)"
                      :style="dicCopyIsTouch == -3 ? 'border: .1rem solid #CCC;color: #CCC;margin-top:1.8rem' : 'margin-top:1.8rem'"
                      @click="copy(dictionariesArr[0].definition, '.copyText1', 'copyDictionaryContent1')"
                      id="copyDictionaryContent1">
                      复制
                    </div>
                  </div>
                </div>
              </div>
            </div>


            <div v-if="dictionariesTopIndex == 1">
              <div class="dictionariesBox" v-if="antonymArr.length > 0">
                <div class="dictionariesBox_list" :key="index" v-for="(item, index) in antonymArr">
                  <div style="width:25.1rem">
                    <div class="dictionariesBox_list_top">
                      {{ item.word }}
                    </div>
                    <div class="dictionariesBox_list_body">
                      {{ item.definition }}
                    </div>
                  </div>
                  <div class="dictionariesBox_list_copy ">
                    <div class="dja" :class="'copyText' + index" @touchstart="touchHandler(2, index)"
                      @touchend="touchHandler(3)"
                      :style="dicCopyIsTouch == index ? 'border: .1rem solid #CCC;color: #CCC;' : ''"
                      @click="copy(item.word + '\n' + item.definition, '.copyText' + index, 'copyDictionaryContent' + index)"
                      :id="'copyDictionaryContent' + index">
                      复制
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>
          <div style="height: 6.1rem;position: fixed;bottom: 0;left: 0rem;background-color: #fff;width: 100%;">
            <div v-if="!isInputFoucsFlag" class="dictionaries_bottom" @click="dictionariesPopup = false"
              :style="dicCancleIsTouch" @touchstart="touchHandler(6)" @touchend="touchHandler(7)">
              取消
            </div>
          </div>
        </div>
      </van-popup>

    </div>

    <!-- 续写 -->
    <van-popup v-model="continuationSwitch" round position="bottom" :style="{ height: '40.5rem' }">
      <div class="continuationBox">
        <div class="continuationBox_top">
          <div class="continuationBox_top_left_biaoti dja">
            <div>续写</div>
            <div class="dja" @click="historyRecord">历史记录</div>
          </div>
          <div class="continuationBox_top_right_img dja">
            <div class="dja" @click="focusAgain">
              <img src="../assets/img/jianpan.png" alt="" />
            </div>
            <div class="dja" @click="continuationSwitch = false">
              <img src="../assets/img/xiasanjioa.png" alt="" />
            </div>
          </div>
        </div>

        <div class="continuationBox_Text_length" @click="textLength">
          <div>
            文本长度
          </div>
          <div class="dja">
            <div>
              {{ startTextLength }}-{{ endTextLength }}
            </div>
            <div class="dja">
              <img src="../assets/img/daoshanjioa.png" alt="">
            </div>
          </div>
        </div>


        <div class="continuationBox_beginContinuation" :class="generateResult ? '' : 'dja'">
          <img src="../assets/img/xuxiebeijingtanchuang.png" alt="" v-if="continuationCondition == 1">
          <div class="xuxie dja" @click="shouSuoXuXie" v-if="continuationCondition == 1">
            开始续写
          </div>

          <div class="shengchengzho" v-if="continuationCondition == 2">
            <div class="dja">
              <img src="../assets/img/searching.gif" alt="">
            </div>
            <div>
              续写中
            </div>
          </div>

          <div class="wenben" v-if="continuationCondition == 3">
            <div v-html="generateResult" class="neirong" v-if="continuationCondition == 3">

            </div>

            <div class="bottom_bottom" v-if="continuationCondition == 3">
              <div class="dja" @click="shouSuoXuXie">
                <div class="dja">
                  <img src="../assets/img/_refresh.png" alt="">
                </div>
                <div class="dja">
                  换一换
                </div>
              </div>
              <div class="dja" @click="replaceText">
                <div class="dja">
                  <img src="../assets/img/shouzhuang.png" alt="">
                </div>
                <div class="dja">
                  我要用
                </div>
              </div>
            </div>
          </div>

        </div>

      </div>
    </van-popup>

    <!-- 续写空文本提示 -->
    <my-dialog @cancel="cancelHint" @confirm="confirmHint" :show="isShowDialogHintText" :text="'请先输入文案'"
      :cancelText="'取消'" :confirmText="'确定'" :z_index="9999999" confirmTextColor="#7254c9"></my-dialog>

    <!-- 续写二次确认弹窗 -->
    <my-dialog @cancel="cancelSearch" @confirm="confirmSearch" :show="isShowDialogSearch" :text="dialogSearchText"
      :cancelText="'取消'" :confirmText="'确定'" :z_index="9999999" confirmTextColor="#7254c9"></my-dialog>

    <!-- 续写二次金币不够弹窗 -->
    <my-dialog @cancel="cancelgold" imageStyle="" :image="jingBiImage" @confirm="confirmgold" :show="isShowDialoggold"
      :text="continuationContent" :cancelText="continuationCancelText" :confirmText="continuationConfirmText"
      :z_index="9999999" confirmTextColor="#7254c9"></my-dialog>

    <!-- 文本长度 -->
    <van-popup v-model="continuationText" round position="bottom" :style="{ height: '30.5rem' }">
      <div>
        <van-picker @cancel="textCancel" @confirm="textConfirm" show-toolbar confirm-button-text :columns="textColumns"
          bind:change="onChange" title="文本长度" />
      </div>
    </van-popup>


    <!--其他按钮的弹窗-->
    <van-popup v-model="otherIsShow" round position="bottom" :style="{ height: '35.5rem' }">
      <div class="otherContainer">
        <div class="otherPanel mycopybtn" v-for="(item, index) in otherLists" :key="index"
          @click="index != 4 ? popupHandler(3, index) : copyFullText()" :id="index == 4 ? 'mycopybtn' : ''">
          <img :src="item.img" alt="" class="otherIcon">
          <div class="otherName">{{ item.name }}</div>
        </div>
        <div class="line"></div>
        <div class="cancle" @click="otherIsShow = false">取消</div>
      </div>

    </van-popup>
    <!--导出弹窗-->
    <van-popup v-model="exportIsShow" round position="bottom" :style="{ height: '22.4rem' }">
      <div class="exportContainer">
        <div class="top"> 导出
          <div class="cancle" @click="popupClose(1)">&#215;</div>
        </div>
        <div class="exportPanle" :class="'copylink' + index" :id="'file' + index" v-for="(item, index) in exportLists"
          :key="index" @click="copyLink(index)">
          <div class="name" @click="popupHandler(5, index)">{{ item.name }}</div>
        </div>
      </div>
    </van-popup>

    <!--编辑配置弹窗-->
    <van-popup v-model="editConfiIsShow" round position="bottom" :style="{ height: '25.3rem' }">
      <div class="editConfiContainer">
        <div class="top">
          <div class="reset" @click="onSliderChange(5)">重置</div>
          编辑配置
          <div class="cancle" @click="popupClose(0)">&#215;</div>
        </div>
        <div class="editConfiPanel" v-for="(item, index) in editConfiLists" :key="index" @click="popupHandler(4, index)">
          <div class="name">
            <span>{{ item.name }}</span>
            <span style="position: absolute;left:9rem ">{{ index !== 2 ? sliderConfiger[index].value : '' }} </span>
            <div style="width: 16.8rem;margin-right:1.7rem" v-if="(index != 2)">
              <van-slider v-model="sliderValues[index].value" :step="sliderSteps[index].value" bar-height=".4rem"
                button-size="2.4rem" @input="onSliderChange(index, sliderValues[index].value)" @change="toSetConfiger" />
            </div>
            <div v-if="(index == 2)" style="display:flex;margin-right:1.7rem;">
              <div v-for="(it, ind) in bgColorList" :key="ind">

                <div class="choosebtn"
                  :style="bgIndex == ind ? 'background: linear-gradient(132deg, #A288EF 0%, #A8D9E9 100%);' : ''"
                  @click="onSliderChange(6, ind)">
                  <div class="bgCenter">
                    <div class="bgbtn" :style="'background:' + it.value + ';'"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
    </van-popup>

    <!--移动位置弹窗-->
    <van-popup v-model="moveLocationIsShow" @close="textClose" round position="bottom" :style="{ height: '49rem' }">
      <div class="moveContainer">
        <div class="top"> 移动位置
          <div class="cancle" @click="popupClose(2)">&#215;</div>
        </div>
        <div class="fileScroll" style="height:36.8rem;overflow-y:auto;margin-top:1rem" @scroll="scrollEvent">
          <!-- <div class="blank_box" v-if="fileLists.length==0"><img class="blank_icon" src="../assets/img/topmoren.png" alt="" ></div>   -->
          <div class="movePanle" :style="pitchOnFolderIndex == index ? 'background: #eee;' : ''"
            v-for="(item, index) in fileLists" :key="index" @click="popupHandler(6, index)">
            <img src="../assets/img/folder-close.png" class="iconType">
            <div class="textCon">
              <div class="name">{{ item.fileName }}</div>
              <div class="date">{{ getTimeSprit(item.updateDate) }}</div>
            </div>
          </div>
        </div>
        <div class="bottomLine"></div>
        <div class="moveBottom">
          <div class="createFile" @click="addFolderPopUp">新建文件夹</div>
          <div class="moveTo" @click="moveFolder">移动至</div>
        </div>

      </div>
    </van-popup>

    <!--校验弹窗-->
    <div class="verifyChangeToast" id="verifyChangeToast" v-if="isShowverifyChangeToast">
      <div class="ver-top" @click="toChangeVerifyWord('弹窗', '-1')">
        <div class="ver-text">{{ verifyToastContent }}</div>
        <div class="ver-label">改</div>
      </div>
      <div class="ver-ignore" @click="toIgnoreVerifyWord('弹窗', '-1')">忽略</div>
      <div class="ver-line"></div>
      <div class="ver-fee" @click="showFeeofChange(1)">设为免改词</div>
    </div>

    <!-- 近义词替换的弹窗 -->
    <!-- <div class="toastCon" v-if="isSimillarWordCheckFlag">
        <div class="upCon">
          <div class="upItem" v-for="count in 4" :key="count">文案</div>
        </div>
        <div class="downCon">
          恢复原文案
        </div>
      </div> -->


    <Verify @toCloseVerifyList="toCloseVerifyList" @focusMyEditor="focusAgain" @errorCode="errorCode"
      v-show="verifyIsShow" @showFeeofChange="showFeeofChange" @todo="todo" :verifyDataList="verifyDataList"
      @clickVerifyList="clickVerifyList" @toChangeVerifyWord="toChangeVerifyWord" @toIgnoreVerifyWord="toIgnoreVerifyWord"
      :isShow="verifyIsShow"></Verify>

    <FeeOfChange v-if="isFeeofChange" :word="feeOfWord" @toFeeofChange="toFeeofChange"></FeeOfChange>
    <!-- 近义词底部按钮 -->
    <div class="bottom_btn" v-show="isSimillarWordCheckFlag">
      <div class="cancle" id="simillarWordBtn" @click="cancleUseSimillarWordResult"
        :style="isBlack ? 'border:.1rem solid #eee;color:#fff' : ''">取消</div>
      <div class="success_btn" @click="confirmUseSimillarWordResult" :style="isBlack ? 'background:#eee;color:#333' : ''">
        确认使用
      </div>
    </div>

    <!-- 统一的加载弹窗 -->
    <my-loading :show="isShowLoadingFlag" :loading_text="loadingText"></my-loading>

    <!-- 统一的提示弹窗 -->
    <my-dialog @cancel="cancelEvent" @confirm="confirmEvent" :show="isShowDialogFlag" :text="dialogContent"
      :cancelText="dialogCancelText" :confirmText="dialogConfirmText"></my-dialog>

    <!-- 未登录弹窗 -->
    <my-dialog :z_index="999999" :image="weiDengLuImage" imageStyle="width: 10rem;height: 7.6rem;margin-bottom:0.9rem;"
      confirmTextColor="#7254c9" @confirm="confirmNotRegister" :show="notRegister" :text="notRegisterText"
      :isOneBtn="true"></my-dialog>

    <!-- 历史版本 -->
    <historyVersions @useText="updataText" v-if="isShowHistoryversionDialogFlag" @goFinish="goFinish($event)"
      @topHandler=topHandler></historyVersions>

    <!-- 改写，续写历史记录 -->
    <historyText v-if="historyTextSwitch" @copyPopup="copyPopup"></historyText>

    <!-- 笑脸弹窗 -->
    <GlobalToast :toastImg="gToastImg" :toastMsg="gToastMsg" :gtoastClass="gtoastClass" v-if="gToastIshow"></GlobalToast>

    <!-- 免改词库-->
    <FeeWordLibrary v-if="isShowFeeWordLibraryDialogFlag"></FeeWordLibrary>


    <!--  写作技巧的弹窗-->
    <WriteSkill @open="openWritingSkill" :show="isShowWriteSkill" @controlWriteSkillToast="controlWriteSkillToast">
    </WriteSkill>

    <!-- 改写的弹窗 -->
    <Polish v-if="polishFlag" @history="history" :style="controlPolishToast" :polishContent="polishContent"
      :isBlack="isBlack" :poSearchFlag="poSearchFlag" @toClosePolish="toClosePolish" @focusMyEditor="focusAgain"
      @getPolishContent="getPolishContent" @toHandlerPoblish="toHandlerPoblish"></Polish>

    <!-- 苹果生成文件链接的弹窗 -->
    <!-- <my-dialog-ios-copy @cancel="ioscancelEvent" @confirm="iosconfirmEvent" :show="isShowIosDialogFlag" :text="iosDialogContent" cancelText="取消" confirmText="复制"></my-dialog-ios-copy> -->
    <div class="model" v-if="isShowIosDialogFlag">
      <div class="dialog">
        <div class="msg_box" v-html="iosDialogContent"></div>
        <div class="bottom_btn1">
          <div @click="ioscancelEvent" class="btn" style="border-right: .1rem solid #E5E5E5;color: #333;">取消</div>
          <div @click="iosconfirmEvent" id="myIosCopyBtn" class="btn myIosCopyBtn">复制</div>
        </div>
      </div>
    </div>



  </div>
</template>

<script type="text/javascript" src="https://js.cdn.aliyun.dcloud.net.cn/dev/uni-app/uni.webview.1.5.2.js"></script>
<script>
var userAgent = navigator.userAgent;
var isAndroid = userAgent.indexOf('Android') > -1 || userAgent.indexOf('Adr') > -1;
import {
  editor,
  audio
} from "../api/server.js"
import wx from 'weixin-js-sdk'
import { Toast,Badge ,Dialog  , Loading } from 'vant';
import FeeOfChange from "../components/feeOfChange/feeOfChange.vue"
import myLoading from "../components/myLoading/myLoading.vue"
import myDialog from "../components/myDialog/myDialog.vue"
import historyVersions from './historyVersions.vue';
import FeeWordLibrary from './FeeWordLibrary.vue';
import GlobalToast from "../components/globalToast/globalToast.vue"
import WriteSkill from "../components/writeSkill/writeSkill.vue"
import Polish from "../components/polish/polish.vue"
import historyText from "./historyText.vue"
import {
  TextToHtml,
  getText,
  getTextBefore,
  enterToDIv,
  simillarWord,
  createSimillarWordItem,
  closeMyDialog
} from "../utils/editor.js"
import { myDecode, myEncode } from "../utils/mydecode.js"
import Verify from "../components/verify/verify"
import index from "../utils/public/public.js"
export default {
  mixins:[index],
  components:{Verify,FeeOfChange,myLoading,myDialog,historyVersions,GlobalToast,FeeWordLibrary,WriteSkill,Polish,historyText},
  data() {
    return {
      historyTextSwitch:false,


      mySetTimer: null,
      continuationSwitch:false,   // 续写 弹窗开关
      
      startTextLength:150,
      endTextLength:200,

      continuationText :false ,
      generateResult:'',
      textColumns2:[],
      textColumnsIndex:3,
      textColumns:[],                 //  文本长度便利数组
      isShowDialogSearch:false,       // 第二次确认弹窗
      dialogSearchText:'本次续写将消耗<span style="color:#FA6400;">0</span>金币',
      isShowDialogHintText:false,   //  续写输入文本
      continuationCondition:1,   //  续写 状态
      continuationCancelText:'',
      continuationConfirmText:'',
      continuationContent:'',
      isShowDialoggold:false,  // 续写金币弹窗
      notRegister:false,  //  未登录弹窗
      notRegisterText:'',

      jingBiImage:require('../assets/img/jinBiBuZu.png'),
      weiDengLuImage:require('../assets/img/weidenglu.png'),

      currentTarget: null,
      isSaveHtmlFlag: true,
      createIndex: null,
      iosDialogContent: "",
      isShowIosDialogFlag: false,
      timerClose:"",
      settingObj: {},
      tempVerifyOriginalText: "",
      isContenteditable: true,
      isShowWriteSkill:false,
      isShowFeeWordLibraryDialogFlag:false,
      gToastImg:'',
      gToastMsg:'',
      gToastIshow:false,
      gtoastClass:'cartActToast showToast',
      isShowHistoryversionDialogFlag: false,
      fontCount: 0,
      dictionariesPopup:false,
      dictionariesSearchBox:'',
      dictionariesTopIndex:1,
      dictionariesArr:[],
      antonymArr:[],
      isSimillarWordCheckFlag: false,
      iscanPasteFlag: true,

      pitchOnFolderIndex:null,

      newFolder:false,   //   新增文件夹
      FolderName:'',   // 文件名字

      isTextChangeFlag: false,
      selectionDirection: "",
      selectionText1: "",
      selectionText2: "",
      gradientList:[
        {
          value:'background: linear-gradient(90deg, rgba(255,255,255,0) 0%, #FFFFFF 100%);'
        },
        {
          value:'background: linear-gradient(90deg, rgba(255,254,239,0) 0%, #FFFEEF 100%);'
        },
        {
          value:'background: linear-gradient(90deg, rgba(244,255,235,0) 0%, #F4FFEB 100%);'
        },
        {
          value:'background: linear-gradient(90deg, rgba(207,232,204,0) 0%, #CFE8CC 100%);'
        },
        {
          value:'background: linear-gradient(90deg, rgba(235,234,239,0) 0%, #EBEAEF 100%);'
        },
        {
          value:'background: linear-gradient(90deg, rgba(38,38,38,0) 0%, #262626 100%);'
        },
      ],
      iconList:[{
        img:require('../assets/img/export.png')
      },{
        img:require('../assets/img/saveArticle.png')
      },{
        img:require('../assets/img/moreContent.png')
      }],
      itemList: [
        // {
        //   img:require('../assets/img/written.png'),
        //   name:'续写'
        // },
      {
        img:require('../assets/img/runse.png'),
        name:"改写"
      },
        {
        img: require('../assets/img/verify.png'),
        name: "校对"
      }, {
        img: require('../assets/img/Synonyms.png'),
        name: "近义词"
      }, {
        img: require('../assets/img/badWord.png'),
        name: "敏感词"
      }, {
        img: require('../assets/img/dictionary.png'),
        name: "词典"
      }],
      touchMoveDirection: "",
      editorTxt: "",
      
      isShowBottomMenuFlag: false,     // 标记底部操作栏的状态  true = 显示； false = 隐藏
      keyboardHeight: 0,
      tempGetselection: "",
      tempRange: "",
      beforeRangeTxt: "", //光标前的内容
      afterRangeTxt: "",   // 光标后的文本
      iskeybroadUp: false,   //标记键盘状态   true= 弹起； false=收起
      chooseIndex: null,  // 当前选中底部菜单的Index

      bottomAreaTxt: "",     // 底部操作区选中的文本
      selectionTxt: "",      // 选中的文本
      rangeIndex1: null,    // 选中文本光标开始的index1
      rangeIndex2: null,    // 选中文本光标开始的index2

      tampEditorText: "",
      tampSelectTxt: "",
      isEndFlag: false,

      otherIsShow:false,   //控制其他操作弹窗的显示
      otherLists:[
        {
          img: require('../assets/img/editorConfiger.png'),

          name:'编辑配置'
        },
        {
          img:require('../assets/img/otherExport.png'),
          name:'导出'
        },
        {
          img:require('../assets/img/historyType.png'),
          name:'历史版本'
        },
        {
          img:require('../assets/img/movePosition.png'),
          name:'移动位置'
        },
        {
          img:require('../assets/img/copyFull.png'),
          name:'复制全文'
        },
      ],
      exportIsShow:false,
      exportLists:[
        {
          name:'导出 图片'
        },
        {
          name:'导出 Word'
        },
        {
          name:'导出 TXT'
        }
      ],
      editConfiIsShow:false,
      editConfiLists:[
        {
          name:'文字大小'
        },
        {
          name:'行间距'
        },
        {
          name:'背景'
        }
        
      ],
      sliderValues:[{
        value:0
      },
      {
        value:10
      },{
        value:0
      }
      ],
      sliderSteps:[
        {
          value:10
        },
        {
          value:10
        },
        {
          value:5
        }
      ],
      sliderConfiger:[
        {
          value:14
        },
        {
          value:7
        },{
          value:0
        }
      ],
      isWhite:true,
      moveLocationIsShow:false,
      fileListsLength:null,
      fileListsPageNo:1,
      fileLists:[],
      folderRequest:false, // 文件夹是否滚动到底部在请求中
      verifyIsShow:false,
      isFeeofChange:false,
      isShowverifyChangeToast:false,   //控制校验单词的弹窗
      paraHeightTxt:'',
      feeOfWord:"",
      tempOriginalText: "",
      loadingText: "",
      isShowLoadingFlag: false,
      verifyToastContent:"",
      verifyDataList:-1,
      verifyCurrrentDom:"",
      verifyListChooseIndex:"",
      text: "",

      isSuggest:false,
      useDefinedToast:false,
      suggestToast:false,   //控制自定义弹窗是否显示
      toastItem:"",
      userDefinedInput:"",
      userDefinedArr:[],
      currentDom:"", //保存点击敏感词的dom节点
      currentIndex:"",
      changeArr:[],
      beforeContent:"",
      isBadWordCheckFlag:false,
      bgColorList:[
        {
          value:'rgb(255, 255, 255)',
          bottomB:'#EEEEEE',
          topB:'#DDDDDD'
        },
        {
          value:'rgb(255, 254, 239)',
          bottomB:'#EEEEEE',
          topB:'#DDDDDD'
        },
        {
          value:'rgb(244, 255, 235)',
          bottomB:'#EEEEEE',
          topB:'#DDDDDD'
        },
        {
          value:'rgb(207, 232, 204)',
          bottomB:'#FFFFFF',
          topB:'#FFFFFF'
        },
        {
          value:'rgb(235, 234, 239)',
          bottomB:'#FFFFFF',
          topB:'#FFFFFF'
        },
        {
          value:'rgb(38, 38, 38)',
          bottomB:'#909090',
          topB:'#666666'
        }
      ],
      bgIndex:0,
      editorTopLeftImg:require('../assets/img/writeSkii.png'),

      isBadWordCheckFlag:false,
      isShowDialogFlag: false,
      dialogContent: "",
      dialogCancelText: "",
      dialogConfirmText: "",
      titleValue: "",
      dictionaryTipLeft:false,
      dictionaryTipRight:false,
      dicitonaryIsSearch:false,
      dictionaryIsLoading:true,
      dictionaryEmptyIsShow:false,
      dictionaryEmptyText:'',
      fileLink: "",
      isInputFoucsFlag: false,
      isToVerifyWord:false,
      isToSaveHistory:true,
      currentVerifyIndex:'',
      dicSeachIsTouch:false,
      dicCopyIsTouch:-1,
      isIconTouch:-1,
      dicIcon1:require('../assets/img/topxzho1.png'),
      dicIcon2:require('../assets/img/topxzho1.png'),
      dicCancleIsTouch:'',
      badOriginalText:"",
      lastSimilDom:"",
      timer:"",
      isWritingSkillFlag: false,
      searching01:false,
      searching02:false,
      isBlack:false,
      reBgColorList:[
        {
          value:'background:#fff'
        },
        {
          value:'background:rgb(255, 254, 250)'
        },
        {
          value:'background:rgb(251, 255, 249)'
        },
        {
          value:'background:rgb(240, 248, 239)'
        },
        {
          value:'background:rgb(249, 248, 250)'
        },
        {
          value:'background:rgb(102, 102, 102);color:#fff'
        }

      ], 
      SbtopBgList:[
      {
          value:'background:#f5f5f5'
        },
        {
          value:'background:rgba(255, 255, 255,.7)'
        },
        {
          value:'background:rgba(255, 255, 255,.7)'
        },
        {
          value:'background:rgba(255, 255, 255,.7)'
        },
        {
          value:'background:rgba(255, 255, 255,.7)'
        },
        {
          value:'background:rgb(102, 102, 102);color:#fff'
        }
      ],
      polishFlag:false,
      polishContent:'',
      poSearchFlag:false,
      polishResult:'',
      poParagraphList:[],
      poChooseList:[],
      tempPolishSelection:"",
      tempPolishRange:"",
      polishChooseIndex:"",
      selectPolishContent:"",
      isToPolish:false,
      verifyFlag:false,
      controlPolishToast:'',
      polishWay:false,
      
      rewriteContent:"",
      getPostironTop:"",
      isPhoneFlag: true,
      
    }
  },
  watch: {
    editorTxt: {
      handler() {
        this.$nextTick(_ => {
          this.fontCount = document.getElementById("editor").innerText.length;
        })
      },
    },
    continuationSwitch:{
      handler(){
        if(!this.continuationSwitch){
          console.log('清空文本');

          let editorDom = document.getElementById("editor");
          if(this.rewriteContent == ""){
            editorDom.scrollTop = editorDom.scrollHeight; // 滚动到底部
          }else{
            editorDom.scrollTop = this.getPostironTop; //滚动到对应的插入位置
          }
          this.rewriteContent = "";

        }
      }
    }
  },

  methods: {
    footerMenuHandler() {
      // 获取键盘弹起时下面菜单的移动方向
      let startX1 = 0;
      let myFooterDom = document.getElementById("footer-resize");
      console.log(99999999,myFooterDom);
      myFooterDom.addEventListener("touchstart",function(eq) {
        startX1 = eq.touches[0].pageX;
      },false);

      myFooterDom.addEventListener(
        "touchmove",
        (op) => {
          if(startX1< op.touches[0].pageX) {
            myFooterDom.scrollLeft -= 5;
          }else if(startX1> op.touches[0].pageX) {
            myFooterDom.scrollLeft += 5;
          }
          setTimeout(_ => {
            startX1 = op.touches[0].pageX;
          }, 100)
        },
        false
      );
    },
    polishContentHandler(res,flag){
          let resTxt = res.data.data.resultText;
          let resList = res.data.data.dataList;
          let newTxt = "";
          let start = "";
          let end = "";
          if(resList.length>0){
            for(let i = 0 ;i<resList.length; i++){
              let text = "";
               start = resList[i].startIndex;
               end = resList[i].endIndex;
              // if( (i+1)<resList.length && resList[i].endIndex == resList[i+1].startIndex){
              //       //判断是否重合
              //       end = resList[i].endIndex-1;
              // }else{
              //       end = resList[i].endIndex;
              // }
              if(i == 0){
                  text = resTxt.substring(0,start);
              }else{
               
                 
                  text = resTxt.substring(resList[i-1].endIndex,start);
                  
                
              }

              let poContent = resTxt.substring(start,end);
              let template = `<span class="poblishContent" style="color:#1175FD">${poContent}</span>`
              newTxt = newTxt+text+template;
          
              

          }
          let len = resList.length-1;
              newTxt = newTxt + resTxt.substring(resList[len].endIndex);

          }else{
            newTxt = resTxt;
          }
          
      

          let paragraphList = newTxt.split("\\n");
          if(paragraphList.length>0){
            for(let i =0;i<paragraphList.length;i++){
              if(paragraphList[i] == ""){
                paragraphList[i] = `<span class="poPara" ><br></span>`;
              }else{
                paragraphList[i] = `<span class="poPara" style="${flag==1?'':'background:rgba(0,113,255,.13)'}">${paragraphList[i]}</span>`;
              }
            }
          }else{

            paragraphList.push(`<span class="poPara" style="${flag==1?'':'background:rgba(0,113,255,.13)'}">${newTxt}</span>`) ;
          }

          if(flag == 1 ){
            this.poParagraphList = paragraphList;
          }

          return paragraphList;
    },
    polishCion(param){
      //改写金币接口

        editor.checkDeductCoin(param).then(res=>{
            if(res.data.code == 10086){
              this.dialogSearchText = res.data.msg ;
              this.isShowDialogSearch = true;
            }else if(res.data.code == 0){
              if(this.polishFlag){
                    let param = this.isToPolish ;
                    this.beginPolish(param);
              }else{
                  
                  this.toVerifyWords();
              }

            }
        })

    },
    //改写
    getPolishContent(chooseIndex,levels){
      let content1 = "" ;
      this.polishChooseIndex = chooseIndex ;
      if(chooseIndex == 1){
        //自选
        content = this.selectPolishContent;
        
      }else{
        //全篇
        let editorDom = document.getElementById("editor");
        content1 = editorDom.innerText.replace(/(\r\n|\n|\r)/g, "\\n").replace(/<br>/g,"\\n");
        let arr = content1.split("\\n");
        arr.forEach((item,index)=>{
          if(item == ""){
            arr[index] = `<span class="polishParagraph" ><br></span>`;
          }else{

            arr[index] = `<span class="polishParagraph" >${arr[index]}</span>`;
          }
        })
        let newContent;

        if(arr.length>1){
          newContent = arr.join("<br>");
        }else{
          newContent = `<div class="polishParagraph">${content1}</div>`;
        }
        editorDom.innerHTML = newContent;
        console.log("替换之后的文",editorDom.innerHTML);
      }
      if(content1 == "" ){
        if(chooseIndex == 1 ){
          return this.showGlobalToast(1,"请选择文本");
        }else{
          return this.showGlobalToast(1,"请输入文本");
        }
      }

      let cionParam = {
        type: 'rewrite',
        text:content1,
        level:(levels*1+1)*2
      };
      let param = {
            fId : Number(localStorage.getItem('textId')),
            text:content1,
            level:`<L${(levels*1+1)*2}>`
        }
      this.isToPolish = param ;
      this.polishWay = false;
      this.polishCion(cionParam);
      // this.polishContent = select.toString();

    },

    textLength(){
      // this.continuationSwitch = true;
      this.continuationText = true;
    },
    textCancel(e){
      console.log('textCancel' , e);
      // this.continuationSwitch = true;
      this.continuationText = false;
    },
    textConfirm(e , i){
      localStorage.setItem("textColumns",i);
      this.textColumnsIndex = i ;
      this.startTextLength = this.textColumns2[this.textColumnsIndex].startTextLength;
      this.endTextLength = this.textColumns2[this.textColumnsIndex].endTextLength;
      this.continuationText = false;
    },
    replaceText(){
      this.continuationSwitch = false;
      let editorDom = document.getElementById("editor");
      let res = "";
      if(this.rewriteContent !== ""){
          res = this.rewriteContent.beforeContent + this.generateResult + this.rewriteContent.afterContent;
      }else{
        res = editorDom.innerText + this.generateResult;
        console.log('editorDom.textContent---',editorDom.innerText);
      }
      editorDom.innerText = res;


    },
    shouSuoXuXie(){

      let ss = "";
      if(this.rewriteContent.beforeContent){
        ss = this.rewriteContent.beforeContent;
      }else{
        ss  = document.getElementById("editor").innerText;
      }
      if (ss.length > 0) {
        let editorDom = document.getElementById("editor");
        // let param = editorDom.innerText;
        let param = {
          type:'renewal',
          text:ss,
          startTextLength:this.startTextLength,
          endTextLength:this.endTextLength,
        }
        audio.checkDeductCoin(param).then( res => {
          console.log('res' , res.data);

          if (res.data.code == 0) {
            // this.isShowDialogSearch = true;
            this.beginXuxie()
          } else if (res.data.code == 10086) {
            this.isShowDialogSearch = true;
            this.dialogSearchText = res.data.msg
          } else {
            this.notRegister = true;
            this.notRegisterText = res.data.msg;
          }
        })
      } else {
        this.isShowDialogHintText = true;
      }
    },
    confirmNotRegister(){
      this.notRegister = false;
    },
    cancelHint(){
      this.isShowDialogHintText = false;
    },
    confirmHint(){
      this.isShowDialogHintText = false;
    },
    cancelSearch(){
      if(this.verifyFlag){
        this.verifyFlag = false;
      }
      this.isShowDialogSearch = false;
    },
    confirmSearch(){

      if(this.polishFlag){
         this.isShowDialogSearch = false;
         let param = this.isToPolish ;
         this.beginPolish(param);
      }else if(this.verifyFlag ){
        this.isShowDialogSearch = false;
        this.toVerifyWords();
      }
      else{
        this.beginXuxie()
        this.isShowDialogSearch = false;
      } 


     
      
    },
    beginPolish(param){
          if(this.polishWay){
            this.poSearchFlag = true;
             editor.textRewriting(param).then(res => {
              this.poSearchFlag = false;
              if(res.data.code == 0 ){
                let paragraphList = this.polishContentHandler(res);
                let j = 0;
                this.polishWay.forEach( i => {
                  this.poParagraphList[i] = paragraphList[j++];
                })
                this.polishContent = this.poParagraphList.join("<br>");
              }
              else if(res.data.code == 1011){
                  this.continuationCancelText = res.data.data.cancelText;
                  this.continuationConfirmText = res.data.data.confirmText;
                  this.continuationContent = res.data.data.content;
                  this.isShowDialoggold = true;
              }else{
                this.showGlobalToast(1,res.data.msg);
              }
            })
          }else{
            this.poSearchFlag = true;
            editor.textRewriting(param).then(res =>{
              this.poSearchFlag = false;
              if(res.data.code == 0 ){
                this.polishResult = res.data.data;

                let paragraphList = this.polishContentHandler(res,1);
                
                this.polishContent = paragraphList.join('<br>');
              }
              else if (res.data.code == 1011) {
                  this.continuationCancelText = res.data.data.cancelText;
                  this.continuationConfirmText = res.data.data.confirmText;
                  this.continuationContent = res.data.data.content;
                  this.isShowDialoggold = true;
                  this.polishContent  = "";
                
              }else{
                  
                  this.polishContent  = "";
                  this.showGlobalToast(1,res.data.msg);
                
              }
            })
          }
          
    },
    beginXuxie(){
      this.isShowLoadingFlag = true;
      this.loadingText = "生产中";
      this.continuationCondition = 2 ;
      let ss = "";
      if(this.rewriteContent !== ""){
        ss = this.rewriteContent.beforeContent;
      }else{
        ss  = document.getElementById("editor").innerText;
      }
      let timer = null;
      audio.renewal({text:ss , startTextLength:this.startTextLength , endTextLength: this.endTextLength , fId: localStorage.getItem('textId') * 1}).then( res => {

        if(res.data.code == 0){
          let tId  = res.data.data;
          timer = setInterval(()=>{
            audio.getRenewalResult({taskId:tId}).then(res=>{

              if(res.data.code == 0){
                clearInterval(timer);
          this.continuationCondition = 3 ;
                this.generateResult = res.data.data;
          this.isShowLoadingFlag = false;
              }else if(res.data.code == 1){
                return;
              }else{
                this.showGlobalToast(1,res.data.msg);
              }
                })
              },1000);

        }else if (res.data.code == 1011) {
          this.continuationCancelText = res.data.data.cancelText;
          this.continuationConfirmText = res.data.data.confirmText;
          this.continuationContent = res.data.data.content;
          this.isShowLoadingFlag = false;
          this.isShowDialoggold = true;
          this.continuationCondition = 1 ;
        } else {
          this.continuationCondition = 1 ;
          this.notRegister = true;
          this.notRegisterText = res.data.msg;
        }







      })
    },
    cancelgold(){
      this.isShowDialoggold = false;
    },
    confirmgold(){
      this.isShowDialoggold = false;
      const that = this

        let param = {
          id: localStorage.getItem("textId"),
          fileName: document.getElementById("title-input").value,
          text: this.isSaveHtmlFlag? document.getElementById("editor").innerHTML: document.getElementById("editor").innerText,
        }
        this.mockUri("indexDouge");
        editor.updataContent(param).then(res => {

          // //跳转小程序购买金币页面
          // wx.miniProgram.redirectTo({
          //   url: '/mySubpage/goldPurchaseHistory/index',
          // });
          //跳转小程序任务页
          wx.miniProgram.redirectTo({
            url: '/mySubpage/earnGold/index',
          });
        })
        
    },
    history(){
      let textId = localStorage.getItem('textId') * 1;
      const that = this;
      
      let param = {
        id: localStorage.getItem("textId"),
        fileName: document.getElementById("title-input").value,
        text: this.isSaveHtmlFlag? document.getElementById("editor").innerHTML: document.getElementById("editor").innerText,
      };
      this.mockUri("indexDouge");
      editor.updataContent(param).then(res => {
        // wx.miniProgram.reLaunch({
        //   url: '/redact/historyText/index?history=polish&textID=' + textId
        // });
        let ss = { 'history':'polish' ,'textID':textId}
          localStorage.setItem('historyText' , JSON.stringify(ss))
          that.historyTextSwitch = true;
          that.polishFlag = false;
      })
    },
    historyRecord () {
      if(this.continuationSwitch){
        this.continuationSwitch = false;
      }
      let textId = localStorage.getItem('textId') * 1;
      const that = this;

      let param = {
        id: localStorage.getItem("textId"),
        fileName: document.getElementById("title-input").value,
        text: this.isSaveHtmlFlag? document.getElementById("editor").innerHTML: document.getElementById("editor").innerText,
      };
      this.mockUri("indexDouge");
      editor.updataContent(param).then(res => {
        let ss = { 'history':'' ,'textID':textId}
        localStorage.setItem('historyText' ,JSON.stringify(ss) )
        that.historyTextSwitch = true;
        that.polishFlag = false;
        // wx.miniProgram.reLaunch({
        //   url: '/redact/historyText/index?textID=' + textId
        // });
      })

    },
    badWordFocus() {
      this.$nextTick(_ => {
        document.getElementById("badWordCheckId").style.top = '35%';
      })
    },
    badWordBlur() {
      let editorDom = document.getElementById('editor');
      editorDom.style.height = `100vh`;
      editorDom.style.height = `calc(100vh -  15.8rem)`;
      document.getElementById("badWordCheckId").style.top = '50%';
    },
    openWritingSkill(e) {
      this.isWritingSkillFlag = e;
    },
    ioscancelEvent() {
      this.isShowIosDialogFlag = false;
    },
    iosconfirmEvent() {
      this.isShowIosDialogFlag = false;
      let obj = {
        id: 'myIosCopyBtn',
        text: this.fileLink,
        className: ".myIosCopyBtn",
        msg: "链接已复制，请到浏览器下载"
      }
      this.$emit("copy",obj);
    },
    toCloseVerifyList(){
        this.verifyIsShow = false;
        let editorDom = document.getElementById('editor');
        editorDom.style.height = `100vh`;
        editorDom.style.height = `calc(100vh -  15.8rem)`;
        this.addVerifyEventListener();
    },
    toClosePolish(){
        this.polishFlag = false;
        let chooseDom = document.querySelectorAll('.polishFreeChoose');
        let content2 = "";
        // let a = chooseDom.innerText;
        // a = document.createTextNode(a);
        // chooseDom.parentNode.insertBefore(a,chooseDom);
        // chooseDom.parentNode.removeChild(chooseDom);
        content2 = document.getElementById('editor').innerText;
        // content = document.getElementById('editor').textContent;

        setTimeout(_ => {
            this.$nextTick(_ => {
              let editorDom = document.getElementById('editor');
              editorDom.style.height = `calc(100vh -  15.8rem)`;
              let bottonDom = document.getElementById("footer");
              bottonDom.style.bottom=0;
            editorDom.innerText = content2;
            // let domArr = document.getElementsByClassName("polishParagraph");
            // domArr = [].slice.call(domArr);
            // domArr.forEach(u => {
            //   u.style["-webkit-user-modify"] = "read-write-plaintext-only";
            //   u.style["-webkit-user-select"] = `text`;
            //   u.setAttribute("isContenteditable",true);
            // })
            this.fontCount = document.getElementById("editor").innerText.length;
            })
          },1)



    },
    toHandlerPoblish(i,param,list,ll){

      if(i == 1 ){
        let polishContentList = [];
        let polishContent="";
          if(param.length>1){
            param.forEach( i=>{
            let contents = "";
            if(i==""){
              contents = `<span class="polishParagraph"><br></span>`;
            }else{
              contents = i;
            }
            let template = `<span class="polishParagraph" >${contents}</span>`;
            polishContentList.push(template);
          });
          polishContent = polishContentList.join("<br>");
        }else{
          polishContent = `<span class="polishParagraph" >${param[0]}</span>`;
        }


        //替换全部,判断是否为片段
       if(this.polishChooseIndex == 1){
          document.querySelector('.polishFreeChoose').innerHTML = polishContent;
       }else{
        // let replaceContent = this.polishResult.resultText.replace(/\\n/g,`<br>`);
          let editor = document.getElementById('editor');
          editor.innerHTML = polishContent;
          this.editorTxt = polishContent;
          console.log(4544545)
       }
      

      }
      else if( i == 2){
        //换一换
        let params = {
            fId : Number(localStorage.getItem('textId')),
            text:param,
            level:`<L${(ll*1+1)*2}>`
           };
        this.isToPolish = params;

        let cionParam = {
          type: 'rewrite',
          text:param,
          level:(ll*1+1)*2
        };
        this.polishWay = list;
        this.polishCion(cionParam);

       
      }else if( i == 3 ){

          //点击对应显示高亮
          let flag = false;
          let arr = document.querySelectorAll(".polishParagraph");

          this.poChooseList.forEach( (item,index) =>{

            if(item == param){
               this.poChooseList.splice(index,1);
               flag = true;
               arr[param].style.background = "transparent";
            }
          })
          if(!flag){
            this.poChooseList.push(param);
            arr[param].style.background = "rgba(0,113,255,.13)";
          }

          let ofTop = 0;
          if(arr[param].offsetTop!=0){
            ofTop = arr[param].offsetTop-120;
          }

          document.getElementById("editor").scrollTo(0, ofTop);
      }else if(i == 4){
        //替换所选
        let arr = document.querySelectorAll(".polishParagraph");
        let chooseIndex = param;
        let text = list;
        chooseIndex.forEach(q =>{
          arr[q].innerText = text[q];
        })
        this.fontCount = document.getElementById("editor").innerText.length;
      }
    },

    touchHandler(i,j){
      if(i==1){
        this.dicSeachIsTouch = !this.dicSeachIsTouch;
      }
      else if( i== 2){
          this.dicCopyIsTouch = j;
      }else if( i==3){
        this.dicCopyIsTouch = -1;
      }else if(i == 4){
        if(j==1){
          this.dicIcon1 = require('../assets/img/topxzho2.png');
        }else{
          this.dicIcon2 = require('../assets/img/topxzho2.png');
        }
      }else if(i == 5){
        this.dicIcon1 = require('../assets/img/topxzho1.png');
        this.dicIcon2 = require('../assets/img/topxzho1.png');
      }else if(i == 6){
        this.dicCancleIsTouch = "background: #EEEEEE";
      }else if( i == 7){
        this.dicCancleIsTouch ="";
      }
    },
    removeContenteditable() {
      this.isContenteditable = false;
      let editorDom = document.getElementById("editor");
      editorDom.style["-webkit-user-modify"] = "read-only";
    },
    addContenteditable() {
      this.isContenteditable = true;
      let editorDom = document.getElementById("editor");
      editorDom.style["-webkit-user-modify"] = "read-write-plaintext-only";
      editorDom.style["-webkit-user-select"] = `text`;
    },
    controlWriteSkillToast(){
      this.isShowWriteSkill = !this.isShowWriteSkill;
    },

    toIgnoreVerifyWord(val,i){
      let word="";
      if(i!== '-1'){
        word = val;
        }
        else{
          word = this.verifyCurrrentDom.innerText;
        }
      let param = {
        word: word,
        fileHomeId:localStorage.getItem("textId")
      }
      editor.addIgnoreWord(param).then(res=>{
        if(res.data.data){ 
          let text = "";
          if(i !== '-1'){
              text = val;
             }
          else{        
            text = this.verifyCurrrentDom.innerText;
        }
        let arr = document.querySelectorAll(".verifyWord");
        for(let j = 0;j<arr.length;j++){
            if(arr[j].innerText == text){
                arr[j].parentNode.insertBefore(document.createTextNode(text),arr[j]);
                arr[j].parentNode.removeChild(arr[j]);  
            }
        }
        for(let k = 0;k<this.verifyDataList.length;k++){
                if(this.verifyDataList[k].word==text){
                    this.verifyDataList.splice(k,1);
                    k--;
                   
                }
        }
        
       
      
      
        this.$nextTick(_=>{
                  let txt =document.getElementById('editor').innerHTML;
                  this.tempVerifyOriginalText = txt;
                  this.editorTxt = txt;
                  if(this.verifyDataList.length){

                    this.addVerifyEventListener();
                  }

                  
                  // if(i === -1){
                  //   this.verifyIsShow = false;
                  // }
                  this.isShowverifyChangeToast = false;
                  if(this.verifyCurrrentDom !== "" && this.verifyCurrrentDom.style!== undefined){
                    this.verifyCurrrentDom.style.background = "transparent";
                  }
                });

        }else{

        }
      })
    },
    updataContentById(i) {

      let param = {
        id: localStorage.getItem("textId"),
        fileName: document.getElementById("title-input").value,
        text: this.isSaveHtmlFlag? document.getElementById("editor").innerHTML: document.getElementById("editor").innerText,
      }
      this.mockUri("indexDouge");
      editor.updataContent(param).then(res => {
        if (i == 1) {
          wx.miniProgram.reLaunch({
            url: '/pages/home/home'
          });
        }
      })
    },
    updataText(e) {
      document.getElementById("editor").innerHTML = e.text;
      document.getElementById("title-input").value = e.title;
      this.editorTxt = e.text;
    },
    mockUri(url) {
      var state = {
        title: "逗哥写作神器",
        url: "/"+url // 这个url可以随便填，只是为了不让浏览器显示的url地址发生变化，对页面其实无影响
      };
      window.history.pushState(state, state.title, state.url);
    },
    copyLink(i) {
      let obj = {
        id: 'file'+i,
        text: this.fileLink,
        className: ".copylink" + i,
        msg: "链接已复制，请到浏览器下载"
      }
      this.$emit("copy",obj);
    },
    inputFocus() {

      if (isAndroid) {
        let q = window.visualViewport;
        let height = q.height;
        let that = this;
        q.addEventListener("resize", function (ae) {
          if(q.height != height) {
            that.isInputFoucsFlag = true;
            document.querySelector(".history_box").children[1].style.position = "relative";
            document.querySelector(".history_box").children[1].style.bottom = "9rem";
          }else {
            that.isInputFoucsFlag = false;
            document.querySelector(".history_box").children[1].style.position = "fixed";
            document.querySelector(".history_box").children[1].style.bottom = "0rem";
          }
        })
      }
    },
    inputBlur() {
      this.isInputFoucsFlag = false;
      setTimeout(_ => {
        document.querySelector(".history_box").children[1].style.position = "fixed";
        document.querySelector(".history_box").children[1].style.bottom = "0rem";
      },500)
    },
    focusAgain() {
      if(isAndroid) {
        document.getElementById("editor").style.height = `calc(100vh - 15.8rem)`;
      }
      let flag = false;
      if(this.polishFlag){
        this.polishFlag = false;
        flag = true ;
      }
      if(this.continuationSwitch){
        this.continuationSwitch = false;
      }
      this.$nextTick(_ => {
        let editorDom = document.getElementById("editor");
        if(flag){
          let content = editorDom.innerText;
          editorDom.innerText = content ;

        }
        editorDom.focus();
      })

    },
    createTextAndHistory() {
      if (!this.isToSaveHistory)return this.showGlobalToast(1,'该版本已保存');
      let text = document.getElementById("editor").innerText;
      let title = document.getElementById("title-input").value;
      if(text.length == 0)  return this.showGlobalToast(1,'请输入内容');
      if(title.length == 0) return this.showGlobalToast(1,'请输入标题');
      if(title.length > 20) return this.showGlobalToast(1,'标题最大长度为20');
      this.isShowLoadingFlag = true;
      this.loadingText = "保存中";
      let param = {
        id: localStorage.getItem("textId")?Number(localStorage.getItem("textId")): '',
        text: text,
        fileName: title
      }
      editor.createTextAndHistory(param).then(res => {
        this.isShowLoadingFlag = false;
        if(res.data.code == 0) {
          if(res.data.data.hasOwnProperty("id")) {
            localStorage.setItem("textId",res.data.data.id);
          }
          this.showGlobalToast(2,'保存历史记录成功');
          this.isToSaveHistory = false;
          
        }
      })
    },
    // 从后端读取用户的文本
    getUserText() {
      let text = "";
      if(localStorage.getItem("originalPage")) {
        text = localStorage.getItem("content");
      }else {
        this.$nextTick(() => {
          this.editorTxt = TextToHtml(text);
        })
      }
      editor.getUserText().then(res => {
        if(localStorage.getItem("originalPage") == "H5") {
          text = text + res.data.data.writeExtract;
          document.getElementById("title-input").value = res.data.data.writeTitle || localStorage.getItem("title") ||""
        }else {
          if(localStorage.getItem("isCreateNew") == "true") {
            text = "";
            document.getElementById("title-input").value = ""
          }else {
            
            text = res.data.data.writeExtract;
            document.getElementById("title-input").value = res.data.data.writeTitle ||""
          }
        }
        this.$nextTick(() => {
          this.editorTxt = TextToHtml(text);
        })
        // return
        // this.$nextTick(() => {
        //   // 目前只有新手文档有div 所以先暂时这样子写
        //   let index1 = text.indexOf("<div");
        //   // 新手文档
        //   if(index1 > -1) {
        //     let index2 = text.indexOf(">",index1);
        //     let html1 = text.substring(index1,index2 + 1);
        //     let html2 = text.substring(text.lastIndexOf("</div>"))
        //     let html = html1 + html2;
        //     text = text.replace(html1,"");
        //     text = text.substring(0,text.length-6);
        //     let nodeInstance = document.createRange().createContextualFragment(html);
        //     // 创建一个临时的盒子用来储存文档下面的内容
        //     let myTemplateBox = document.createElement("div");
        //     myTemplateBox.style = "display: none";
        //     myTemplateBox.setAttribute("class","myTemplateBox")
        //     let textTemplate = document.createRange().createContextualFragment(text);
        //     myTemplateBox.appendChild(textTemplate);
        //     document.body.appendChild(nodeInstance);
        //     document.body.appendChild(myTemplateBox);
        //     let domBox = document.getElementsByClassName("rookie_file_box")[0];
        //     domBox.style.display = 'block';
        //     domBox.setAttribute("contenteditable",true);
        //     domBox.style["-webkit-user-modify"] = "read-write-plaintext-only";
        //     domBox.style["-webkit-user-select"] = `text`;
        //     // 将处理好的盒子append到编辑器中
            
        //     let domList = document.getElementsByClassName("myTemplateBox")[0].children;
        //     domList = [].slice.call(domList);
        //     domList.forEach(v => {
        //       v.setAttribute("contenteditable",true);
        //       v.style["-webkit-user-modify"] = "read-write-plaintext-only";
        //       v.style["-webkit-user-select"] = `text`;
        //       domBox.appendChild(v);
        //     })
        //     document.getElementById("editor").appendChild(domBox);
        //   }
        // })
      })
    },
    // 统一弹窗取消按钮处理
    cancelEvent() {
      this.isShowDialogFlag = false;
    },
    // 统一的弹窗确认事件处理
    confirmEvent() {
      
        if(this.dialogConfirmText == "确定" || this.dialogConfirmText == "再想想") {
          this.isShowDialogFlag = false;
        }else if(this.dialogConfirmText == "去购买" || this.dialogConfirmText == "去开通" || this.dialogConfirmText == "去续费") {
          this.isShowDialogFlag = false;
          const that = this
          

          let param = {
            id: localStorage.getItem("textId"),
            fileName: document.getElementById("title-input").value,
            text: this.isSaveHtmlFlag? document.getElementById("editor").innerHTML: document.getElementById("editor").innerText,
          }
          this.mockUri("indexDouge");
          editor.updataContent(param).then(res => {
          
            // 跳转到会员购买页面
            wx.miniProgram.redirectTo({
              url: '/mySubpage/openVip/openVip'
            });
          })

        }
      

    },
    //发送校验
    toVerifyWords(){
      this.verifyFlag = false ;
      this.verifyListChooseIndex = "";
      // let param = document.getElementById("editor").innerText.replace(/(\r\n|\n|\r)/g, "\\n").replace(/<br>/g,"\\n");
      let param = document.getElementById("editor").innerText.replace(/(\r\n|\n|\r)/g, "\\n").replace(/<br>/g,"\\n");
      //获取文本
      // if(!param){
      //     this.showGlobalToast(1,'请输入文本');
      //    return ;
      // }
      
      this.addContenteditable();
      this.isShowLoadingFlag = true;
      this.loadingText = "校验中";
          //  editor.verifyWord({
           editor.iflytekVerifyWord({
               text:param,
               fId: localStorage.getItem("textId")
           }).then(res => {
            
            if(res.data.code == 0) {
              this.isShowLoadingFlag = false;
              this.verifyDataList = res.data.data;
              this.verifyIsShow = true ;
              if(res.data.data == [] || res.data.data.length === 0 ){
                    let txt = param.replace(/\\n/g,`<br>`);
                    this.tempVerifyOriginalText = txt;
                    this.editorTxt = txt;
                    this.isToVerifyWord = false;
                    this.verifyDataList =[];
                    setTimeout(_ => {
                      this.$nextTick(_ => {
                        let editorDom = document.getElementById('editor');
                        editorDom.style.height = `100vh`;
                        editorDom.style.height = `calc(100vh -  47.4rem)`;
                      })
                    },500)
                    return ;
              }
              let filterArr = [...this.verifyDataList];
              let index = 0;
              let newContent="";
               for(let i =0;i<this.verifyDataList.length;i++){
                console.log('i----',i);
                if(i>0){
                  if(this.verifyDataList[i].index<this.verifyDataList[i-1].index + this.verifyDataList[i-1].length ){
                     
                    filterArr.splice(i+index,1);
                    index--;
                    i++;
                  }
                }
                
                   let correctWord = this.verifyDataList[i].word;
                   let template = `<div class="verifyWord" contenteditable="true"  style="display:inline-block;border-bottom:.2rem solid #9C87D9;">${correctWord}</div>`;
                   let txt="";
                   if(i==0){
                        txt = param.substring(0,this.verifyDataList[0].index)+template;
                   }else{
                       txt = param.substring(this.verifyDataList[i-1].index+this.verifyDataList[i-1].word.length,this.verifyDataList[i].index)+template;
                   }
                   newContent =newContent+txt;
  
               }
               let last = this.verifyDataList.length-1;

               newContent =newContent + param.substring(this.verifyDataList[last].index+this.verifyDataList[last].word.length);
               newContent = newContent.replace(/\\n/g,`<br>`);
               console.log('newContent----',newContent);


              //  document.getElementById('editor').innerHTML = res.data.data.text.replace(/\\n/g,`<br>`);;

               this.$nextTick(_=>{
                    document.getElementById('editor').innerHTML = newContent;
                    this.tempVerifyOriginalText = newContent;
                    this.editorTxt = newContent;
                    this.verifyDataList = [...filterArr];
                    this.verifyListChooseIndex = "";
                    this.addVerifyEventListener();
                    this.isToVerifyWord = false;
                    setTimeout(_ => {
                      this.$nextTick(_ => {
                        let editorDom = document.getElementById('editor');
                        editorDom.style.height = `100vh`;
                        editorDom.style.height = `calc(100vh -  47.4rem)`;
                      })
                    },500)
               })
            }
            else if(res.data.code == 1011){
              this.isShowLoadingFlag = false;
              this.continuationCancelText = res.data.data.cancelText;
              this.continuationConfirmText = res.data.data.confirmText;
              this.continuationContent = res.data.data.content;
              this.isShowDialoggold = true;
            }
            else {
              this.isShowLoadingFlag = false;
              this.errorCode(res.data);
            }


           }).catch(err =>{
               console.log("checkerr=", err);
               this.isShowLoadingFlag = false;
               this.showGlobalToast(1,'网络异常');
           })
    },
    addVerifyEventListener(){
      this.$nextTick(_=>{
                    let arr = document.querySelectorAll(".verifyWord");
                    let that = this;
                    for(let i = 0;i<arr.length;i++){  
                        arr[i].onclick = function(e){

                          for(let j=0;j<arr.length;j++){
                                arr[j].style.background = "transparent";
                            }

                           arr[i].contenteditable=false;
                            that.verifyListChooseIndex = i ;
                            //显示背景颜色
                            arr[i].style.background = "#DCD5F2";
                            that.verifyToastContent = that.verifyDataList[i].correctWord;
                            that.verifyCurrrentDom = arr[i];
                            that.currentVerifyIndex = i;
                            that.isShowverifyChangeToast = true;
                            //设置弹窗的位置
                            that.showVerifyToastPosition(e);
                    }
                  }

               })
    },

    //点击替换
    toChangeVerifyWord(val,i){
      let content ="";
      let dom = "";
      this.isToSaveHistory = true;
      if(i !== '-1'){
          dom = document.querySelectorAll(".verifyWord")[i];
          content = document.createTextNode(val);
          this.verifyDataList.splice(i,1);
      }
      else{
        dom = this.verifyCurrrentDom;
        content = document.createTextNode(this.verifyToastContent);
        this.verifyDataList.splice(this.currentVerifyIndex,1);

      }

        dom.parentNode.insertBefore(content,dom);
        dom.parentNode.removeChild(dom);
        this.$nextTick(_=>{
                  let txt =document.getElementById('editor').innerHTML;
                  this.tempVerifyOriginalText = txt;
                  this.editorTxt = txt;
                  this.addVerifyEventListener();

                  
                  if(i === -1){
                    this.verifyIsShow = false;
                  }
                  this.isShowverifyChangeToast = false;
                  if(this.verifyCurrrentDom !== ""  && this.verifyCurrrentDom.style!== undefined){
                    this.verifyCurrrentDom.style.background = "transparent";
                  }
                });
    },
    //显示校验小弹窗的位置
    showVerifyToastPosition(e){
      this.$nextTick(_=>{
        let b = e.target.getBoundingClientRect();
        let dom = document.getElementById('verifyChangeToast');
        if(this.iskeybroadUp){
              if(b.top>173){
                dom.style.top = (b.top-120)/10 + 'rem';
              }else{
                dom.style.top = (b.top+b.height-15)/10 +'rem';       
              }

        }else{
             if(b.top>382.5){
                    dom.style.top = (b.top-102)/10 + 'rem';
                }else{
                    dom.style.top = (b.top+b.height+5)/10 +'rem';
                }
        }

               
               
                
                if(b.left>230){
                    dom.style.left = '24rem';
                }else{
                    dom.style.left = (b.left-14)/10 +'rem';
                }

      })
    },
        // 显示免改弹窗
    showFeeofChange(val,word){
      //页面点击
      if(val == 1){
        this.isShowverifyChangeToast = false; //关闭校验单词小弹窗
        // if(this.verifyCurrrentDom !== ""){
        //     this.verifyCurrrentDom.style.background = "transparent";
        //  }

        this.feeOfWord = this.verifyCurrrentDom.innerText;
        this.verifyCurrrentDom.contenteditable = true;
      }
      //列表显示
      else if(val ==2){
        this.feeOfWord = word;
        this.verifyIsShow = false;
        setTimeout(()=>{

          let editorDom = document.getElementById('editor');
          editorDom.style.height = `100vh`;
          editorDom.style.height = `calc(100vh -  17.8rem)`;
          let bottonDom = document.getElementById("footer");
          bottonDom.style.bottom=0;
          this.addVerifyEventListener();
        },500)

      }
      this.isFeeofChange= !this.isFeeofChange; //控制免改词弹窗
      if(!this.isFeeofChange){
        this.verifyCurrrentDom.style.background = "transparent";
      }
    },
    //点击校验列表弹窗
    clickVerifyList(i){

      const arr = document.querySelectorAll(".verifyWord");
      for(let j = 0;j<arr.length;j++){
        arr[j].style.background = "transparent";
      }
      //显示背景颜色
      arr[i].style.background = "#DCD5F2";
      this.verifyCurrrentDom =arr[i];

      this.$nextTick(_=>{
        this.addVerifyEventListener();
      })
      //滚动条滚动到对应词的附近
      let editor = document.getElementById('editor');
      let ofTop = this.verifyCurrrentDom.offsetTop-140;
      editor.scrollTo(0, ofTop);

    },

    downloadFile(res,type,name) {
      let blob = new Blob([res.data], {
        'type': type
      }); // 手机端要指定特定的type 才能下载。
      const blobUrl = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.style.display = 'none';
      a.download = name;
      a.href = blobUrl;
      a.click();
      setTimeout(_ => {
        this.isShowLoadingFlag = false;
      },1000)
    },
    preventClick() {
      this.isShowLoadingFlag = false;
      return
    },
    recoverOriginalText(i) {
      this.$nextTick(_ => {
        let editorDom = document.getElementById("editor");
        if(i === 2){
         if(this.badOriginalText == ""){
            return;
         }
          this.suggestToast = false;

          this.useDefinedToast = false;
          editorDom.innerHTML = this.badOriginalText;
          this.editorTxt = this.badOriginalText;
          this.$nextTick(_=>{
            this.addBadListener();
          })
          this.changeArr=[];
        }else{
          closeMyDialog("toastCon");
          if(this.lastSimilDom !== ""){
            this.lastSimilDom.style.background = "transparent";

          }
          editorDom.innerHTML = this.tempOriginalText;
          this.editorTxt = this.tempOriginalText;
        }
      })
    },
    cancleUseSimillarWordResult() {
      closeMyDialog("toastCon");
      let editorDom = document.getElementById("editor");
      this.isSimillarWordCheckFlag = false;
      this.editorTxt = editorDom.innerText;
      this.addContenteditable();
      editorDom.style.height = `calc(100vh - 15.8rem)`;
    },
    confirmUseSimillarWordResult() {

      closeMyDialog("toastCon");
      if(this.lastSimilDom !== ""){
        this.lastSimilDom.style.background = "transparent";

      }
      this.isSimillarWordCheckFlag = false;
      let editorDom = document.getElementById("editor");
      localStorage.setItem("content", editorDom.innerText);
      this.editorTxt = editorDom.innerText;
      this.isToVerifyWord = true ;
      this.isToSaveHistory = true;
      this.addContenteditable();
      editorDom.style.height = `calc(100vh - 15.8rem)`;
    },

        //  词典
     copy(e,className,id){
      console.log('e' , e);
      let obj = {
        id: id,
        text: e,
        className: className,
        msg: "复制成功",
      }
      this.$emit("copy",obj);
    },
    searchBox(){
      if(!this.dictionariesSearchBox){

        if (this.dictionariesTopIndex == 0) {
          this.dictionariesSearchBox = "高大魁梧";
        }
        else{

            this.dictionariesSearchBox="比喻做事非常顺利";
        }
      }
      this.dictionariesArr=[];
      this.antonymArr = [];
      this.dictionaryIsLoading = false;
      this.dictionaryEmptyIsShow = false;
      this.dicitonaryIsSearch = true;
     
      
      if (this.dictionariesTopIndex == 0) {
        if(this.searching01)
        {
          console.log('在搜索');
          this.showGlobalToast(1,"正在搜索请稍等");
          return;
        }

        this.searching01 = true;
        audio.selectDict({word:this.dictionariesSearchBox}).then( res => {
          this.dicitonaryIsSearch = false;
          this.dictionaryEmptyIsShow = false;
          if(res.data.data == null){
            this.dictionaryEmptyText = this.dictionariesSearchBox;
            this.dictionaryEmptyIsShow = true;
             if(this.dictionariesTopIndex != 0){
                  this.dictionaryEmptyIsShow = false;
                  this.searching01 = false;
                  return;
            }
            this.searching01 = false;
            return;
          }
          
          let ss = []
          ss.push(res.data.data)
          this.dictionariesArr = ss;
          this.searching01 = false;
        })
      } else {
        if(this.searching02){
          this.showGlobalToast(1,"正在搜索请稍等");
          return;
        }
        this.searching02 = true;
        audio.describe({word:this.dictionariesSearchBox}).then( res => {
          this.dicitonaryIsSearch = false;
          if(res.data.data.length == 0){
            this.dictionaryEmptyText = this.dictionariesSearchBox;
            this.dictionaryEmptyIsShow = true;
            if(this.dictionariesTopIndex == 0){
              this.dictionaryEmptyIsShow = false;
              this.searching02 = false;
              return;
            }
            this.searching02 = false;
            return;
          }
          this.antonymArr = res.data.data;
          this.searching02 = false;
        })
      }
    },
    dictionariesTopClikc(e){
      let dom = document.getElementById('dictionariesSearchBox');
      if(e == 0){
        dom.placeholder = `试试搜索“高大魁梧”`
        this.dictionaryTipRight = false;
      }else{
        dom.placeholder = `试试搜索 “比喻做事非常顺利”`
        this.dictionaryTipLeft = false;
      }
      this.dictionariesTopIndex = e;
      this.searchBox();
    },
    getPositionContent(e){
      if(e.target.className !== 'view_text_box'){
        return;
      }
      let sel = getSelection();
      if(sel.anchorNode !== null){
        let choose = sel.anchorNode.textContent;
        this.getPostironTop = e.pageY-116;

        let myIndex = sel.anchorOffset;
        let before = choose.substring(0,myIndex);
        let after = choose.substring(myIndex);

        let beforeNode = sel.anchorNode.previousSibling ? sel.anchorNode.previousSibling : ""; // 上一个节点
        let meNodeTxt = "";
        while(beforeNode){
                meNodeTxt = beforeNode.textContent+meNodeTxt;
                beforeNode = beforeNode.previousSibling;
        }
        // if(beforeNode==""){

        // }else{
        //   let beforeContent = meNodeTxt+before;
        // }
        let beforeContents = meNodeTxt+before;

        let afterNode = sel.anchorNode.nextSibling ? sel.anchorNode.nextSibling : ""; //下一个节点
        meNodeTxt = "";
        while(afterNode){
              meNodeTxt = meNodeTxt +afterNode.textContent;
              afterNode = afterNode.nextSibling;
        }

        let afterContents = after + meNodeTxt ;
       this.rewriteContent = {};
        this.rewriteContent.beforeContent = beforeContents;
        this.rewriteContent.afterContent = afterContents;
      }else{
        this.rewriteContent = "";
      }



    },
    divClick(e) {

      this.getPositionContent(e);//获取光标位置

      closeMyDialog("toastCon");
      let that =this;
      setTimeout(()=>{
          if(e.target.classList.contains("verifyWord")&& that.iskeybroadUp){
                let editorDom = document.getElementById("editor");
                let toTop = e.target.offsetTop-120;
                let verifyChangeToast = document.getElementById('verifyChangeToast');
                let x1 = editorDom.scrollTop;
                editorDom.scrollTo(0,toTop);
                let x2 = editorDom.scrollTop;
                 
                if(x1!==x2){
                  let beforeTop = verifyChangeToast.style.top.replace(/rem/,"")*1;
                  let newTop = beforeTop - (x2 -x1)/10;
                  if(newTop<4.9){
                    verifyChangeToast.style.top = newTop+ 14 + 'rem';
                  }else{

                    verifyChangeToast.style.top = newTop + 'rem';

                  }
                } 
           }
      },300)
      
   
     

      if(this.lastSimilDom !== ""){
        this.lastSimilDom.style.background = "transparent";

      }
      if(e.target.classList.contains("my_simillar_word_contronl") || e.target.classList.contains("my_simillar_word_contronl_box")) {
        if(this.lastSimilDom !== ""){
            this.lastSimilDom.style.background = "transparent";
        }
        e.target.style.background = "rgb(221, 237, 255)";
        this.lastSimilDom = e.target;
        
        createSimillarWordItem(e);
      }else if(e.target.classList.contains("view_text_box")) {
        this.isShowverifyChangeToast = false;
        if(this.verifyCurrrentDom !== "" && this.verifyCurrrentDom.style!== undefined){
            this.verifyCurrrentDom.style.background = "transparent";
          }
        this.suggestToast = false;
         let arr1 = document.querySelectorAll("#editor span");
          for(let j = 0;j<arr1.length;j++){
              arr1[j].style.background = "transparent";
           }
      }
      if(this.isContenteditable && !e.target.classList.contains("verifyWord")) {
        this.tempGetselection = getSelection();
        this.tempRange = this.tempGetselection.getRangeAt(0);
        this.beforeRangeTxt = getTextBefore(this.tempGetselection, this.tempRange, 1000, e.target);
        this.currentTarget = e.target;
        this.afterRangeTxt = getText(this.tempGetselection, this.tempRange, 1000, e.target);
        this.iscanPasteFlag = true;
      }
    },
    toFeeofChange(i,word){
      //查看免改词库
      if(i == 3){
        this.isShowFeeWordLibraryDialogFlag = true;
        this.mockUri("checkout");
        return;
      }

      //确定设置为免改
      if( i == 1){
        let text = word ;
        let arr = document.querySelectorAll(".verifyWord");
        for(let j = 0;j<arr.length;j++){
            if(arr[j].innerText == text){
                arr[j].parentNode.insertBefore(document.createTextNode(text),arr[j]);
                arr[j].parentNode.removeChild(arr[j]);
            }
        }
        for(let k = 0;k<this.verifyDataList.length;k++){
                if(this.verifyDataList[k].word==text){
                    this.verifyDataList.splice(k,1);
                    k--;

                }
        }
        this.$nextTick(_=>{
                  this.isFeeofChange= false;//关闭免改词弹窗
                  let txt =document.getElementById('editor').innerHTML;
                  this.tempVerifyOriginalText = txt;
                  this.editorTxt = txt;
                  if(this.verifyDataList.length){
                    this.addVerifyEventListener();
                  }
                });

                return;
      }

      if(this.verifyCurrrentDom.style!==undefined){
        this.verifyCurrrentDom.style.background = "transparent";
        this.verifyCurrrentDom.contenteditable = true;
      }


      this.isFeeofChange= false;//关闭免改词弹窗
      this.addVerifyEventListener();

    },
    topHandler(i){
      if( i === 0 ){
        let editorDom = document.getElementById('editor');
        let title = document.getElementById("title-input").value;
        localStorage.setItem("title",title);
        localStorage.setItem("content",editorDom.innerText);
        const that = this;
        //导入文案
        that.createTextAndHistory();
        wx.miniProgram.reLaunch({
          url: '/redact/importText/index'
        });
        wx.miniProgram.postMessage({
          data: {fromPage: "H5"}
        })
      }else if( i === 1){
        //保存文案并创建一条记录
        this.createTextAndHistory();
      }else{
        this.otherIsShow = true;

      }
    },
    addFolder(){
      if (this.FolderName == '') {
        return this.showGlobalToast(1,'不能为空');
      }
      if (this.FolderName.length > 20) {
        return this.showGlobalToast(1,'不能超过20个字');
      }

      audio.insertFileHome({type: 'file',fileName:this.FolderName}).then( res => {
        if (res.data.code == 0) {
          this.isShowLoadingFlag = true;
          this.loadingText = "文件查询中";
          audio.selectMkdirList({pageNo:1  , pageSize:10}).then( res => {
            this.isShowLoadingFlag = false;
            this.FolderName = '';
            this.folderRequest = false;
            this.fileListsLength = res.data.data.count
            this.fileLists = res.data.data.fileHomes;
            this.newFolder = false;
            this.moveLocationIsShow = true;
        })
        }
      })

    },
    cancelAddFolder(e){
      this.newFolder = false;
      this.moveLocationIsShow = true;
    },
    addFolderPopUp(){
      this.newFolder = true;
      this.moveLocationIsShow = false;
    },
    moveFolder(){
      if (this.pitchOnFolderIndex == null) {
        return this.showGlobalToast(1,'请选择文件夹');
      }
      let ss = {
        id: localStorage.getItem("textId"),
        folderId:this.fileLists[this.pitchOnFolderIndex].id,
      }
      audio.moveTextFile(ss).then( res => {
        if(res.data.code == 0) {
          this.showGlobalToast(2,'移入成功');
        }else {
          this.showGlobalToast(1,'移入失败');
        }
      })

    },
    scrollEvent(e){
      if (this.fileListsLength <= this.fileLists.length || this.folderRequest) {
        return
      }


      if (Math.ceil(e.currentTarget.scrollTop + e.currentTarget.clientHeight) >=e.currentTarget.scrollHeight - 70) {   //容差：20px
        this.folderRequest = true
        this.isShowLoadingFlag = true;
        this.loadingText = "文件查询中";

        this.fileListsPageNo = this.fileListsPageNo + 1
        audio.selectMkdirList({pageNo:this.fileListsPageNo  , pageSize:10}).then( res => {
            this.isShowLoadingFlag = false;
            this.folderRequest = false;
            this.fileListsLength = res.data.data.count
            res.data.data.fileHomes.forEach(e => {
              this.fileLists.push(e)
            });

        })
      }
    },
    popupHandler(s,i){
     if(s === 3 ){
      this.otherIsShow = false;
      if( i=== 0){
        this.editConfiIsShow = true;
      }
       else if( i === 1){
        this.exportIsShow = true ;
      }else if (i === 2){
        if(!localStorage.getItem("textId")) return this.showGlobalToast(1,'请先生成一篇文章');
        this.mockUri("history");
        this.isShowHistoryversionDialogFlag = true;
      }
      else if( i ===3){
        this.isShowLoadingFlag = true;
        this.loadingText = "文件查询中";
        audio.selectMkdirList({pageNo:1 , pageSize:10}).then( res => {
            this.isShowLoadingFlag = false;
            console.log('res' , res.data.data);
            this.fileListsLength = res.data.data.count
            this.fileLists = res.data.data.fileHomes;
        })
        this.moveLocationIsShow = true;
      }
     }else if(s == 5) {
      this.fileLink = "";
      let serve = null;
      serve = i == 1? editor.outputWord : i == 0? editor.outputImg : i == 2? editor.outputTxt : '';
      // 导出
      this.isShowLoadingFlag = true;
      this.loadingText = "文件生成中";
      // 导出word
      let that = this;
      let box = document.getElementsByClassName("editor_box")[0];
      let param = new FormData();
      i == 2? param.append("html",box.innerText): param.append("html",box.innerHTML);
      serve(param).then(res => {
        console.log("res",res.data.data);
        this.fileLink = res.data.data;
        this.isShowLoadingFlag = false;
        if(isAndroid) {
          let obj = {
            id: 'file'+i,
            text: this.fileLink,
            className: ".copylink" + i,
            msg: "链接已复制，请前往浏览器下载"
          }
          this.$emit("copy",obj);
          document.getElementById("file"+i).click();
        }else {
          let fileType = i== 0? 'png': i==1? 'word': i== 2? 'txt': '';
          this.createIndex = i;
          this.isShowIosDialogFlag = true;
          this.iosDialogContent = `<div style="width: 100%;display: flex;align-items: center;justify-content: center;">您确定要复制以下链接吗？</div><div style="width: 100%;display: flex;align-items: center;justify-content: center;">文件格式[${fileType}]</div>`
        }
      })

     } else if ( s == 6) {
      this.pitchOnFolderIndex = i;
     }
    },

    //复制全文
    copyFullText(){
      let obj = {
        id: "mycopybtn",
        text: document.getElementById('editor').textContent,
        className: ".mycopybtn",
        msg: "复制成功"
      }
      this.$emit("copy",obj);
    },
    textClose(){
      if (!this.newFolder) {
        this.fileListsPageNo = 0;
        this.fileListsLength = null;
        this.fileLists = [];
        this.pitchOnFolderIndex == null;
      }
      this.FolderName == '';
    },
    popupClose(i){
     
      if( i === 0){
      //编辑配置
        this.editConfiIsShow = false ;
      }else if( i === 1 ){
        //导出
        this.exportIsShow = false ;
      }else if( i === 2){
        //移动位置
        this.moveLocationIsShow = false ;
      }
      this.otherIsShow = true;

    },


    //修改编辑配置 i 为修改的进度条位置
    onSliderChange(i,val){
      //获取文本框的节点
      let editorDom = document.getElementById('editor');
       if(i === 0){
        //文字大小
        let newVal = (14 + val/10)/10;
        editorDom.style.fontSize = `${newVal}rem`
        this.sliderConfiger[0].value = 14 + val/10;

        let newVal2 = (document.getElementById('editor').style.fontSize.replace(/rem/,"")*10+val/10+6)/10;
        editorDom.style.lineHeight = `${newVal2}rem`;
       }else if( i === 1 ){
        //行间距
        
        let newVal = (document.getElementById('editor').style.fontSize.replace(/rem/,"")*10+val/10+6)/10;
        this.sliderConfiger[1].value = 6+val/10 ;
        editorDom.style.lineHeight = `${newVal}rem`;
        

       }else if( i === 2 ){
        //段落间距
        let newVal = val==0? 0:val/50;
        let editorhr = document.querySelectorAll('#editor .my_paraHeight');
        for(let i = 0;i<editorhr.length;i++){

          editorhr[i].style.marginBottom=`${newVal}rem`;
        }
       }
       else if(i=== 6){//修改背景颜色
         if(val == this.bgIndex) return;
         if( val == 5 ){
           this.isBlack = true ;
         }else{
          this.isBlack = false;
         }
            this.bgIndex =val;
            this.bgColorConfiger();
            
            this.$nextTick(_=>{
              this.toSetConfiger();
            })
        }
       else if( i=== 5 ){
        //重置设置
        editorDom.style.fontSize = `1.4rem`;
        editorDom.style.lineHeight = `2.1rem`;
        // let editorhr = document.querySelectorAll('#editor .my_paraHeight');
        // for(let i = 0;i<editorhr.length;i++){

        //   editorhr[0].style.marginBottom=`0rem`;
        // }

        this.sliderValues=[{
            value:0
          },
          {
            value:10
          },{
            value:0
          }]
          document.getElementById('con').style.background="rgb(255,255,255)";
          editorDom.style.color = "#333333";
          this.isWhite = true;
          this.sliderConfiger=[{
            value:14
          },
          {
            value:7
          },
          {
            value:0
          }]
          this.bgIndex = 0;
          this.bgColorConfiger();
          this.toSetConfiger();
       }





    },
    bgColorConfiger(){
            let editorDom = document.getElementById('editor');
            let footer_item = document.querySelectorAll('.footer_item');
            let totalCount = document.querySelectorAll('.totalCount');
            let titleinput = document.getElementById("title-input");
            document.getElementById('con').style.background=this.bgColorList[this.bgIndex].value;
            let border=this.bgColorList[this.bgIndex].topB;
            totalCount[0].style.border=` .1rem solid ${border}`;


            for(let i = 0;i<footer_item.length;i++){
                border = this.bgColorList[this.bgIndex].bottomB;
                footer_item[i].style.border =`.1rem solid ${border}`;
              if(this.bgIndex == 5){//黑色
                footer_item[i].style.color = '#fff';
                footer_item[i].style.background = '#666';

              }else{
                footer_item[i].style.color = '#333';
                footer_item[i].style.background = 'transparent';

              }
            }

            if(this.bgIndex !== 5){
              titleinput.style.color="#333";
              editorDom.style.color='#333';
              totalCount[0].style.color = `#666`;
              this.editorTopLeftImg = require('../assets/img/writeSkii.png');

            }else{
              titleinput.style.color="#fff";
              editorDom.style.color='#fff';
              totalCount[0].style.color = `#fff`;
              this.editorTopLeftImg = require('../assets/img/writeSkill-Black.png');
            }
    },
    toSetConfiger(){
      this.$nextTick(_=>{
        let editorDom = document.getElementById('editor');
        const reg = /rem/;
        //保存配置
        let param = {
          fontSize:editorDom.style.fontSize.replace(reg,""),
          wordSpacing:0,
          rowSpacing:editorDom.style.lineHeight.replace(reg,""),
          paragraphSpacing:0,
          background:document.getElementById('con').style.background,
          fontColor:editorDom.style.color
        }
        let tempObj = {
                  fontSize: editorDom.style.fontSize.replace(reg,""),
                  lineHeight: editorDom.style.lineHeight.replace(reg,""),
                  background: document.getElementById('con').style.background,
                  color: editorDom.style.color
          }
        this.settingObj = tempObj;
        this.setConfiger(param);
       })
    },
    
    keyup(e) {
      if(e.keyCode == 13) {
        // let select = window.getSelection();
        // let range = select.getRangeAt(0);
        // // var rangeObj = document.createRange();


        // let currentParaHeight = this.sliderValues[3] ==0?0:this.sliderValues[3].value/50;
        // let reg = /\n/g;
		    // let txt = document.getElementById('editor').innerHTML.replace(reg,`\n<div class="my_paraHeight" style="margin-bottom:${currentParaHeight}rem"></div>`);

        // let currentParaHeight = this.sliderValues[3] ==0?0:this.sliderValues[3].value/50;
        // let reg = /\n/g;
		    // let txt = document.getElementById('editor').innerHTML.replace(reg,`\n<div class="my_paraHeight" style="margin-bottom:${currentParaHeight}rem"></div>`);


        // this.editorTxt  = txt;

        // this.editorTxt  = txt;

        // //移动光标的位置
        // window.getSelection().removeAllRanges();
        // window.getSelection().addRange(range);
        // let reg = /\n/g;
        // let txt = document.getElementById('editor').innerText.replace(reg,`<div></div>`);
      }
      if(e.keyCode == 8) {
        if(document.getElementById("editor").innerText.trim().length == 0) {
          document.getElementById("editor").innerHTML = "";
          this.fontCount = 0;
        }
      }
    },
   
    
    createHtm1(e) {
      return TextToHtml(e);
    },
    editInputChange(e) {
      
      let dom = document.getElementById("editor");
      let txt = dom.innerHTML;
      this.tampEditorText = txt;
      this.isTextChangeFlag = true;
      var selection = getSelection();
      var range = selection.getRangeAt(0);
      this.beforeRangeTxt = getTextBefore(selection,range,1000,this.currentTarget);
      this.afterRangeTxt = getText(selection,range, 1000, this.currentTarget);
      this.fontCount = dom.innerText.length;
      
      this.isToVerifyWord = true;
      this.isToSaveHistory = true;
    },
    
   
    todo(val) {
      this.$nextTick(_=>{
        if(this.polishFlag && val!==1 && val!==7){
      this.polishFlag = false;
        }
      })
      
      
      this.isSaveHtmlFlag = false;
      this.isShowverifyChangeToast = false;
      if(this.verifyCurrrentDom !== ""  && this.verifyCurrrentDom.style!== undefined){
        this.verifyCurrrentDom.style.background = "transparent";
      }
      // if (val == 0 ) {
      //   this.generateResult = null;
      //   this.continuationSwitch = true;
      //   this.continuationCondition = 1 ;
      //   audio.getRenewalTextLength().then( res => {
      //     this.textColumns2 = res.data.data;
      //     this.startTextLength = this.textColumns2[this.textColumnsIndex].startTextLength;
      //     this.endTextLength = this.textColumns2[this.textColumnsIndex].endTextLength;
      //     let ss ,tt =[]
      //     res.data.data.forEach(e => {
      //       ss = e.startTextLength + '-'+ e.endTextLength
      //       tt.push(ss)
      //     });
      //     this.textColumns = tt
      //   })
      // }
       if(val == 0) {
        //改写
        this.verifyDataList = [] ;
        this.isToVerifyWord = true;
        let contents = document.getElementById('editor').innerHTML;
        this.controlPolishToast = "display:block;";
        this.poChooseList = [];
        setTimeout(_ => {
          this.$nextTick(_ => {
              this.polishFlag = true;
              let editorDom = document.getElementById('editor');
              // editorDom.style.height = `calc(100vh -  52.7rem + 4.3rem)`;
              editorDom.innerHTML = contents;
              editorDom.addEventListener("touchend",(ii)=>{
                if(!isAndroid){
                  this.tempPolishSelection = getSelection();
                  if(this.polishFlag){
                    let  content = this.tempPolishSelection.toString().replace(/(\r\n|\n|\r)/g, "\\n").replace(/<br>/g,"\\n");
                    

                    if(content!==this.selectPolishContent && (content || !this.selectPolishContent)){
                      console.log("进来");
                      let beforeDom = document.querySelector(".polishFreeChoose");
                      if(beforeDom!== undefined && beforeDom !== null){
                        let beforeContent =  document.createTextNode(beforeDom.innerText);
                        beforeDom.parentNode.insertBefore(beforeContent,beforeDom);
                        beforeDom.parentNode.removeChild(beforeDom);
                      }
                      this.selectPolishContent = content;
                        if(this.mySetTimer){
                          clearTimeout(this.mySetTimer);
                        }
                        this.mySetTimer = setTimeout(() => {
                        this.tempPolishRange = this.tempPolishSelection.getRangeAt(0);
                        let template = `<span  class="polishFreeChoose" style="background:red;"></span>`;
                        let newNode = document.createRange().createContextualFragment(template);
                        let container = newNode.querySelector('.polishFreeChoose');
                        container.appendChild(this.tempPolishRange.extractContents());
                        this.tempPolishRange.insertNode(newNode);
                        this.$nextTick(_=>{
                          let chooseContent =content;
                          let arr = chooseContent.split("\\n");
                          arr.forEach((item,index)=>{
                            if(item == ""){
                              arr[index] = `<span class="polishParagraph"><br></span>`;
                            }else{
                              arr[index] = `<span class="polishParagraph">${arr[index]}</span>`;
                            }
                          })
                          if(arr.length>1){
                            chooseContent = arr.join("<br>");
                          }else{
                            chooseContent =  `<span class="polishParagraph">${chooseContent}</span>`;
                          }
                          document.querySelector('.polishFreeChoose').innerHTML = chooseContent;
                          document.querySelector('.polishParagraph').setAttribute("contenteditable",true);
                          document.querySelector('.polishParagraph').style["-webkit-user-modify"] = "read-write-plaintext-only";
                          document.querySelector('.polishParagraph').style["-webkit-user-select"] = `text`;
                        })
                      }, 1);
                    }
                  }
                }
              })
              
              // editorDom1.style.height = `40rem`;
              setTimeout(_ => {
                let editorDom1 = document.getElementById('editor');
                editorDom1.style.height = `calc(100vh -  52.7rem + 4.3rem)`;
              },2)
            })
        },1)
      }else if (val == 1) {
        this.verifyFlag = true ;
        //控制校验窗口的显示
        this.isShowverifyChangeToast = false;
        if(this.verifyCurrrentDom !== ""  && this.verifyCurrrentDom.style!== undefined){
            this.verifyCurrrentDom.style.background = "transparent";
          }
        
        if(this.isToVerifyWord || this.verifyDataList == -1){
          
          let param = document.getElementById("editor").innerText.replace(/(\r\n|\n|\r)/g, "\\n").replace(/<br>/g,"\\n");
          //获取文本
          if(!param){
              this.showGlobalToast(1,'请输入文本');
            return ;
          }
          let cionParams =  {
            type: 'correction',
            text: param
          }
          this.polishCion(cionParams);



          
        }else{
          this.verifyIsShow = true;

          setTimeout(_ => {

            this.$nextTick(_ => {
              let editorDom = document.getElementById('editor');
              editorDom.innerHTML = this.tempVerifyOriginalText;
              editorDom.style.height = `100vh`;
              editorDom.style.height = `calc(100vh -  47.4rem)`;
              this.addVerifyEventListener();
            })
          },500)
        }
      }else if (val == 2) {
        // 近义词点击
        this.mockUri("sillimar");
        this.isHasSimillarWord();
      } else if (val == 3) {
        // 敏感词点击
        this.changeArr=[];
        let editorDom = document.getElementById("editor");
        localStorage.setItem("content", editorDom.innerText);
        this.mockUri("badwordcheck");
        this.filterBadWords();
        

      } else if (val == 4) {
        //词典
        // this.dictionariesSearchBox="";
        // this.dictionaryIsLoading = true;
        this.dictionariesPopup = true;

      }
    },

    
    touchMoveEvent(e) {
      let target = e.target;
      let dom = document.getElementById("editor");
      e.preventDefault();
      if (target.classList.contains("view_text_box")) {
        // switch (this.touchMoveDirection) {
        //   // 没动
        //   case "":
        //     break;
        //   // 向上
        //   case "goUp":
        //     dom.scrollTop += 10;
        //     break;
        //   // 向下
        //   case "goDown":
        //     dom.scrollTop -= 8;
        //     break;
        //   default:
        // }
        return
      }else {
        document.documentElement.scrollTop = 0;
      }
    },
    onFocus(e) {
      if(this.verifyIsShow){
        this.verifyIsShow = false;
        this.addVerifyEventListener();
      }
     
      
      let that = this;
      let q = window.visualViewport;
      let height = q.height;

      
      let editorDom = document.getElementById("editor");
      // 监听键盘弹起
      if(isAndroid) {
        if(this.polishFlag){
            this.controlPolishToast = "display:none;";
        }
        if(height<600){
          editorDom.style.height = `calc(100vh - 15.8rem)`;
        }else{
          editorDom.style.height = `calc(100vh - 13.5rem)`;
        }
      }else {
        if(this.polishFlag){
            this.controlPolishToast = "display:none;";
        }
        document.addEventListener("touchmove", this.touchMoveEvent, { passive: false });
      }
        q.addEventListener("resize", function (ce) {
          setTimeout(_ => {
            if (height > q.height) {
             
              let itemList = [
                // {
                //   img:require('../assets/img/written.png'),
                //   name:'续写'
                //   },
                {
                  img:require('../assets/img/runse.png'),
                  name:"改写"
                },
                {
                  img: require('../assets/img/verify.png'),
                  name: "校对"
                }, {
                  img: require('../assets/img/Synonyms.png'),
                  name: "近义词"
                }, {
                  img: require('../assets/img/badWord.png'),
                  name: "敏感词"
                }, {
                  img: require('../assets/img/dictionary.png'),
                  name: "词典"
                }, {
                  name: ""
                },{
                  name: "完成"
                }
              ];
              that.itemList = itemList;
              
              that.iskeybroadUp = true;
              that.$nextTick(_ => {
                that.footerMenuHandler();
                let footerDomH = document.getElementById("footer-resize");
                if(!isAndroid) {
                  that.keyboardHeight = (height - q.height) / 10;
                  // 重新设置输入框的高度
                  
                  if(that.polishFlag){
                    that.controlPolishToast = "display:none;";
                  }
                  if(height<600){
                    editorDom.style.height = `calc(100vh - ${parseInt((that.keyboardHeight))}rem - 13.8rem )`;
                  }else{
                    editorDom.style.height = `calc(100vh - ${parseInt((that.keyboardHeight))}rem - 12rem )`;
                  }
  
                  // 键盘弹起时为下面的菜单添加左右滑动事件
                  let startX1 = 0;
                  footerDomH.addEventListener("touchstart",function(eq) {
                    startX1 = eq.touches[0].pageX;
                  },false);
                  footerDomH.addEventListener(
                    "touchmove",
                    (op) => {
                      if(startX1< op.touches[0].pageX) {
                        footerDomH.scrollLeft -= 5;
                      }else if(startX1> op.touches[0].pageX) {
                        footerDomH.scrollLeft += 5;
                      }
                      setTimeout(_ => {
                        startX1 = op.touches[0].pageX;
                      }, 100)
                    },
                    false
                  );
                  let startX = 0, startY = 0,timer = 0;
                  //mounted生命周期中进行监听
                  editorDom.addEventListener(
                    "touchstart",
                      (ev) => {
                        startX = ev.touches[0].pageX;
                        startY = ev.touches[0].pageY;
                      },
                      false
                    );
                    editorDom.addEventListener(
                      "touchmove",
                      (ev) => {
                        if (startY < ev.touches[0].pageY) {
                          editorDom.scrollTop -= 5;
                        } else if (startY > ev.touches[0].pageY) {
                          editorDom.scrollTop += 5;
                        }
                        if(startX< ev.touches[0].pageX) {
                        }else {
                        }
                        setTimeout(_ => {
                          startY = ev.touches[0].pageY;
                        }, 100)
                      },
                      false
                    );
  
                  footerDomH.style.bottom = `${height - q.height}px`;
                  document.documentElement.scrollTop = 0;
                  that.$nextTick(_=>{
                    if(e.target.classList.contains("verifyWord")){
                        console.log(e.target.offsetTop)
                      // editorDom.scrollTo = (0,1200);
  
                    }
                  })
                }
              })
            }else {
              that.iskeybroadUp = false;
              that.$nextTick(_ => {
                document.getElementById("footer").style.bottom = 0;
              })
              // let itemList = [
              //   // {
              //   //   img:require('../assets/img/written.png'),
              //   //   name:'续写'
              //   // },
              //   {
              //     img:require('../assets/img/runse.png'),
              //     name:"改写"
              //   },
              //   {
              //     img: require('../assets/img/verify.png'),
              //     name: "校对"
              //   }, {
              //     img: require('../assets/img/Synonyms.png'),
              //     name: "近义词"
              //   }, {
              //     img: require('../assets/img/badWord.png'),
              //     name: "敏感词"
              //   }, {
              //     img: require('../assets/img/dictionary.png'),
              //     name: "词典"
              //   }
              // ];
              // that.itemList = itemList;
            }
          },200)
        })
    },
    onBlur() {
      
      document.removeEventListener("touchmove", this.touchMoveEvent);
      let editorDom = document.getElementById("editor");
      // if(this.polishFlag){
      //       this.controlPolishToast = "display:block;";
        
      //     // editorDom.style.height = `calc(100vh - 52.7rem)`;
          
        
        
      // }
      let reg = /\n/g
      //在失去焦点的时候设置文本的缓存
      if(!this.isSimillarWordCheckFlag && !this.isBadWordCheckFlag) {
        localStorage.setItem("content", editorDom.innerHTML);
      }
      setTimeout(_ => {
        if(this.polishFlag){
          
            this.controlPolishToast = "display:block;";
            editorDom.style.height = `calc(100vh - 52.7rem + 4.3rem)`;
        }else{
          editorDom.style.height = `calc(100vh - 15.8rem)`;
        }

        this.iskeybroadUp = false;
        let itemList = [
          //  {
          //      img:require('../assets/img/written.png'),
          //      name:'续写'
          // },
          {
              img:require('../assets/img/runse.png'),
              name:"改写"
          },
          {
          img: require('../assets/img/verify.png'),
            name: "校对"
          }, {
            img: require('../assets/img/Synonyms.png'),
            name: "近义词"
          }, {
            img: require('../assets/img/badWord.png'),
            name: "敏感词"
          }, {
            img: require('../assets/img/dictionary.png'),
            name: "词典"
          }
        ];
        this.itemList = itemList;
      },20)
    },

    isHasSimillarWord() {
      let editorDom = document.getElementById("editor");
      let param = editorDom.innerText;
          if (!param){
            this.showGlobalToast(1,'请输入文本');
                return;
          }
      this.loadingText = "近义词加载中";
      this.isShowLoadingFlag = true;
      this.removeContenteditable();
      editor.isHasSimillarWord({
        text:param
      }).then(res => {
        if(res.data.code == 0) {
          this.isShowLoadingFlag = true;
          this.$nextTick(_ => {
            this.editorTxt = simillarWord(res.data.data);
            this.tempOriginalText = this.editorTxt;
            this.isShowLoadingFlag = false;
            document.getElementById("simillarWordBtn").style.height = "3.7rem";
            editorDom.style.height = `calc(100vh - 17.8rem)`;
            this.isSimillarWordCheckFlag = true;
          })
        }else {
          this.errorCode(res.data);
        }
      })
    },

    // 全局处理code的方法
    errorCode(res) {
      this.isShowLoadingFlag = false;
      if(res.code == 1010) {
        this.isShowDialogFlag = true;
        this.dialogContent = res.data.msg || res.data.content;
        this.dialogConfirmText = res.data.confirmText || "确定";
        this.dialogCancelText = res.data.cancelText || "取消";
      }
    },



  //点击自定义显示自定义弹窗
   toUserDefined(){
            this.suggestToast = false;
            this.useDefinedToast = true;

        },
  //点击自定义确定修改文案
    toDefined(){
            if(this.userDefinedInput.trim() ==""){
              this.showGlobalToast(1,"请输入自定义内容");
              this.userDefinedInput = ""
              return;
            }
            let param={
                customText:this.userDefinedInput,
                masterName:this.toastItem
            }

            editor.userDefinedBad(param).then(res => {
              if(res.data.code === 0){
                this.showGlobalToast(2,'修改成功');
                this.toChangeBadWord(1);

              }else{
                this.showGlobalToast(1,res.data.msg);  
              }
            }).catch(err =>{
                console.log("checkerr=", err);
                this.showGlobalToast(1,'网络异常');
                
            })
            this.useDefinedToast = false;
        },

  //判断敏感词是否自定过
    badIsChange(param,e){
            editor.badWordCheck(param).then(res => {
                this.userDefinedArr = [...res.data.data];
                if(this.userDefinedArr.length !==0){//判断是否自定义过
                    this.isSuggest = true;
                    this.suggestToast = true;
                    this.badToastPosition(e);
                }else{
                    this.useDefinedToast = true;
                }
            }).catch(err => {
                console.log("checkerr=", err);
                this.showGlobalToast(1,'网络异常');
            })
        },

  //修改敏感词
    toChangeBadWord(i){
            let content ;
            if(i==1){//自定义修改
                 content = this.userDefinedInput;
                 this.userDefinedInput='';
            }else{
                content = this.isSuggest? this.userDefinedArr[0].customText:this.beforeContent;
            }

            //保存修改前的文本
            let flag = false;
            let findID = "";
            if(this.changeArr.length!==0){
                this.changeArr.forEach((e,i,a)=>{
                    if(e.index == this.currentIndex){
                        // a[i]={index:this.currentIndex,value:this.currentDom.innerText};
                        findID = i;
                        flag = true;
                    }
                })
                if(!flag){
                    this.changeArr.push({index:this.currentIndex,value:this.currentDom.innerText});
                }else{
                    this.changeArr.splice(findID,1);
                }
            }else{
                this.changeArr.push({index:this.currentIndex,value:this.currentDom.innerText});
            }

            this.currentDom.innerHTML = content;
            //去掉背景色
            this.currentDom.style.color = "#333";
            this.currentDom.style.display = "inline-block";
            this.currentDom.style.borderBottom =".2rem #FF8000 solid";
            this.currentDom.style.background = "transparent";
            this.suggestToast = false;

        },
        //设置弹窗的位置
        badToastPosition(e){
            this.$nextTick(_=>{
                let b = e.target.getBoundingClientRect();
                let dom = document.getElementById('suggest');

                if(b.top>=473){
                    dom.style.top = b.top-78 +'px';
                }else{
                    dom.style.top = b.top+b.height+5+'px';
                }  

                if(b.left>200&&b.left<220){
                    dom.style.left = '18rem';
                }
                else if(b.left>=220){
                    dom.style.left ='20rem';
                }
                else{
                    dom.style.left = (b.left-14)/10 +'rem';
                }

            })
        },
        //重新检测
        goBack() {
		        let txt = document.getElementById("editor").innerText;
            if (txt) {
                this.changeArr=[];
                this.suggestToast = false;
                this.useDefinedToast = false;
                let arr1 = document.querySelectorAll("#editor span");
                for(let j = 0;j<arr1.length;j++){
                    arr1[j].style.background = "transparent";
                }
                this.filterBadWords();
            } else {
              this.showGlobalToast(1,'请输入检测内容');
            }
        },
        //确定使用
        badWordEnsureUse() {
          
          this.isBadWordCheckFlag = false;
          this.suggestToast = false;
          this.useDefinedToast = false;
          this.badOriginalText = "";
          
          let editorDom = document.getElementById("editor");
          localStorage.setItem("content", editorDom.innerText);
          this.editorTxt = editorDom.innerText;
          this.isToVerifyWord = true ;
          this.isToSaveHistory = true;
          let that = this;

          setTimeout(() => {
            let bottonDom = document.getElementById("footer");
            bottonDom.style.bottom=0;
            that.addContenteditable();
            editorDom.style.height = `calc(100vh - 15.8rem)`;
          }, 1);
          
        },
        //敏感词检测
        filterBadWords() {
          let param = document.getElementById("editor").innerText;
          if (!param){
            this.showGlobalToast(1,'请输入文本');
                return;
          }  

            this.isBadWordCheckFlag = true;
            this.removeContenteditable();
            this.isShowLoadingFlag = true;
            this.loadingText = "敏感词检测中";
            editor.filterWords({
                text: param
            }).then(res => {
                this.isShowLoadingFlag = false;
                if (res.data.code == 0) {
                    this.editorTxt = res.data.data.resultText;
                      if(this.badOriginalText == ""){
                        this.badOriginalText = this.editorTxt;
                      }
                    this.badToastClick();
                    document.getElementById("editor").style.height = `calc(100vh - 17.8rem)`;
                } else {
                  this.showGlobalToast(1,'检测失败');
                }
            }).catch(err => {
                console.log("checkerr=", err);
                Toast.clear();
                this.showGlobalToast(1,'网络异常');
            })
        },

        badToastClick(){
          this.$nextTick(_=>{
            let arr = document.querySelectorAll("#editor span");
            if(arr.length==0) return this.showGlobalToast(2,"发现0个敏感词待修改");
              this.addBadListener();
            this.tempOriginalText = this.editorTxt;
          })
        },

        addBadListener(){
          
          let that = this;
          let arr = document.querySelectorAll("#editor span");
            for (let i = 0;i<arr.length;i++){
              arr[i].onclick = function(e){

                 that.suggestToast = false;
                 that.useDefinedToast = false;
                //显示背景颜色
                let arr1 = document.querySelectorAll("#editor span");
                for(let j = 0;j<arr1.length;j++){
                    arr1[j].style.background = "transparent";
                }

                arr[i].style.background = "#F6F280";
                 that.toastItem = arr[i].innerText; //获取当前点击节点的敏感词
                 that.currentDom = arr[i]; //保存当前点击的节点
                 that.currentIndex = i;
                // 判断是否修改过
                let flag = false;
                if(that.changeArr.length!==0){
                  that.changeArr.forEach(i=>{
                    if(i.index == that.currentIndex){
                      that.isSuggest = false;
                      that.beforeContent = i.value;
                      that.suggestToast = true;
                      that.badToastPosition(e);
                      flag = true;
                    }
                  })
                  if(!flag){
                    that.badIsChange({masterName: that.toastItem},e);
                  }
                }else{
                  that.badIsChange({masterName: that.toastItem},e);
                }
              }
            }
        },

        // }
        //设置配置
        setConfiger(param){
            editor.editConfig(param).then(res=>{
              if(res.data.code==0){
                //保存成功
              }else{
                this.errorCode(res.data);
              }
          })

        },
        //获取配置
          getConfiger(){
            editor.getEditConfig().then(res=>{
              if(res.data.code === 0){
                let data = res.data.data;
                if(data.background==null || data.fontSize==0 || data.rowSpacing==0 ){
                  let param = {
                    fontSize:`1.4`,
                    wordSpacing:0,
                    rowSpacing:`2.1`,
                    paragraphSpacing:0,
                    background:`rgb(255, 255, 255)`,
                    fontColor:`#333333`
                  }
                  this.setConfiger(param);
                  return;
                }

                this.sliderValues=[{
                      value:(data.fontSize-1.4)*100
                    },
                    {
                      value:(data.rowSpacing-data.fontSize)*10
                    },{
                      value:0
                    }]
                this.sliderConfiger=[{
                  value:data.fontSize*10
                },{
                  value:(data.rowSpacing-data.fontSize)*10
                },{
                  value:0
                }]

                // let editorDom = document.getElementById("editor");
                // editorDom.style.fontSize = `${data.fontSize}rem`;
                // editorDom.style.lineHeight = `${data.rowSpacing}rem`;
                // document.getElementById('con').style.background = data.background;
                // editorDom.style.color = data.fontColor;
                let tempObj = {
                  fontSize: data.fontSize,
                  lineHeight: data.rowSpacing,
                  background: data.background,
                  color: data.fontColor
                }
                this.settingObj = tempObj;
                let arr = this.bgColorList;
                arr.forEach((item,index)=>{
                  if(item.value==data.background){
                    this.bgIndex=index;
                    if( index == 5 ){
                      this.isBlack = true;
                    }
                  }
                })
                this.bgColorConfiger();

                // paragraphSpacing

              }else{
                this.errorCode(res.data);
              }
            })
          },
          copyPopup(e){
            this.showGlobalToast(e.index,e.text)
          },

          //微笑弹窗
          showGlobalToast(img,msg){
                if(img === 1){//失败
                  this.gToastImg = require('../assets/img/failure.png');
                }else{
                  this.gToastImg = require('../assets/img/success.png');
                }
                if(this.timer !== ""){
                    clearTimeout(this.timer);
                    clearTimeout(this.timerClose);
                    this.gToastIshow = false;
              // return
                }
               
                this.$nextTick(_=>{
                  this.gToastMsg = msg;
                  this.gtoastClass='cartActToast showToast';
                  this.gToastIshow = true;
                  let that = this ;
                  this.timer = setTimeout(function(){
                        that.gtoastClass='cartActToast hiddenToast'
                        that.timerClose = setTimeout(function(){
                            that.gToastIshow = false ;
                          },1500);
                    },1600);
                })
          }
  },
  created() {
  },
  mounted() {
    // if(!localStorage.getItem("searchStrFlag")) {
    //   let userAgentInfo = navigator.userAgent;
    //   let Agents = ["Android", "iPhone","SymbianOS", "Windows Phone","iPad", "iPod"];
    //   let flag = false;
    //   for (let v = 0; v < Agents.length; v++) {
    //     if (userAgentInfo.indexOf(Agents[v]) > 0) {
    //       flag = true;
    //       break;
    //     }
    //   }
    //   this.isPhoneFlag = flag;
    //   if (!this.isPhoneFlag) return
    // }
    
    
    let myState = {
      title: "逗哥写作神器",
      url: "/indexDouge" // 这个url可以随便填，只是为了不让浏览器显示的url地址发生变化，对页面其实无影响
    };
    window.history.pushState(myState, myState.title, myState.url);

    // 监听浏览器返回事件
    window.addEventListener("popstate", e => {

      let currentPathname = window.location.pathname;
      
      // 编辑器首页，执行退出保存
      if((currentPathname == "/indexDouge" || currentPathname == "/") &&
        !this.isShowHistoryversionDialogFlag &&
        !this.isShowFeeWordLibraryDialogFlag &&
        !this.isSimillarWordCheckFlag &&
        !this.isBadWordCheckFlag &&
        !this.isWritingSkillFlag && 
        !this.historyTextSwitch
      ) {
        this.updataContentById(1);
      }else {
        let myState = {
          title: "逗哥写作神器",
          url: "/indexDouge" // 这个url可以随便填，只是为了不让浏览器显示的url地址发生变化，对页面其实无影响
        };
        window.history.pushState(myState, myState.title, myState.url);
        document.getElementById("editor").style.height = `calc(100vh - 15.8rem)`;
        this.suggestToast = false;
        this.useDefinedToast = false;
        this.badOriginalText = "";
        closeMyDialog("toastCon");
        if (this.historyTextSwitch) {
          this.historyTextSwitch = false;
        }
        if(this.isShowHistoryversionDialogFlag) {
          this.isShowHistoryversionDialogFlag = false;
        }else if(this.isShowFeeWordLibraryDialogFlag) {
          this.isShowFeeWordLibraryDialogFlag = false;
        }else if(this.isSimillarWordCheckFlag) {
          this.isSimillarWordCheckFlag = false;
          let editorDom = document.getElementById("editor");
          this.editorTxt = editorDom.innerText;
          this.addContenteditable();
        }else if(this.isBadWordCheckFlag) {
          this.isBadWordCheckFlag = false;
          let editorDom = document.getElementById("editor");
          this.editorTxt = editorDom.innerText;
          this.addContenteditable();
        }else if(this.isWritingSkillFlag) {
          this.isWritingSkillFlag = false;
        }
      }
    }, false);
    //进行校验
    // this.toVerifyWords();
    //获取编辑配置
    this.getConfiger();


    let that = this;
    let dom = document.getElementById("editor");
    this.tampEditorText = TextToHtml(dom.textContent)?TextToHtml(dom.textContent): "";
    let lastTime = 0;
    let isUpdataTimeFlag = false;

    // 模拟双击
    dom.addEventListener("click",function(fe) {
      let currentTime = new Date().getTime();
      if(isUpdataTimeFlag) {
        if (currentTime - lastTime < 900) {
          that.selectionTxt = window.getSelection().toString().replace(/[\r\n]/g, "");
          isUpdataTimeFlag = false;
        }
      }
      if(!isUpdataTimeFlag) {
        lastTime = currentTime;
        isUpdataTimeFlag = true;
      }
      setTimeout(_ => {
        isUpdataTimeFlag = false;
      },1000)
    })



    // 重写粘贴事件
    dom.addEventListener("paste",(op) => {
      this.isToSaveHistory = true;
      this.isToVerifyWord = true;
      let reg = /\n/g;
      var clipboardData = (op.clipboardData || window.clipboardData);
      var val = clipboardData.getData('text').replace(reg,`，|br，`);
      
      // 清除光标前后的保存的文本
        
      if(this.tempGetselection) {
        document.getElementById("editor").innerHTML = this.tampEditorText?this.tampEditorText: '';
        if(this.afterRangeTxt && this.beforeRangeTxt) {
          // 在文本中间进行粘贴
          this.editorTxt = TextToHtml(this.beforeRangeTxt + val + this.afterRangeTxt);
        }else if(this.beforeRangeTxt && !this.afterRangeTxt) {
          // 在文章最后粘贴
          this.editorTxt = TextToHtml(this.beforeRangeTxt + val);
        }else if(this.afterRangeTxt && !this.beforeRangeTxt) {
          // 在文章开头粘贴
          this.editorTxt = TextToHtml(val + this.afterRangeTxt);
        }else {
          // // 没有文章的时候粘贴
          this.editorTxt = TextToHtml(val);
        }
        this.tampEditorText = document.getElementById("editor").innerHTML;
        this.iscanPasteFlag = false;
        this.tempRange = null;
        getSelection().removeAllRanges()
      }else {
        this.editorTxt = TextToHtml(val);
      }
      // 阻止默认的粘贴事件
      op.preventDefault();
    },{passive: false})
    this.getUserText();



    // 获取手指移动的方向
    let startX = 0, startY = 0,timer = 0;
    //mounted生命周期中进行监听
    dom.addEventListener(
      "touchstart",
      (ev) => {
        startX = ev.touches[0].pageX;
        startY = ev.touches[0].pageY;
      },
      false
    );
    dom.addEventListener(
      "touchmove",
      (ev) => {
        if (startY < ev.touches[0].pageY) {
          this.touchMoveDirection = "goDown";
          dom.scrollTop -= 8;
        } else if (startY > ev.touches[0].pageY) {
          this.touchMoveDirection = "goUp";
          dom.scrollTop += 10;
        }
        if(startX< ev.touches[0].pageX) {
          this.selectionDirection = "goTo";
        }else {
          this.selectionDirection = "goBack";
        }
        setTimeout(_ => {
          startY = ev.touches[0].pageY;
        }, 100)
      },
      false
    );
    
    // if(isAndroid){
      dom.addEventListener("contextmenu",(oo) =>{
        if(isAndroid){
          this.tempPolishSelection = getSelection();
                if(this.polishFlag){
                  let  content = this.tempPolishSelection.toString().replace(/(\r\n|\n|\r)/g, "\\n").replace(/<br>/g,"\\n");
                  if(content!==this.selectPolishContent && content){
                    
                    let beforeDom = document.querySelector(".polishFreeChoose");
                    if(beforeDom!== undefined && beforeDom !== null){
                      let beforeContent =  document.createTextNode(beforeDom.innerText);
                      beforeDom.parentNode.insertBefore(beforeContent,beforeDom);
                      beforeDom.parentNode.removeChild(beforeDom);
                    }

                    this.selectPolishContent = content ;


                    this.tempPolishRange = this.tempPolishSelection.getRangeAt(0);
                    let template = `<span class="polishFreeChoose" style="background:#EEEEEE;"></span>`;
                    let newNode = document.createRange().createContextualFragment(template);
                    let container = newNode.querySelector('.polishFreeChoose');
                    container.appendChild(this.tempPolishRange.extractContents());
                    this.tempPolishRange.insertNode(newNode);
                    this.$nextTick(_=>{
                     
                      // let chooseContent = document.querySelector('.polishFreeChoose').innerText;
                      let chooseContent =content;
                      let arr = chooseContent.split("\\n");
                      arr.forEach((item,index)=>{
                        if(item == ""){
                          arr[index] = `<span class="polishParagraph" ><br></span>`;
                        }else{
                          arr[index] = `<span class="polishParagraph" >${arr[index]}</span>`;
                        }
                      })
                      if(arr.length>1){
                        chooseContent = arr.join("<br>");
                      }else{
                        chooseContent =  `<span class="polishParagraph">${chooseContent}</span>`;
                      }
                      document.querySelector('.polishFreeChoose').innerHTML = chooseContent;
                    })
                  }
                }

        }
        // oo.preventDefault();
      })
    // }
   

    let sst = localStorage.getItem('textColumns');
    if (sst) {
      this.textColumnsIndex = sst;
    } else {
      this.textColumnsIndex = 3;
    }
  },
}
</script>

<style lang="less" scoped>
#footer-resize {
  display: none;
}

/deep/.my_dialog .van-dialog__content {
  height: auto !important;
}

/deep/.my_dialog .van-dialog__footer {
  height: 0 !important;
}

/deep/.my_dialog .van-dialog {
  padding-top: 0;
  height: auto !important;
}

/deep/.van-picker__toolbar {
  padding: 0 2.3rem;
  margin-top: 1.8rem;
  height: auto;
  box-sizing: border-box;
}

/deep/.van-picker__cancel,
/deep/.van-picker__title,
/deep/.van-picker__confirm {
  font-size: 1.6rem;
  line-height: 2rem;
  color: #333;
}

/deep/.van-picker__confirm {
  color: #43BB01;
}

/deep/.van-picker-column__item {
  font-size: 1.4rem;
}


/deep/.van-popup--round {
  border-radius: 1.1rem 1.1rem 0 0;
}

/deep/.van-slider__button {
  box-shadow: 0 0 .9rem .1rem rgba(0, 0, 0, 0.16);
}

.con {
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  background-color: #fff;
  touch-action: none;
}

.mask_box {
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  backdrop-filter: blur(.2rem);
  background: rgba(255, 255, 255, 0.1);
  z-index: 9999999999;

  .content_box {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 1.4rem;
    color: #333;

    >img {
      width: 10.8rem;
      height: 7.3rem;
    }
  }
}

.footer {
  position: fixed;
  bottom: 0;
  left: 0;
  display: flex;
  // justify-content: space-between;
  width: 100%;
  height: 6.1rem;
  padding: 0.6rem 0.6rem 0 1.9rem;
  box-sizing: border-box;
  overflow-x: auto;


  .footer_item {
    height: 3rem;
    // width: 8rem;
    border-radius: 1.2rem;
    border: .1px solid #EEEEEE;
    padding: 0 .7rem;
    box-sizing: border-box;
    background: transparent;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 1.3rem;

    .item_icon {
      margin-right: .5rem;
      margin-top: .5rem;

      >img {
        width: 2rem;
        height: 2rem;
      }
    }

    .item_name {
      font-size: 1.2rem;
      white-space: nowrap;
    }
  }

  .jianbian {
    position: fixed;
    bottom: 0;
    right: 0;
    height: 6.1rem;
    padding: 0.6rem 0.6rem 0 1.9rem;
    box-sizing: border-box;
    // background: rgba(255,255,255,.6)!important;
    background: linear-gradient(to right, rgba(255, 255, 255, 0.3), rgba(255, 255, 255, 1));
    // backdrop-filter: blur(17px)!important;
  }

  .jianbian02 {
    position: fixed;
    bottom: 0;
    right: 4.2rem;
    height: 5.1rem;
    padding: 0.6rem 0.6rem 0 1.9rem;
    box-sizing: border-box;
    background: linear-gradient(to right, rgba(255, 255, 255, 0.3), rgba(255, 255, 255, 1));
  }

}

.top_container {
  overflow: hidden;
  position: fixed;
  top: 0px;
  background-color: red;
  height: 6rem;
  width: 100%;
  padding: 1.9rem 1.9rem 2.2rem 1.9rem;
  background: transparent;
  box-sizing: border-box;
  display: flex;

  .left {
    width: 6.8rem;
    height: 2.4rem;
  }

  .right {
    position: absolute;
    right: .4rem;

    display: flex;
    justify-content: space-between;

    .totalCount {
      width: 6.3rem;
      height: 2.4rem;
      line-height: 2.4rem;
      text-align: center;
      background: transparent;
      border-radius: .8rem;
      box-sizing: border-box;
      font-size: 1.2rem;
      font-family: Microsoft YaHei-Regular, Microsoft YaHei;
      font-weight: 400;
      border: .1rem solid #DDDDDD;
      color: #666666;
      margin-right: 2rem;
    }

    .iconType {
      width: 1.8rem;
      height: 1.8rem;
      margin-top: .4rem;
      margin-right: 1.7rem;

    }
  }
}

.editor_box {

  width: 100%;
  // touch-action: none;
}

.title_box {
  height: 2.4rem;
  width: 100%;
  padding: 0 2rem;
  background-color: transparent;
  box-sizing: border-box;

}

.title_box input {
  display: flex;
  align-items: center;
  width: 100%;
  height: 2.4rem;
  font-size: 1.6rem;
  font-family: Microsoft YaHei-Bold, Microsoft YaHei;
  color: #333333;
  -webkit-user-modify: read-write-plaintext-only;
  -webkit-user-select: text;
  user-select: text;
  text-overflow: ellipsis;

}

.view_text_box {
  width: 100%;
  height: calc(100vh - 15.8rem);
  padding: 1.5rem 1.9rem 0 1.9rem;
  box-sizing: border-box;
  background: transparent;
  -webkit-user-modify: read-write-plaintext-only;
  -webkit-user-select: text;
  user-select: text;
  touch-action: auto;
  overflow-y: scroll;
  font-size: 1.4rem;
  line-height: 2.1rem;
  outline: none;

  .my_paraHeight {
    margin-bottom: 40rem;
  }

  // -webkit-overflow-scrolling:touch;
  // border: 1px  solid red;
}


.view_text_box * {
  -webkit-user-select: text;
}

// 实现placeholder效果
.view_text_box:empty:before {
  // content:attr(placeholder);
  content: "开始书写或导入文案";
  color: #999;
  font-weight: 400;
  font-size: 1.4rem;
}

.view_text_box::selection {
  background-color: #eee;
}

.current_play_color {
  color: #1577ff;
  font-weight: 700;
}

.otherContainer {
  .otherPanel {
    width: 100%;
    height: 5rem;
    line-height: 5rem;
    background: #fff;
    display: flex;

    .otherIcon {
      width: 1.9rem;
      height: 1.9rem;
      // background: #DFDFDF;
      // border-radius: .5rem;
      margin: 1.6rem 1.8rem 1.6rem 2.1rem;

    }

    .otherName {
      font-size: 1.4rem;
      height: 5rem;
      width: 85%;
      border-bottom: .1rem solid #E8E8E8;

    }

  }

  .otherPanel:first-child {
    margin-top: 1.4rem;
  }

  .otherPanel:nth-child(5) {
    .otherName {
      border-bottom: none;
    }
  }

  .line {
    width: 100%;
    height: .6rem;
    background: #FBFBFB;
  }

  .cancle {
    width: 100%;
    height: 4.7rem;
    line-height: 4.7rem;
    background: #fff;
    text-align: center;
    font-size: 1.5rem;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
    color: #333333;

  }
}

.exportContainer,
.editConfiContainer,
.moveContainer {
  .top {
    height: 4.1rem;
    line-height: 4.1rem;
    width: 100%;
    text-align: center;
    font-size: 1.4rem;
    font-family: Microsoft YaHei-Bold, Microsoft YaHei;
    font-weight: bold;
    color: #333333;
    position: relative;

    .cancle {
      position: absolute;
      top: 0;
      right: 1.8rem;
      font-size: 2.4rem;
      color: #C5C5C5;
      font-weight: normal;

    }
  }

  .top::after {
    content: '';
    display: block;
    width: 100%;
    height: .6rem;
    background: #FBFBFB;
  }


}

.exportContainer {
  .exportPanle {
    width: 100%;
    height: 4.7rem;
    line-height: 4.7rem;
    padding: 0 2rem;
    box-sizing: border-box;

    .name {
      border-bottom: .1rem solid #E8E8E8;
      font-size: 1.3rem;
      font-family: Microsoft YaHei-Regular, Microsoft YaHei;
      font-weight: 400;
      color: #333333;

    }

  }
}


.editConfiContainer {
  .top {
    margin-bottom: .6rem;

    .reset {
      position: absolute;
      top: 0;
      left: 2rem;
      font-weight: 400;
    }
  }

  .editConfiPanel {
    height: 5rem;
    width: 100%;
    padding-left: 2rem;
    position: relative;

    .name {
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 1.4rem;
      font-family: Microsoft YaHei-Regular, Microsoft YaHei;
      font-weight: 400;
      color: #333333;
      border-bottom: .1rem solid #E8E8E8;

      .choosebtn {
        width: 2.8rem;
        height: 2.8rem;
        background: #fff;
        border-radius: 1.4rem;
        padding: 0.1rem;
        box-sizing: border-box;
        margin-left: 1rem;

      }

      .bgCenter {
        width: 2.6rem;
        height: 2.6rem;
        background: #fff;
        border-radius: 1.3rem;
        padding: 0.1rem;
        box-sizing: border-box;
      }

      .bgbtn {
        width: 2.4rem;
        height: 2.4rem;
        border-radius: 1.2rem;
        border: .1px solid #DDDDDD;
      }


    }
  }

}

.moveContainer {
  height: 100%;
  width: 100%;
  overflow: hidden;

  .movePanle {
    height: 5.6rem;
    width: 100%;
    display: flex;
    align-items: center;

    .iconType {
      width: 2.2rem;
      height: 2.2rem;
      // background: #DFDFDF;
      // border-radius: .7rem;
      margin: 0 2.2rem;
    }

    .textCon {
      height: 5.6rem;
      width: 85%;
      border-bottom: .1rem solid #E8E8E8;

      .name {
        font-size: 1.3rem;
        font-family: Microsoft YaHei-Regular, Microsoft YaHei;
        font-weight: 400;
        color: #333333;
        margin: 1rem 0 .6rem 0;
      }

      .date {
        font-size: .9rem;
        font-family: Microsoft YaHei-Regular, Microsoft YaHei;
        font-weight: 400;
        color: #999;
      }
    }


  }

  .moveBottom {
    width: 100%;
    height: 7.5rem;
    background: #fff;
    display: flex;

    .createFile {
      height: 1.8rem;
      line-height: 1.8rem;
      font-size: 1.3rem;
      font-family: Microsoft YaHei-Regular, Microsoft YaHei;
      font-weight: 400;
      color: #333333;
      margin: 1.9rem 13.3rem 3.7rem 1.9rem;
    }

    .moveTo {
      width: 11.2rem;
      height: 3.7rem;
      line-height: 3.7rem;
      background: #FFFFFF;
      border-radius: 2.3rem;
      border: .1rem solid #666666;
      text-align: center;
      font-size: 1.5rem;
      font-family: Microsoft YaHei-Regular, Microsoft YaHei;
      font-weight: 400;
      color: #333333;
      margin-top: .9rem;

    }
  }

  .bottomLine {
    width: 100%;
    height: 0.6rem;
    background: #FBFBFB;
  }

}

.verifyChangeToast {
  position: absolute;
  top: 20rem;
  left: 10rem;
  height: 9.5rem;
  background: #FFFFFF;
  box-shadow: 0 .3rem .6rem .1rem rgba(0, 0, 0, 0.16);
  border-radius: .7rem;

  .ver-top {
    height: 3.2rem;
    background: linear-gradient(132deg, #BCABF3 0%, #FFFFFF 100%);
    border-radius: .7rem .7rem 0 0;
    display: flex;
    font-family: Microsoft YaHei-Bold, Microsoft YaHei;

    .ver-text {
      font-size: 1.5rem;
      font-weight: bold;
      color: #333333;
      margin: .7rem 3.6rem 0 1.1rem;

    }

    .ver-label {
      font-size: 2.3rem;
      font-family: Microsoft YaHei-Regular, Microsoft YaHei;
      font-weight: 400;
      color: #DCDCDC;
    }
  }

  .ver-ignore,
  .ver-fee {
    height: 3.1rem;
    padding: .7rem 0 0 1.1rem;
    box-sizing: border-box;
    font-size: 1.3rem;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
    color: #333333;

  }

  .ver-line {
    width: 100%;
    height: .1rem;
    background: linear-gradient(135deg, #EFECFA 0%, #F1F8FB 100%);

  }


}

.s_top {
  margin: .9rem;
  color: #666666;
  border-radius: 1.2rem;
  padding: 1.4rem 1.1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;

  .top_left {
    width: 18rem;
    font-size: 1.2rem;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;

    letter-spacing: .1rem;
  }

  .top_right_btn {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: .1rem;
    width: 10.6rem;
    height: 3.6rem;
    background: linear-gradient(to right, #CEC1F5, #CEE3F3);
    border-radius: 1.6rem;

    .top_right_btnText {
      position: relative;
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 1.6rem;
      text-align: center;
      font-size: 1.5rem;
      font-family: Microsoft YaHei-Regular, Microsoft YaHei;
      font-weight: 400;
      color: #333;
    }
  }
}

.bottom_btn {
  padding: 1rem 1.9rem 0 1.9rem;
  position: fixed;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 7.1rem;
  background-color: transparent;
  z-index: 1;
  box-sizing: border-box;

  .cancle {
    width: 10.3rem;
    height: 3.7rem;
    line-height: 3.7rem;
    border-radius: 1.5rem;
    border: .1rem solid #212121;
    text-align: center;
    font-size: 1.3rem;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
    color: #333333;
  }

  .success_btn {
    width: 18.7rem;
    height: 3.7rem;
    line-height: 3.7rem;
    background: #212121;
    border-radius: 1.5rem;
    text-align: center;
    font-size: 1.3rem;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
    color: #FFFFFF;
  }
}


.toastCon {
  position: absolute;
  top: 20rem;
  left: 10rem;
  height: 7.7rem;
  border-radius: .7rem;
  background: #FFFFFF;
  box-shadow: 0 .3rem .6rem .1rem rgba(0, 0, 0, 0.16);

  .upCon {
    height: 4.5rem;
    padding: 1.1rem .2rem 1.1rem .9rem;
    background: linear-gradient(132deg, #C3E0F1 0%, #FFFFFF 100%);
    border-radius: .7rem .7rem 0 0;
    display: flex;

    .upItem {
      height: 2.2rem;
      line-height: 2.2rem;
      background: #FFFFFF;
      border-radius: .6rem;
      border: .1rem solid #212121;
      text-align: center;
      padding: 0 .7rem;
      font-size: 1.3rem;
      font-family: Microsoft YaHei-Regular, Microsoft YaHei;
      font-weight: 400;
      color: #333333;
      margin-right: .7rem;
    }
  }

  .downCon {
    height: 3.2rem;
    width: 100%;
    background: transparent;
    border-radius: 0 0 .7rem .7rem;
    padding-top: .8rem;
    text-align: center;
    box-sizing: border-box;
    font-size: 1.1rem;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
    color: #333333;

  }

}

.loading_box {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999999;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);

  >div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 25rem;
    left: 50%;

    >img {
      width: 3.5rem;
      height: 3.5rem;
    }

    >div {
      font-size: 1.3rem;
      color: #999;
    }
  }
}

.dictionaries_body {
  position: relative;
  background: linear-gradient(124deg, rgba(162, 136, 239, 0.15) 0%, rgba(168, 217, 233, 0.15) 100%);
  height: 43.1rem;
  overflow: hidden;

  // height: 100%;
  .dicitonaryIsSearch {
    margin-top: 6rem;
    display: flex;
    padding-left: 13.5rem;

    .searchingTxt {
      font-size: 1.4rem;
      font-family: MicrosoftYaHei;
      color: #6E7C91;
    }

  }

  .tip01 {
    margin: 0;
    position: absolute;
    top: 10rem;
    padding: .9rem .8rem;
    width: 17.3rem;
    border-radius: 1rem;
    border: none;
    box-sizing: border-box;
    font-size: 1.2rem;
    font-family: MicrosoftYaHei;
    color: #FFFFFF;
    line-height: 1.6rem;
    background: rgba(0, 0, 0, .75);
    z-index: 999
  }

  .tip01-top {
    position: absolute;
    top: 8.7rem;
    margin: 0;
    width: 0rem;
    height: 0rem;
    border-width: .7rem;
    border-style: solid;
    border-color: transparent transparent rgba(0, 0, 0, .75) transparent;
    z-index: 99
  }

  .contrastBox_imgBox {

    padding-top: 9.2rem;

    img {
      width: 20rem;
      height: 13rem;

    }
  }

  .dictionarier_content {
    height: 28.6rem;
    overflow: auto;
    position: relative;

  }

  .dictionaries_searchBox {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 1.5rem;
    margin-bottom: 1.2rem;

    .dictionaries_searchBox_box {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 0.4rem;
      box-sizing: border-box;
      width: 34.5rem;
      height: 3.6rem;
      background: #FFFFFF;
      border-radius: 1.7rem;
      border: 1px solid #D6D6D6;

      &>div:nth-child(1) {
        input {
          width: 26rem;
          height: 3.6rem;
          font-size: 1.4rem;
          color: #999999;
          padding-left: 1rem;
        }
      }

      .dic-serchBtn {
        width: 5.6rem;
        height: 2.8rem;
        line-height: 2.8rem;
        text-align: center;
        font-size: 1.4rem;
        font-family: MicrosoftYaHei;
        color: #FFFFFF;
        background: #212121;
        border-radius: 1.4rem;
      }

    }
  }

  .dictionaries_top {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 3rem;

    .dictionaries_top_box {
      display: flex;
      padding-left: 6.8rem;
      align-items: center;
      font-size: 1.2rem;
      font-family: MicrosoftYaHei;
      color: #333333;
      background: #FFFFFF;
      width: 50%;
      height: 3rem;
      box-sizing: border-box;

      &>:nth-child(2) {
        margin-left: 5.4rem;
        margin-top: .2rem;

        img {
          width: 1.1rem;
          height: 1.2rem;
        }
      }
    }

    .dictionaries_top_box2 {
      display: flex;
      padding-left: 6.7rem;
      align-items: center;
      font-size: 1.2rem;
      font-family: MicrosoftYaHei;
      color: #FFFFFF;
      background: #212121;
      width: 50%;
      height: 3rem;
      box-sizing: border-box;

      &>:nth-child(2) {
        margin-left: 5.4rem;
        margin-top: .2rem;

        img {
          width: 1.1rem;
          height: 1.2rem;
        }
      }
    }
  }

  .dictionariesBox {
    height: 28.6rem;
    overflow-y: auto;
    width: 100%;

    .dictionariesBox_imgBox {
      height: 28.6rem;

      img {
        width: 20rem;
        height: 13rem;

      }
    }

    .dictionariesBox_list {
      box-sizing: border-box;
      padding: 0rem 1.5rem;
      border-bottom: 1px solid rgba(33, 33, 33, .1);
      display: flex;

      .dictionariesBox_list_top {
        font-size: 1.4rem;
        font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
        font-weight: bold;
        color: #333333;
        margin-top: 1.9rem;
      }

      .dictionariesBox_list_body {

        display: -webkit-box;
        line-height: 2rem;
        word-break: break-all;
        margin: 1rem 0 2rem 0;
        font-size: 1.3rem;
        font-family: MicrosoftYaHei;
        color: #333333;
      }

      .dictionariesBox_list_copy {
        font-size: 1.2rem;
        font-family: MicrosoftYaHei;
        color: #333333;
        text-align: -webkit-right;
        margin-left: 3.4rem;
        position: relative;

        &>div {
          width: 6rem;
          height: 2.8rem;
          border: 1px solid #333333;
          border-radius: 1.4rem;
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
        }
      }
    }
  }

  .dictionaryEmpty {
    height: 28.6rem;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 1.7rem;
    font-size: 1.2rem;
    color: #B2BCCC;

    .dicEmptyImg {
      width: 26rem;
      height: 19rem;
      margin-bottom: 2.2rem;
    }
  }

  .contrastBox {
    height: 28.6rem;
    overflow-y: auto;
    width: 100%;


    .contrastBox_list {
      box-sizing: border-box;
      padding: 0rem 1.5rem;

      display: flex;

      .contrastBox_list_top {
        font-size: 1.4rem;
        font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
        font-weight: bold;
        color: #333333;
        margin-top: 1.9rem;
      }

      .contrastBox_list_body {
        display: -webkit-box;
        line-height: 2rem;
        word-break: break-all;
        margin-top: 2rem;
        font-size: 1.3rem;
        font-family: MicrosoftYaHei;
        color: #333333;
        width: 25.1rem;

      }

      .contrastBox_list_copy {
        font-size: 1.2rem;
        font-family: MicrosoftYaHei;
        color: #333333;
        text-align: -webkit-right;
        margin-left: 2.9rem;
        position: relative;

        &>div {
          width: 6rem;
          height: 2.8rem;
          border: 1px solid #333333;
          border-radius: 1.4rem;
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
        }

      }
    }
  }

  .dictionaries_bottom {
    width: 100%;
    height: 5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.4rem;
    font-family: MicrosoftYaHei;
    color: #333333;
    background: #fff;
  }
}

.FolderBox {
  padding-top: 1.5rem;
  width: 25rem;
  font-size: 1.4rem;
  background-color: #c0c0c0;

  .FolderBox_top {
    font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
    font-weight: bold;
    color: #333333;
  }

  .FolderBox_input {
    width: 22.5rem;
    font-family: MicrosoftYaHei;
    background-color: #eeeeee;
    line-height: 19px;
    margin: 1.1rem auto 0 auto;
    border-radius: .7rem;

    input {
      box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.09);
      color: #999999;
      width: 22.55rem;
      height: 3.15rem;
      box-sizing: border-box;
      padding: 0 1rem;
      border-radius: 0.8rem;
      border: 0;
    }
  }

  .FolderBox_bottom {
    margin-top: 1.3rem;
    display: flex;
    align-items: center;
    width: 100%;
    height: 4.4rem;
    border-top: .2rem solid #dedede;

    .FolderBox_btn {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 50%;
      height: 100%;
      color: #333333;
      font-size: 1.6rem;
    }
  }
}

.header {
  margin: .9rem;
  height: 6rem;
  border-radius: 1.2rem;
  padding: 0rem 1rem;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #666666;


  .tip {
    width: 20rem;
    font-size: 1.2rem;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
    letter-spacing: .2rem;

    >img {
      width: 1.2rem;
      height: 1.4rem;

      position: relative;
      top: .2rem;
      left: .4rem;

    }

  }

  .recover {
    width: 10.4rem;
    height: 3.5rem;
    padding: 0.1rem;
    box-sizing: border-box;
    border-radius: 1.6rem;
    background-image: linear-gradient(to right, #CEC1F5, #CEE3F3);

    .recoverText {
      width: 100%;
      height: 100%;
      border-radius: 1.6rem;
      line-height: 3.3rem;
      text-align: center;
      font-size: 1.5rem;
      font-family: Microsoft YaHei-Regular, Microsoft YaHei;
      font-weight: 400;
      color: #333
    }
  }
}

.bad-bottomLine {
  width: 100%;
  height: .6rem;
  background: transparent;
  position: fixed;
  bottom: 7.1rem;
  z-index: 1;
}

.bad-bottom-btn {
  width: 100%;
  height: 7.7rem;
  position: fixed;
  top: calc(100vh - 7.7rem);
  padding: 1.1rem 1.9rem 0 1.9rem;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  z-index: 1;

  .my-btn {
    width: 18.7rem;
    height: 3.7rem;
    line-height: 3.7rem;
    background: #212121;
    border-radius: 1.5rem;
    text-align: center;
    font-size: 1.3rem;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
    color: #FFFFFF;
  }

  .my-btn-pain {

    width: 10.3rem;
    height: 3.7rem;
    margin-right: 3.3rem;
    line-height: 3.7rem;
    border-radius: 1.5rem;
    border: .1rem solid #212121;
    text-align: center;
    font-size: 1.3rem;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
    color: #333333;
  }
}

.userDefinedCon {

  width: 23.3rem;
  height: 17.2rem;
  background: linear-gradient(180deg, #FFFCB2 0%, #FFFFFF 100%);
  box-shadow: 0 .3rem .6rem .1rem rgba(0, 0, 0, 0.16);
  border-radius: .7rem;
  padding-top: .9em;
  box-sizing: border-box;

  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 99;


  .user-header {
    height: 3.1rem;
    padding-left: .6rem;
    font-size: 1.5rem;
    font-family: Microsoft YaHei-Bold, Microsoft YaHei;
    font-weight: bold;
    color: #333333;
    display: flex;
    margin-left: 1.1rem;

    .user-label {
      height: 2rem;
      padding-top: .5rem;
      box-sizing: border-box;
      font-size: 1.1rem;
      font-family: Microsoft YaHei-Regular, Microsoft YaHei;
      font-weight: 400;
      color: #666;
      margin-left: 1.1rem;
    }

  }

  .user-inp {
    margin-left: 1.1rem;
    width: 21.1rem;
    height: 3rem;
    background: #FFFFFF;
    border-radius: .7rem;
    border: .1px solid #9A9A9A;
    padding-left: .6rem;

    font-size: 1.3rem;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;

  }

  .user-line {
    margin: .9rem .4rem .7rem;
    height: .1rem;
    background: #FEFEFE;
  }

  .user-btn {
    margin: 0 1.1rem .6rem;
    height: 3.4rem;
    line-height: 3.4rem;
    border-radius: .7rem;
    border: .1rem solid #666;
    text-align: center;
    font-size: 1.3rem;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
    color: #333333;
  }
}

.suggest {
  position: absolute;
  height: 6.5rem;
  background: #FFFFFF;
  box-shadow: 0 .3rem .6rem .1rem rgba(0, 0, 0, 0.16);
  border-radius: .7rem;
  z-index: 99;


  .suggestContent {

    height: 50%;
    background: linear-gradient(135deg, #F5F17C 0%, #FFFFFF 100%);
    padding: .7rem .7rem 0 1.1rem;
    box-sizing: border-box;
    border-radius: .7rem .7rem 0 0;
    font-family: Microsoft YaHei-Bold, Microsoft YaHei;
    display: flex;



    .content {
      font-size: 1.5rem;
      font-weight: bold;
      color: #333333;
    }

    .label {
      margin: .2rem 0 0 4.5rem;
      font-size: 1.3rem;
      font-weight: 400;
      color: #DCDCDC;
    }
  }

  .userDefined {
    height: 50%;
    padding: .7rem 0 0 1.1rem;
    font-size: 1.3rem;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
    color: #333333;
  }
}

.verifyCountDot {
  width: 2.2rem;
  height: 1.4rem;
  border-radius: 1.1rem;
  line-height: 1.4rem;
  background: linear-gradient(270deg, #EA6CD5 0%, #FF0000 100%);
  text-align: center;
  position: absolute;
  top: 0rem;
  left: 23.1rem;
  font-size: 1rem;
  font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
  font-weight: bold;
  color: #FFFFFF;
  z-index: 999999999;


}


.blank_box {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  border: 1px solid red;
}

.blank_icon {
  width: 20rem;
  height: 13rem;
}

.model {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 999999999;
  background-color: rgba(0, 0, 0, .65);

  .dialog {
    position: absolute;
    top: 50%;
    left: 50%;
    margin-left: -12.5rem;
    margin-top: -6.1rem;
    width: 25rem;
    min-height: 12.2rem;
    background-color: #c5c5c5;
    border-radius: .8rem;

    .msg_box {
      padding: 1.5rem;
      width: 100%;
      min-height: 7.8rem;
      box-sizing: border-box;
      border-bottom: .1rem solid #E5E5E5;
      font-size: 1.4rem;
      color: #333;
    }

    .bottom_btn1 {
      display: flex;
      align-items: center;
      width: 100%;

      .btn {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 50%;
        height: 4.4rem;
        font-size: 1.6rem;
        color: #7254C9;
      }
    }
  }
}


.continuationBox {
  width: 100%;
  height: 100%;
  background: linear-gradient(135deg, rgba(162, 136, 239, .15) 0%, rgba(168, 217, 233, .15) 100%);

  .continuationBox_top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 2rem;
    padding-top: 1.5rem;
    box-sizing: border-box;

    .continuationBox_top_left_biaoti {

      &>div:nth-child(1) {
        font-size: 1.6rem;
        font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
        font-weight: bold;
        color: #333333;
      }

      &>div:nth-child(2) {
        font-size: 1rem;
        font-family: MicrosoftYaHei;
        color: #333333;
        background: rgba(33, 33, 33, 0.1);
        border-radius: 0.8rem;
        margin-left: 0.6rem;
        width: 5.2rem;
        height: 2.2rem;
      }
    }

    .continuationBox_top_right_img {
      &>div:nth-child(1) {
        width: 4.1rem;
        height: 3rem;
        background: #FFFFFF;
        border-radius: 1.2rem;

        img {
          width: 2rem;
          height: 1.6rem;
        }
      }

      &>div:nth-child(2) {
        margin-left: 1.4rem;

        img {
          width: 1.3rem;
          height: 0.8rem;
        }
      }
    }

  }

  .continuationBox_Text_length {
    margin-top: 1.4rem;
    height: 4rem;
    width: 100%;
    padding: 0 2rem;
    box-sizing: border-box;
    background: rgba(255, 255, 255, 0.3);

    display: flex;
    justify-content: space-between;
    align-items: center;

    font-size: 1.4rem;
    font-family: MicrosoftYaHei;
    color: #333333;

    img {
      width: 0.9rem;
      height: 0.6rem;
      margin-left: 0.6rem;
    }
  }

  .continuationBox_beginContinuation {
    position: relative;

    .xuxie {
      position: absolute;
      left: 50%;
      top: 67%;
      transform: translate(-50%, -50%);

      width: 9.8rem;
      height: 3rem;
      background: linear-gradient(132deg, #CEC1F5 0%, #CEE3F3 100%);
      border-radius: 1.5rem;

      font-size: 1.3rem;
      font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
      font-weight: bold;
      color: #333333;
    }

    .bottom_bottom {
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 1.2rem;
      font-family: MicrosoftYaHei;
      padding: 0 2rem;
      box-sizing: border-box;
      background: #fff;
      height: 6.5rem;

      &>div {
        width: 15rem;
        height: 3.4rem;
        background: #F9F9F9;
        border-radius: 0.8rem;
        display: flex;
      }

      &>div:nth-child(1) {
        color: #333333;
        background: #F9F9F9;

        img {
          width: 1.4rem;
          height: 1.4rem;
          margin-top: 0;
          margin-right: 0.4rem;
        }
      }

      &>div:nth-child(2) {
        color: #FFFFFF;
        background: #000000;

        img {
          width: 1.4rem;
          height: 1.8rem;
          margin-top: 0;
          margin-right: 0.4rem;
        }
      }
    }

    .neirong {
      box-sizing: border-box;
      padding: 0 2rem;
      font-size: 1.4rem;
      font-family: MicrosoftYaHei;
      color: #8766E8;
      line-height: 2.2rem;
      margin-top: 1.6rem;
      height: 22.5rem;
      overflow-y: auto;
    }

    img {
      width: 20.1rem;
      height: 13.8rem;
      margin-top: 6.9rem;
    }
  }
}

.wenben {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.shengchengzho {
  display: flex;
  margin-top: 2.7rem;
  font-size: 1.4rem;
  font-family: MicrosoftYaHei;
  color: #6E7C91;

  img {
    width: 1.5rem !important;
    height: 1.5rem !important;
    margin-top: 0 !important;
    margin-right: 0.8rem;
  }
}

/deep/.van-picker__confirm {
  color: #333333;
}

.dja {
  display: flex;
  justify-content: center;
  align-items: center;
}</style>