<template>
    <div class="container">
        <div class="top">
            <div style="display:flex">
                <div class="currentVersion">当前版本</div>
                <img src="../assets/img/saveCurrent.png"  style="height:2rem;width:2rem;">
            </div>
            <div class="toSave" @click="toSaveCurrent">保存</div>
        </div>
        <div class="panel" style="height: calc(100vh - 5.2rem);">
            <div class="panel-list" v-for="(item, index) in versionLists" :key="index">
                <div class="panel-date">{{ item.time }}</div>
                <div class="panel-item" :class="index2 == selectIndex? 'panel-item_active': ''" @click="selectOne(item1,index2)" v-for="(item1, index2) in item.list" :key="index2">
                    <div class="icon">
                        <img src="../assets/img/watch.png" alt="">
                    </div>
                    {{ item1.time }}
                </div>
            </div>
        </div>

        <!-- 历史版本内容的弹窗 -->
        <van-popup v-model="isShowTextContentFlag" position="bottom" round :style="{ height: '55rem' }">
            <div class="content_box">
                <div class="header">
                    <span>{{contentObj.fileTime}}</span>
                    <van-icon name="cross" class="close_icon" @click="closePopup" />
                </div>
                <div class="content_title">{{contentObj.textTitle}}</div>
                <div class="content_text" id="content_text">{{contentObj.text}}</div>
                <div class="bottom_box">
                    <div style="height: 3.9rem;display: flex;align-items: center;justify-content: space-between;">
                        <div class="btn1" @click="useText">覆盖当前文本</div>
                        <div class="btn" @click="closePopup">取消</div>
                    </div>
                </div>
            </div>
        </van-popup>
    </div>
</template>

<script>
import { editor } from "../api/server.js"
export default {
    data() {
        return {
            versionLists: [],
            selectIndex: -1,
            isShowTextContentFlag: false,
            contentObj: {},
        }
    },
    methods: {
        closePopup() {
            this.isShowTextContentFlag = false;
        },
        useText() {
            let obj = {
                isUpdata: "1",
                text: this.contentObj.text,
                title: this.contentObj.textTitle
            }
            this.$emit("useText",obj);
            this.isShowTextContentFlag = false;
            this.$router.go(-1);
        },
        toSaveCurrent(){
            let param = 1;
            this.$emit('topHandler',param);
            this.$nextTick(_=>{
                this.isShowTextContentFlag = false;
                this.$router.go(-1);
            })
           

        },
        selectOne(e,i) {
            this.isShowTextContentFlag = true;
            this.$nextTick(_=>{
                let scrollDom = document.getElementById('content_text');
                scrollDom.scrollTo(0, 0);
            })
            if(this.selectIndex == i) return
            this.selectIndex = i;
            this.contentObj = e;
        },
        getHistory() {
            let param = {
                fId: localStorage.getItem("textId"),
            };
            editor.getHistoryList(param).then(res => {
                this.versionLists = res.data.data;
            })
        },
    },
    mounted() {
        this.getHistory();
    },

}
</script>

<style lang="less" scoped>
.container {
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 0;
    background-color: #fff;
    touch-action: none;

    .top {
        width: 100%;
        height: 5.2rem;
        background: linear-gradient(132deg, rgba(162, 136, 239, .4) 0%, rgba(168, 217, 233, .4) 100%);
        padding: 1.7rem 1.9rem 0 1.9rem;
        box-sizing: border-box;
        display: flex;
        justify-content: space-between;

        .currentVersion {
            width: 6.2rem;
            height: 2rem;
            font-size: 1.5rem;
            font-family: Microsoft YaHei-Bold, Microsoft YaHei;
            font-weight: bold;
            color: #333333;
            margin-right: .9rem;

        }

        .toSave {
            width: 6.5rem;
            height: 2.6rem;
            line-height: 2.6rem;
            background: #212121;
            border-radius: 1.1rem;
            text-align: center;
            font-size: 1.3rem;
            font-family: Microsoft YaHei-Regular, Microsoft YaHei;
            font-weight: 400;
            color: #FFFFFF;
        }


    }

    .panel {
        width: 100%;
        padding: .4rem 1rem;
        box-sizing: border-box;
        overflow-y: auto;

        .panel-list {
            font-size: 1.5rem;
            font-family: Microsoft YaHei-Regular, Microsoft YaHei;
            font-weight: 400;
            color: #333333;

            .panel-date {
                padding: 0 .9rem;
                margin: .9rem 0 1.5rem 0;
            }

            .panel-item {
                padding: .7rem .9rem .9rem .9rem;
                display: flex;
                margin-bottom: 1.4rem;
                align-items: center;
                justify-content: flex-start;
                border-radius: 1.2rem;
                .icon {
                    width: 1.4rem;
                    height: 1.6rem;
                    margin-right: 1rem;
                    >img {
                        width: 100%;
                        height: 100%;
                    }
                }

                font-size: 1.3rem;

            }
            .panel-item_active {
                background-color: #EEEEEE;
            }
        }
    }
}

.content_box {
    width: 100%;
    
    .header {
        position: relative;
        width: 100%;
        height: 4.4rem;
        line-height: 4.4rem;
        text-align: center;
        border-bottom: .6rem solid #FBFBFB;
        >span {
            font-size: 1.5rem;
            font-weight: 700;
            color: #000;
        }
        .close_icon {
            position: absolute;
            top: 50%;
            right: 1.9rem;
            margin-top: -.5rem;
            font-size: 1.6rem;
            color: #C5C5C5;
        }
    }
    .content_title {
        padding: 2rem 2rem 1.6rem 2rem;
        box-sizing: border-box;
        width: 100%;
        font-size: 1.8rem;
        color: #333;
    }
    .content_text {
        width: 100%;
        height: 35rem;
        padding: 0 2rem;
        box-sizing: border-box;
        font-size: 1.4rem;
        overflow-y: auto;
    }
    .bottom_box {
        padding: 0 2rem;
        position: fixed;
        bottom: 0;
        left: 0;
        // display: flex;
        // justify-content: space-between;
        box-sizing: border-box;
        height: 8rem;
        width: 100%;
        .btn {
            font-size: 1.4rem;
            margin-right: 1.5rem;
        }
        .btn1 {
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 0 2.7rem;
            box-sizing: border-box;
            height: 3.9rem;
            font-size: 1.6rem;
            border: .1rem solid #666666;
            border-radius: 2rem;
            color: #000;
        }
    }

}
</style>