<template>
    <div class="container">    
        <div class="topTitle">将 [{{word}}] 设为免改词</div>
        <div class="toCode" @click="toCode">查看免改词库</div>

        <div class="topline"></div>
        <div class="btn" @click="addNotModifyWord">确定</div>
        <div class="btn" @click="cancle">取消</div>
      <global-toast :toastImg="gToastImg" :toastMsg="gToastMsg" :gtoastClass="gtoastClass" v-if="gToastIshow"></global-toast>
    </div>
</template> 

<script>
import { Toast } from 'vant'
import globalToast from '../globalToast/globalToast.vue';
import {
    editor
} from "../../api/server.js";

export default {
    props:['word'],
    components: {globalToast},
    data(){
        return{
            gToastImg: "",
            gToastMsg: "",
            gtoastClass: "",
            gToastIshow: false
        }
    },
    methods:{
        addNotModifyWord(){
            editor.addNotModifyWord({
                text:this.word
            }).then(res => {
                
                if(res.data.code===0){
                    this.gToastMsg = "设置成功！";
                    this.gToastImg = require('../../assets/img/success.png');
                    
                    this.$emit('toFeeofChange',1,this.word);
                }else{
                    this.gToastImg = require('../../assets/img/failure.png');
                    this.gToastMsg = "设置失败！";
                }
                this.gToastIshow = true;
                this.gtoastClass='cartActToast showToast';
                setTimeout(_ =>{
                    this.gtoastClass='cartActToast hiddenToast';
                    setTimeout(_ => {
                        this.gToastIshow = false ;
                    },1500);
                },2000);
            }).catch(err=>{
                this.gToastImg = require('../../assets/img/failure.png');
                this.gToastMsg = "设置失败！";
                this.gToastIshow = true;
                this.gtoastClass='cartActToast showToast';
                setTimeout(_ =>{
                    this.gtoastClass='cartActToast hiddenToast';
                    setTimeout(_ => {
                        this.gToastIshow = false ;
                    },1500);
                },2000);
            })
            // this.$emit('toFeeofChange');//关闭弹窗
        },
        toCode(){
            this.$emit('toFeeofChange',3);
        },
        cancle(){
            this.$emit('toFeeofChange');
        }
    }

}
</script>

<style lang="less" scoped>
    .container{
        width: 25rem;
        height: 19rem;
        background: linear-gradient(180deg, #BCABF3 0%, #FFFFFF 100%);
        box-shadow: 0 .4rem  .8rem 0 rgba(0,0,0,0.09);
        border-radius: .8rem;
        position:fixed;
        top:50%;
        left: 50%;
        transform: translate(-50%,-50%);
       
        

        .topTitle{
            width: 100%;
            height: 4.2rem;
            padding:1.5rem 0 .8rem 0;
            box-sizing: border-box;
            text-align: center;
            font-size: 1.4rem;
            font-family: Microsoft YaHei;
            color: #333333;
            margin:0 auto;
        }
        .toCode{
            width: 7.2rem;
            height: 1.6rem;
            text-align: center;
            font-size: 1.2rem;
            font-family: Microsoft YaHei;
            color: #1990FF;
            text-shadow: 0  .4rem  .8rem  rgba(0,0,0,0.09);
            border-bottom: .1rem solid #1990FF;
            margin-left: 8.9rem;
        }
        .topline{
            margin: 1.5rem .4rem; 
            height: .1rem;
            background: #FEFEFE;
           box-shadow: 0 .4rem .8rem  0 rgba(0,0,0,0.09);
        }
        .btn{
            width: 22.5rem;
            height: 3.5rem;
            line-height: 3.5rem;
            border-radius: .8rem;
            border: .1rem solid #666666;
            box-shadow: 0 .4rem  .8rem 0 rgba(0,0,0,0.09);
            text-align: center;
            font-size: 1.3rem;
            font-family: Microsoft YaHei-Regular, Microsoft YaHei;
            font-weight: 400;
            color: #333333;
            margin:0 0 1.5rem 1.2rem;
        }
        
        
    }

</style>