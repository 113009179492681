<template>
	<div class="box">
		<div class="box_top">
			<div class="dja" >
				<img src="../assets/img/fangdajing.png" alt="" />
			</div>
			<div class="dja">
				<!-- <input confirm-type="search"  v-model="inputText" type="text" :placeholder=" history == 'polish' ? '搜索我的改写记录' : '搜索我的续写记录'" /> -->
				<input  v-model="inputText" type="text" :placeholder=" history == 'polish' ? '搜索我的改写记录' : '搜索我的续写记录'" />
			</div>
			<div class="clearInput" v-if="inputText" @click="emptyInput" @touchstart="touchHandler(3,1)" @touchend="touchHandler(3,2)">
				<img :src="closeIconImg" />
			</div>
			<div class="searchBtn dja" @click="shoushuo"  @touchstart="touchHandler(4)" @touchend="touchHandler(4)" :style="searchBtnFlag?'background: linear-gradient(90deg, #E9DDFF 0%, #C78FFF 100%);':''">搜索</div>

			
        </div>
		<div class="listBox"  @scroll="scrollEvent" v-if="list.length>0">
			<div class="list" v-for="(item , index) in list" :key="index">
				<div class="list_left">
					<div>{{getTime(item.createdTime)}}</div>
					<div v-html="replaceLine(item.renewalText)"></div> 
				</div>
				<div class="list_rightBox">
					<div class="dja" @click="clickPopup(item)" :style="index == toView?'border: .1rem solid #CCCCCC;color: #999999;':''" @touchstart="touchHandler(1,index)" @touchend="touchHandler(1,index)">
						查看
					</div>
					<div class="list_right dja" @click="copyText(item.renewalText)" :style="index==toCopy?'background: #575757;':''" @touchstart="touchHandler(2,index)" @touchend="touchHandler(2,index)">
						复制
					</div>
				</div>
			</div>
			<div class="dja box_botton">
				仅保留最近七天记录 
			</div>
		</div>
		<div v-else class="listEmpty">
			<img src="../assets/img/historyEmpty.png" >
			<div class="tipText">{{ history == 'polish'?'暂无改写历史记录': '暂无续写历史记录'}}</div>

		</div>
		

        <!-- <van-popup v-model="isShow" :overlay="true" :z-index="99999" custom-class="popUpBox_popup"> -->
		<van-popup :safe-area-inset-bottom="false"  :z-index="99999" v-model="isShow"  round  ref="popup" @close="toClose">
		    <div class="popUpBox">	
				<div class='popUpContnet' v-html="popupContent"></div>
		       <!-- <scroll-div scroll-y="true"  style=" width: 100%;height: 100%; " show-scrollbar="true" >
		       	<div class='popUpContnet'>{{popupContent}}</div>
		       </scroll-div>   -->
		    </div>
		</van-popup>



        <input id="input" v-show="false" type="text" v-model="copyInput"/>
	</div> 
</template>

<script>
import {user} from "../api/server.js"
	export default {
		data() {
			return {
                copyInput:'',

				inputText:'',
				list:[],
				pageNumber:0,
				isShow:false,
				popupContent:'',
				total:null,

				total2:null,
				pageNumber2:0,
				
				whetherSearch:false,

				history:null,
				textID:null,
				toView:-1,
				toCopy:-1,
				closeIconImg:require('../assets/img/historyCloseIcon.png'),
				searchBtnFlag:false,
			}
		},
		mounted(){

            let e = JSON.parse(localStorage.getItem("historyText")); 
			// console.log('e333' , e.history);
			this.textID = e.textID * 1 
			if (e.history) {
				this.history = e.history
			}

			if (this.history == 'polish') {
                // console.log('333333');
                
				this.polishList()
			} else {
				this.listS()
			}
			
			this.created()
		},
		methods: {
		
		touchHandler(flag,index){
			if(flag == 1 ){
				this.toView == -1?this.toView = index:this.toView = -1;
			}
			else if(flag == 2 ){
				this.toCopy == -1?this.toCopy = index:this.toCopy = -1;
			}else if(flag == 3){
				if( index == 1){
					this.closeIconImg = require('../assets/img/historyCloseTouch.png.png');
				}else{
					this.closeIconImg = require('../assets/img/historyCloseIcon.png');
				}
			}else if(flag == 4){
				this.searchBtnFlag = !this.searchBtnFlag;
			}
		},
		scrollEvent(e){
            if(e.srcElement.scrollTop + e.srcElement.clientHeight == e.srcElement.scrollHeight ){ 
               
                if (this.history == 'polish') {
				if (this.whetherSearch) {
					console.log(this.total2  , '11' ,this.list.length );
					
					if (this.total2 == this.list.length) {
						return
					}
					this.shoushuoPolish2()
				} else {
					if (this.total == this.list.length) {
						return
					}
					this.polishList()
				}
			} else {
				if (this.whetherSearch) {
					if (this.total2 == this.list.length) {
						return
					}
					this.shoushuo2()
				} else {
					if (this.total == this.list.length) {
						return
					}
					this.listS()
				}
			}
            }
        },
			emptyInput(){
				this.inputText = '';
				this.pageNumber = 0 ;
				this.list = [];
				this.whetherSearch = false;
				if (this.history == 'polish') {
					this.polishList()
				} else {
					this.listS()
				}
			},
			polishList(){
				this.pageNumber = this.pageNumber + 1 
				let ss = {
					page:this.pageNumber,
					size:10,
					fId:this.textID
				}
				user.getRewriteHistoryList(ss).then( res => {
                    console.log('222222' ,res.data.data);
                    
					if (res.data.code == 0) {
						this.list = [ ...this.list, ...res.data.data.list ] ;
						this.total = res.data.data.total;
					} else {
						// uni.showModal({
						// 	title: '提示',
						// 	content: res.data.msg,
						// 	showCancel:false,
						// 	success: function (res) {
						// 		if (res.confirm) {
						// 			console.log('用户点击确定');
						// 		} else if (res.cancel) {
						// 			console.log('用户点击取消');
						// 		}
						// 	}
						// });						
					}
				})
			},
			shoushuoPolish(){
				this.whetherSearch  = true
				this.pageNumber2 = 1 
				let ss = {
					page:this.pageNumber2,
					size:10,
					text:this.inputText,
					fId:this.textID
				}
				user.searchRewriteHistoryList(ss).then( res => {
					console.log('1111res' , res);
						
					if (res.data.code == 0) {
						this.list = res.data.data.list;
						this.total2 = res.data.data.total;
					} else {
						// uni.showModal({
						// 	title: '提示',
						// 	content: res.data.msg,
						// 	showCancel:false,
						// 	success: function (res) {
						// 		if (res.confirm) {
						// 			console.log('用户点击确定');
						// 		} else if (res.cancel) {
						// 			console.log('用户点击取消');
						// 		}
						// 	}
						// });
					}
				})

			},
			shoushuoPolish2(){
				if (!this.inputText) {
					return
				}

				this.whetherSearch  = true
				this.pageNumber2 = this.pageNumber2 + 1 
				let ss = {
					page:this.pageNumber2,
					size:10,
					text:this.inputText,
					fId:this.textID
				}
				user.searchRewriteHistoryList(ss).then( res => {
					console.log('res' , res.data);
					
					if (res.data.code == 0) {
						this.list = [ ...this.list ,...res.data.data.list ] ;
						this.total2 = res.data.data.total;
					} else {
						// uni.showModal({
						// 	title: '提示',
						// 	content: res.data.msg,
						// 	showCancel:false,
						// 	success: function (res) {
						// 		if (res.confirm) {
						// 			console.log('用户点击确定');
						// 		} else if (res.cancel) {
						// 			console.log('用户点击取消');
						// 		}
						// 	}
						// });						
					}
				})
			},
			shoushuo(){
				if (!this.inputText) {
					return
				}

				if (this.history == 'polish') {
					return  this.shoushuoPolish()
				}
				this.whetherSearch  = true
				this.pageNumber2 = 1 
				let ss = {
					page:this.pageNumber2,
					size:10,
					text:this.inputText,
					fId:this.textID
				}
				user.searchRenewalHistory(ss).then( res => {
					console.log('1111res' , res);
						
					if (res.data.code == 0) {
						this.list = res.data.data.list;
						this.total2 = res.data.data.total;
					} else {
						// uni.showModal({
						// 	title: '提示',
						// 	content: res.data.msg,
						// 	showCancel:false,
						// 	success: function (res) {
						// 		if (res.confirm) {
						// 			console.log('用户点击确定');
						// 		} else if (res.cancel) {
						// 			console.log('用户点击取消');
						// 		}
						// 	}
						// });
					}
				})
			},
			shoushuo2(){
				if (!this.inputText) {
					return
				}
				this.whetherSearch  = true
				this.pageNumber2 = this.pageNumber2 + 1 
				let ss = {
					page:this.pageNumber2,
					size:10,
					text:this.inputText,
					fId:this.textID
				}
				user.searchRenewalHistory(ss).then( res => {
					console.log('res' , res.data);
					
					if (res.data.code == 0) {
						this.list = [ ...this.list,...res.data.data.list] ;
						this.total2 = res.data.data.total;
					} else {
						// uni.showModal({
						// 	title: '提示',
						// 	content: res.data.msg,
						// 	showCancel:false,
						// 	success: function (res) {
						// 		if (res.confirm) {
						// 			console.log('用户点击确定');
						// 		} else if (res.cancel) {
						// 			console.log('用户点击取消');
						// 		}
						// 	}
						// });						
					}
				})
			},
			listS(){
				this.pageNumber = this.pageNumber + 1 
				let ss = {
					page:this.pageNumber,
					size:10,
					fId:this.textID
				}
				console.log('sss' , ss);
				
				// debugger

				user.getRenewalHistory(ss).then( res => {
					console.log(res , '1111');
					if (res.data.code == 0) {
						this.total = res.data.data.total;
						this.list = [ ...this.list,...res.data.data.list] ;
					} else {
						// uni.showModal({
						// 	title: '提示',
						// 	content: res.msg,
						// 	showCancel:false,
						// 	success: function (res) {
						// 		if (res.confirm) {
						// 			console.log('用户点击确定');
						// 		} else if (res.cancel) {
						// 			console.log('用户点击取消');
						// 		}
						// 	}
						// });		
					}
				})
			},
			clickPopup(e){
                console.log('e2' ,e );
                let reg = /\\n/g;
				this.isShow = true;
				this.popupContent = e.renewalText.replace(reg,`<br>`);
				// this.popupContent = e.renewalText
				
			},
			toClose(){
				this.isShow = false;
			},

			replaceLine(text){
				let reg = /\\n/g;
				return text.replace(reg,`<br>`)
			},


			
			getTime(t) {                                //根据时间戳来解析返回格式为 xxxx-x-x xx:xx:xx 的格式
			    const date = new Date(t);
			    const year = date.getFullYear();
			    const month = date.getMonth() + 1;
			    const day = date.getDate();
			    const hours = date.getHours();
			    const minute = date.getMinutes();
			    const seconds = date.getSeconds();
			    return `${year}-${month}-${day} ${hours.toString().length > 1 ? hours : '0' + hours.toString()}:${minute.toString().length > 1 ? minute : '0' + minute.toString()}:${seconds.toString().length > 1 ? seconds : '0' + seconds.toString()}`
			},
			copyText(e){
                const that = this
                return new Promise((reslove, reject) => {
                try {
                        let input = document.createElement('input');
                        document.documentElement.append(input);
                        input.value = e;
                        input.select();
                        document.execCommand("copy");
                        document.documentElement.removeChild(input);
                        
                        that.$emit('copyPopup' , {index: 2, text:'复制成功' } )
                        reslove();
                        
                    }
                    catch (err) {
                        reject(err);
                        that.$emit('copyPopup' , {index: 1, text:'复制失败' } )
                    }
                })

			},
            created(){
                window.onscroll = function(){
                //变量scrollTop是滚动条滚动时，距离顶部的距离
                var scrollTop = document.documentElement.scrollTop||document.body.scrollTop;
                //变量windowHeight是可视区的高度
                var windowHeight = document.documentElement.clientHeight || document.body.clientHeight;
                //变量scrollHeight是滚动条的总高度
                var scrollHeight = document.documentElement.scrollHeight||document.body.scrollHeight;
                    //滚动条到底部的条件
                    if(scrollTop+windowHeight==scrollHeight){
                        //写后台加载数据的函数
                         console.log("距顶部"+scrollTop+"可视区高度"+windowHeight+"滚动条总高度"+scrollHeight);
                    } 
                }
            }

		},
		onReachBottom(){
			console.log('8888');
			if (this.history == 'polish') {
				if (this.whetherSearch) {
					console.log(this.total2  , '11' ,this.list.length );
					
					if (this.total2 == this.list.length) {
						return
					}
					this.shoushuoPolish2()
				} else {
					if (this.total == this.list.length) {
						return
					}
					this.polishList()
				}
			} else {
				if (this.whetherSearch) {
					if (this.total2 == this.list.length) {
						return
					}
					this.shoushuo2()
				} else {
					if (this.total == this.list.length) {
						return
					}
					this.listS()
				}
			}

		}
	}
</script>

<style lang="less" scoped>
.dja {
	display: flex;
	justify-content: center;
	align-items: center;
}
.box {
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 0;
    background: linear-gradient(225deg, #FEF3FB 0%, #E8F1F6 100%);
    // touch-action: none;
}
.box_top{
	display: flex;
	align-items: center;
	margin:  0 2rem;
	margin-top:1.2rem ;
	// padding: 0 1rem;
	box-sizing: border-box;
	background: #FFFFFF;
	border-radius: 1.6rem;
	height: 3.2rem;
	position: relative;
	
	&>div:nth-child(1){
		// background: orange;
		padding-left: 1rem;
		padding-right: 0.8rem;
		img {
			width: 1.2rem;
			height: 1.2rem;
		}
	}
	&>div:nth-child(2) {
		width: calc(100vw - 15.5rem);
		input {
			width: 100%;
            font-size: 1.4rem;
		}
	}
	// &>div:nth-child(3){
	// 	padding-left: 0.8rem;
	// 	img {
	// 		width: 1.2rem;
	// 		height: 1.2rem;
	// 	}
	// } 
	.clearInput{
		margin:0 1rem;
		&>img {
			width: 1.4rem;
			height: 1.4rem;
		}
	}
	.searchBtn{
		position: absolute;
		right:.2rem;
		top:.2rem;
		width: 4.9rem;
		height: 2.8rem;
		background: linear-gradient(90deg, #B39DDB 0%, #9030EF 100%);
		border-radius: 1.4rem;
		font-size: 1.2rem;
		font-family: MicrosoftYaHei;
		color: #FFFFFF;
	}
	input{
        width: calc(100vw - 15.5rem);
        height: 3.2rem;
        background: #FFFFFF;
        // border-radius: 1.6rem;
        font-size: 1.4rem;
        font-family: MicrosoftYaHei;
        color: #333333;
        box-sizing: border-box;
    }
}

.listBox{
	margin-top: 0.9rem;
	height: calc(100% - 5.5rem);
	overflow-y: auto;
	.list{
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding:  0 2rem;
		box-sizing: border-box;

		border-bottom: 1px solid #EEEEEE;
		padding-bottom: 1.5rem;
		padding-top: 1.5rem;
		.list_left{

			&>div:nth-child(1) {
				font-size: 1.4rem;
				font-family: MicrosoftYaHei;
				color: #333333;
			}
			&>div:nth-child(2){
				font-size: 1.2rem;
				font-family: MicrosoftYaHei;
				color: #999999;
				margin-top:0.4rem;
				width: 19rem;
				height: 1.6rem;
				overflow: hidden;
  				text-overflow: ellipsis;
  				white-space: nowrap;
			}
		}
		.list_rightBox{
			display: flex	;
			&>div:nth-child(1){
				width: 5rem;
				height: 3rem;
				border-radius: 1rem;
				border: 1px solid #212121;

				font-size: 1.2rem;
				font-family: MicrosoftYaHei;
				color: #333333;
			}
		}
		.list_right{
			width: 5rem;
			height: 3rem;
			background: #212121;
			border-radius: 1rem;

			font-size: 1.2rem;
			font-family: MicrosoftYaHei;
			color: #FFFFFF;
			margin-left:1rem ;
		}
	}
	.box_botton{
	font-size: 1.2rem;
	font-family: MicrosoftYaHei;
	color: #CCCCCC;
	margin-top: 1.5rem;
	padding-bottom: 2rem;
}
}
.listEmpty{
	width: 100vw;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	&>img{
		width: 26rem;
		height: 19rem;
		margin:10.1rem 0 2.1rem 0 ;
	}
	.tipText{
		font-size: 1.2rem;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #B2BCCC;
	}
}



.popUpBox{
	// max-height: 32rem;
	// min-height: 5rem;
	height: 32rem;
	
	width: 29.5rem;
	
	//  height: 32rem;
	 padding:2rem 1.2rem 2.2rem 2rem;
	 box-sizing: border-box;
	 .popUpContnet{
		 
		 height: 100%;
		 font-size: 1.3rem;
		 font-family: MicrosoftYaHei;
		 color: #333333;
		 line-height: 1.7rem;
		 overflow-y: auto;
		 
	 }
	 
 }

/deep/  .van-popup--round{
	border-radius: 1.4rem !important;
}

 /deep/ ::-webkit-scrollbar {
 	 //滚动条整体样式
 	    display: block;
 	    width: 0.6rem !important;
 	    height: 1rem !important;
 	    -webkit-appearance: auto!important;
 	    background: #EEEEEE;
 	    overflow: auto!important;
  }
  /deep/ ::-webkit-scrollbar-thumb {
 	 //滚动条里面小方块
  	   border-radius: 0.2rem !important;
 	   box-shadow: inset 0 0 0.5rem rgba(0,0,0,0.2) !important;
 	   background: #CCCCCC !important;
  }
  /deep/ ::-webkit-scrollbar-track {
  	   //滚动条
  	   border-radius: 1rem !important;
  	   box-shadow: inset 0 0 0.5rem rgba(0,0,0,0.2) !important;
  	   background-color: #ededed !important;
  }
</style>