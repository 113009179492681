<template>
  <div>
    <div class="loading_box" v-if="show">
      <div class="center_box">
        <img src="../../assets/img/loading.gif" alt="">
        <div>{{loading_text}}</div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      loading_text: {
        type: String,
        default: ""
      },
      show: {
        type: Boolean,
        default: false
      }
    },
    data() {
      return {}
    },
    methods: {},
    mounted() {}
  }
</script>

<style lang="less" scoped>
  .loading_box {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9999999999;
    width: 100%;
    height: 100%;
    .center_box {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      position: absolute;
      top: 50%;
      left: 50%;
      margin-left: -7.5rem;
      margin-top: -6rem;
      width: 15rem;
      height: 12rem;
      background-color: #181818;
      border-radius: 1.8rem;
      >img {
        width: 4rem;
        height: 4rem;
      }
      >div {
        margin-top: .9rem;
        font-size: 1.4rem;
        color: #fff;
      }
    }
  }
</style>