import axios from 'axios';

let searchStr = window.location.search;
console.log("77777777777",searchStr);
if(searchStr.indexOf("?from=") != -1) {
	localStorage.setItem("isShareToMakeMoney",1);
	localStorage.setItem("isShareToMakeMoneyParam",searchStr.substring(searchStr.indexOf("?from=")+6));
	localStorage.setItem("searchStrFlag","");
}else {
	if(searchStr.length>0) {
		localStorage.setItem("searchStrFlag",searchStr);
	}else {
		localStorage.setItem("searchStrFlag","");
	}
	localStorage.setItem("isShareToMakeMoney",0);
	if(searchStr.search("originalPage") != -1) {
		let page = searchStr.substring(searchStr.indexOf("originalPage=")+13,searchStr.indexOf("?token="));
		localStorage.setItem("originalPage",page);
		localStorage.removeItem("isCreateNew");
		let token = searchStr.substring(searchStr.indexOf("token=")+6,searchStr.indexOf("?textId")>-1?searchStr.indexOf("?textId"): searchStr.length);
		localStorage.setItem("token",token);
		if(page == 'home') {
			let textId = searchStr.substring(searchStr.indexOf("textId=")+7);
			textId? localStorage.setItem("textId",textId) : '';
		}
		console.log("token",localStorage.getItem("token"),"originalPage",localStorage.getItem("originalPage"),"textid",localStorage.getItem("textId"));
	}else {
		let token = searchStr.substring(searchStr.indexOf("token=")+6,searchStr.indexOf("?textId="));
		localStorage.setItem("token",token);
		localStorage.removeItem("originalPage");
		let textId = "";
		if(searchStr.indexOf("isCreateNew") != -1) {
			textId = searchStr.substring(searchStr.indexOf("textId=")+7,searchStr.indexOf("?isCreateNew"));
			localStorage.setItem("isCreateNew",searchStr.substring(searchStr.indexOf("isCreateNew=")+12));
		}else {
			textId = searchStr.substring(searchStr.indexOf("textId=")+7);
			localStorage.removeItem("isCreateNew");
		}
		localStorage.setItem("textId",textId);
	}
}

//  localStorage.setItem("token","8142376086190231");
axios.defaults.timeout=180000;
axios.defaults.headers.post['X-Access-Token'] = localStorage.getItem("token");
axios.defaults.headers.get['X-Access-Token'] = localStorage.getItem("token");
// axios.defaults.baseURL="https://test.write.douge.club/";  // 测试环境
axios.defaults.baseURL="https://write.douge.club/";  // 生产环境
// axios.defaults.baseURL = "";    //本地开发
let editor = {};
	 const server = {
		filterWords(param) {
			return axios.post('/writing/user/filterWords', param);
		},
		//敏感词自定义
		userDefinedBad(param){
			return axios.post('/writing/user/customTag',param);
		},
		//敏感词是否修过自定义过
		badWordCheck(param){
			return axios.post('/writing/user/wordsReplace',param);
		},
		//校验
		verifyWord(param){
			return axios.post('/writing/user/TextCorrectionPro',param);
		},
		//讯飞校验
		iflytekVerifyWord(param){
			return axios.post('/writing/user/webTextCorrection',param);
		},
		//设置为免改词
		addNotModifyWord(param){
			return axios.post('/writing/user/addNotModifyWord',param);
		},
		//查看免改词库
		getNotModifyWord(param){
			return axios.post('/writing/user/getNotModifyWord',param);
		},
		removeModifyWord(param){
			return axios.post('/writing/user/deleteNotModifyWord',param)
		},
		//保存配置
		editConfig(param){
			return axios.post('/writing/user/editConfig',param);
		},
		//获取配置
		getEditConfig(){
			return axios.post('/writing/user/getEditConfig');
		},
		//免改词忽略
		addIgnoreWord(param){
			return axios.post('/writing/user/addIgnoreWord',param);
		},
		//写作技巧
		writingSkills(){
			return axios.post('/writing/anonymous/writingSkills');
		},
		//润色
		textRewriting(param){
			return axios.post('/writing/user/textRewriting',param);
		},
		checkDeductCoin(param){
			return axios.post('/writing/user/checkDeductCoin',param);
		},
		listenBysentence(param) {
			return axios.post('/peiyin/user/wxAudition', param, { responseType: 'blob' });
		},
		getSign() {
			return axios.post('/peiyin/user/v1')
		},
		createTaskId(param) {
			return axios.post('/peiyin/user/synAuditionGenerateVoiceAcodeNew', param)
		},
		generateAudioLink(param) {
			return axios.post('/peiyin/user/getVoiceAudioUrl', param)
		},
		newCreateTaskId(param) {
			return axios.post('/peiyin/user/synNewAuditionGenerateVoiceAcode', param)
		},
		auditionClause(param) {
			return axios.post('/peiyin/user/auditionClause', param)
		},
		isHasSimillarWord(param) {
			return axios.post('/writing/user/hasSynonym',param)
		},
		getSimillarListByWord(param) {
			return axios.post("/writing/user/getSynonymSubstitution",param)
		},
		 outputWord(param){return axios.post('/writing/user/getWord',param)},
		 outputImg(param) {
			 return axios.post('/writing/user/getImg',param)
		 },
		 outputTxt(param) {
			 return axios.post('/writing/user/getTxt',param)
		 },
		 getUserText() {
			return axios.post('/writing/user/selectCacheWrite')
		 },
		 createTextAndHistory(param) {
			return axios.post('/writing/user/saveHistory',param)
		 },
		 getHistoryList(param) {
			return axios.post('/writing/user/getHistoryList',param)
		 },
		 updataContent(param) {
			return axios.post("/writing/user/save2updateFile",param)
		 },
		 getWxLink() {
			return axios.post("/writing/anonymous/getWenanConnect")
		 },
		 getH5ShareUrl(param) {
			return axios.post("/writing/anonymous/getH5ShareUrl",param)
		 },
	}
	editor = server;

const audio = {
	getNumberList(param) {
		return axios.post('/peiyin/user/getDigitalPronunciation', param)
	},
	selectDict(param) {  // 正向
		return axios.post('/writing/user/selectDict', param)
	},
	describe(param) {    // 反向词典
		return axios.post('/writing/user/describe', param)
	},
	selectMkdirList(param) {    // 查询文件
		return axios.post('/writing/user/selectMkdirList', param)
	},
	insertFileHome(param) {    // 增加新文件夹
		return axios.post('/writing/user/insertFileHome', param)
	},
	moveTextFile(param) {    // 移入
		return axios.post('/writing/user/moveTextFile', param)
	},
	renewal(param) {    // 续写
		return axios.post('/writing/user/renewal', param)
	},
	getRenewalResult(param){
		return axios.post('/writing/user/getRenewalResult',param);
	},
	getRenewalTextLength(param) {    // 续写文本长度
		return axios.post('/writing/anonymous/getRenewalTextLength', param)
	},
	checkDeductCoin(param) {    // 检查需要扣除多少金币
		return axios.post('/writing/user/checkDeductCoin', param)
	},
	getGuidance(param) {    // 
		return axios.post('/writing/user/getGuidance', param)
	},
	 updateGuidance(param) { // 
		 return axios.post('/writing/user/updateGuidance', param)	
	},
}
const  user = {
	getRewriteHistoryList (param) {   //   查询记录的 
		return axios.post("/writing/user/getRewriteHistoryList ",param)
	},
	searchRewriteHistoryList (param) {   //   搜索记录的 
		return axios.post("/writing/user/searchRewriteHistoryList ",param)
	},
	getRenewalHistory (param) {   // 搜索续写历史
		return axios.post("/writing/user/getRenewalHistory ",param)
	},
	searchRenewalHistory (param) {   // 搜索续写历史
		return axios.post("/writing/user/searchRenewalHistory ",param)
	},
}

export {
	editor,
	audio,
	user
}



