import CryptoJS from 'crypto-js'
var SHA512 = require("crypto-js/sha512");

export function myDecode(text,origin) {
  var key = CryptoJS.enc.Utf8.parse("abcdefgabcdefg12");
  var decryptedData = CryptoJS.AES.decrypt(text, key, { 
    mode: CryptoJS.mode.ECB,  
    padding: CryptoJS.pad.Pkcs7
  })
  let decryStr = decryptedData.toString(CryptoJS.enc.Utf8);
  if(origin == "sign") {
    let arr = decryStr.split("|");
    return arr[1]+arr[0]
  }else {
    return decryStr
  }
  
}

export function myEncode(text) {
    //输出加密后的哈希sha512
    return SHA512(text).toString();
}