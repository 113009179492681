const server = require("../api/server.js");
// 处理光标的位置后面获取文本
export function getText(selection, range, length, currentTarget) {
  // console.log("直接得到的文本为",selection.anchorNode.textContent);
  let reg = /×|「|」/g;
  let reg2 = /\n|<br>/g;
  let reg1 = /倍\|/g;
  // 如果点击的是 样式外部的文字
  let targetDom = currentTarget;
  let selectNode = selection.anchorNode.parentNode;
  if (currentTarget.innerText == "」") return currentTarget.innerText;
  if (currentTarget.classList.contains("view_text_box")) {
      // 检测是不是多音字
      for (let j = 0; j < 100; j) {
          if ((selectNode.classList.contains("duoyin-control-content") || selectNode.classList.contains("duoyin-control")) && selectNode.textContent) {
              j++;
              if(selectNode.parentNode.classList.contains("view_text_box")) {
                  break;
              }else {
                  selectNode = selectNode.parentNode;
              }
          } else {
              j = 100;
          }
      }
      // // 检测是不是变速
      // for (let j = 0; j < 100; j) {
      //     if ((selectNode.classList.contains("duoyin-control-content") || selectNode.classList.contains("duoyin-control")) && selectNode.textContent) {
      //         j++;
      //         if(selectNode.parentNode.classList.contains("view_text_box")) {
      //             break;
      //         }else {
      //             selectNode = selectNode.parentNode;
      //         }
      //     } else {
      //         j = 100;
      //     }
      // }
      let myText = selection.anchorNode.textContent;
      
      let myIndex = range.startOffset;
      let lastText = "";  // 从光标位置开始分割当前文本
      let meNode = null; // 下一个节点
      if(selectNode.classList && selectNode.classList.contains("speed-control-content") || selectNode.classList.contains("duoyin-control")) {
        meNode = selectNode.nextSibling;
          lastText = selectNode.textContent.replace(reg,"");
      }else {
          meNode = selection.anchorNode.nextSibling; 
          lastText = myText.substring(myIndex, myText.length);
      }
      let meNodeTxt = "";
      if (meNode && meNode.classList) {
          meNodeTxt = meNode.textContent;
      }
      let readyTxt = lastText + meNodeTxt;
      for (let i = 0; i < length; i) {
          if (meNode && meNode.nextSibling) {
              if (meNode.nextSibling.classList && meNode.nextSibling.classList.contains("statistics")) {
                  console.log("wwwwwwwwwwwwwwwww");
              }else {
                  meNode = meNode.nextSibling;
                  readyTxt += meNode.textContent;
              }
              // meNode = meNode.nextSibling;
              // console.log(`第${i}个menode`,meNode);
              // readyTxt += meNode.textContent;
              i++;
          } else {
              i = length
          }
      }
      return readyTxt.replace(reg, "").replace(reg1, "|").replace(reg2,"，|br，");
  } else if (currentTarget.classList.contains("speed-control-content") || currentTarget.classList.contains("duncuo-control-content") || currentTarget.classList.contains("duoyin-control-content") || currentTarget.classList.contains("duoyin-control")) { // 点击的是变速内部或者顿挫调节
      // 确保操作时最外层是 可编辑的div
      // console.log("当前点击的是",selection.anchorNode.parentNode.textContent);
      if (!targetDom.parentNode.parentNode.classList.contains("view_text_box")) {
          for (let j = 0; j < 100; j) {
              if (targetDom.parentNode.classList.contains("speed-control") || targetDom.parentNode.classList.contains("speed-control-content") || targetDom.parentNode.classList.contains("duncuo-control-content") || targetDom.parentNode.classList.contains("duoyin-control") || targetDom.parentNode.classList.contains("duoyin-control-content")) {
                  j++;
                  targetDom = targetDom.parentNode;
              } else {
                  j = 100;
              }
          }
          let currentTargetTxt = targetDom.textContent; // 获取到点击的样式内的文本及携带的协议
          let meNodeTxt = "";
          // let meNode = null; // 下一个节点
          let meNode = targetDom.nextSibling; // 下一个节点
          // if(selectNode.classList.contains("speed-control-content") || selectNode.classList.contains("duoyin-control")) {
          //     meNode = selectNode.nextSibling;
          // }else {
          //     meNode = selection.anchorNode.nextSibling; 
          // }
          if (meNode) {
              meNodeTxt = meNode.textContent;
          }
          let readyTxt = currentTargetTxt + meNodeTxt;
          for (let i = 0; i < length * 2 + 1; i++) {
              if (meNode && meNode.nextSibling) {
                  meNode = meNode.nextSibling;
                  readyTxt += meNode.textContent;
              }
          }
          return readyTxt.replace(reg, "").replace(reg1, "|").replace(reg2,"，|br，");
      } else {
          let currentTargetTxt = currentTarget.parentNode.textContent; // 获取到点击的样式内的文本及携带的协议
          let meNodeTxt = "";
          let meNode = currentTarget.parentNode.nextSibling; // 下一个节点
          if (meNode) {
              meNodeTxt = meNode.textContent;
          }
          let readyTxt = currentTargetTxt + meNodeTxt;
          for (let i = 0; i < length * 2 + 1; i++) {
              if (meNode && meNode.nextSibling) {
                  meNode = meNode.nextSibling;
                  readyTxt += meNode.textContent;
              }
          }
          return readyTxt.replace(reg, "").replace(reg1, "|").replace(reg2,"，|br，");
      }
  } else if (currentTarget.classList.contains("speed-control-brackets")) {
      // 确保操作时最外层是 可编辑的div
      if (!targetDom.parentNode.parentNode.classList.contains("view_text_box")) {
          for (let j = 0; j < 100; j) {
              if (targetDom.parentNode.classList.contains("speed-control") || targetDom.parentNode.classList.contains("speed-control-content") || targetDom.parentNode.classList.contains("duncuo-control-content")) {
                  j++;
                  targetDom = targetDom.parentNode;
              } else {
                  j = 100;
              }
          }
          let currentTargetTxt = targetDom.textContent; // 获取到点击的样式内的文本及携带的协议
          let meNodeTxt = "";
          let meNode = targetDom.nextSibling; // 下一个节点
          if (meNode) {
              meNodeTxt = meNode.textContent;
          }
          let readyTxt = currentTargetTxt + meNodeTxt;
          for (let i = 0; i < length * 2 + 1; i++) {
              if (meNode && meNode.nextSibling) {
                  meNode = meNode.nextSibling;
                  readyTxt += meNode.textContent;
              }
          }
          return readyTxt.replace(reg, "").replace(reg1, "|").replace(reg2,"，|br，");
      } else {
          let meNodeTxt = "";
          let meNode = currentTarget.parentNode.nextSibling; // 下一个节点
          if (meNode) {
              meNodeTxt = meNode.textContent;
          }
          let readyTxt = meNodeTxt;
          for (let i = 0; i < length * 2 + 1; i++) {
              if (meNode && meNode.nextSibling) {
                  meNode = meNode.nextSibling;
                  readyTxt += meNode.textContent;
              }
          }
          return readyTxt.replace(reg, "").replace(reg1, "|").replace(reg2,"，|br，");
      }
  } else {
      // 处理回车键的文本
      let patt = /,|，|;|……|！|。|？/
      // 没有效果的文本
      let myText = selection.anchorNode.textContent;
      let myIndex = range.startOffset;
      let lastText = myText.substring(myIndex, myText.length);  // 从光标位置开始分割当前文本
      let punctuationStr = lastText.substring(lastText.length - 2); // 得到点击当前段最后一个字符
      if (punctuationStr.search(patt) == -1) {
          // 若改段文本没有符号,添加一个逗号
          lastText = lastText + "，"
      }
      let meNode = selection.anchorNode.parentNode.nextSibling; // 下一个节点
      let meNodeTxt = "";
      if (meNode) {
          meNodeTxt = meNode.textContent;
      }
      let readyTxt = lastText + meNodeTxt;
      for (let i = 0; i < length * 2 + 1; i++) {
          if (meNode && meNode.nextSibling) {
              meNode = meNode.nextSibling;
              readyTxt += meNode.textContent;
          }
      }
      return readyTxt.replace(reg, "").replace(reg1, "|").replace(reg2,"，|br，");
  }
}

// 获取html
export function getInnerHTML(selection, range, length, currentTarget) {
  // console.log("直接得到的文本为",selection.anchorNode.textContent);
  let reg = /×|「|」/g;
  // let reg2 = /\n/g;
  let reg1 = /倍\|/g;
  // 如果点击的是 样式外部的文字
  let targetDom = currentTarget;
  let selectNode = selection.anchorNode.parentNode;
  if (currentTarget.innerText == "」") return currentTarget.innerText;
  if (currentTarget.classList.contains("view_text_box")) {
      // 检测是不是多音字
      for (let j = 0; j < 100; j) {
          if ((selectNode.classList.contains("duoyin-control-content") || selectNode.classList.contains("duoyin-control")) && selectNode.textContent) {
              j++;
              if(selectNode.parentNode.classList.contains("view_text_box")) {
                  break;
              }else {
                  selectNode = selectNode.parentNode;
              }
          } else {
              j = 100;
          }
      }
      // // 检测是不是变速
      // for (let j = 0; j < 100; j) {
      //     if ((selectNode.classList.contains("duoyin-control-content") || selectNode.classList.contains("duoyin-control")) && selectNode.textContent) {
      //         j++;
      //         if(selectNode.parentNode.classList.contains("view_text_box")) {
      //             break;
      //         }else {
      //             selectNode = selectNode.parentNode;
      //         }
      //     } else {
      //         j = 100;
      //     }
      // }
      let myText = selection.anchorNode.textContent;
      let myIndex = range.startOffset;
      let lastText = "";  // 从光标位置开始分割当前文本
      let meNode = null; // 下一个节点
      if(selectNode.classList && selectNode.classList.contains("speed-control-content") || selectNode.classList.contains("duoyin-control")) {
          meNode = selectNode.nextSibling;
          lastText = selectNode.textContent.replace(reg,"");
      }else {
          // 
          meNode = selection.anchorNode.nextSibling; 
          lastText = myText.substring(myIndex, myText.length);
      }
      let meNodeTxt = "";
      if (meNode) {
          meNodeTxt = meNode.textContent;
      }
      let readyTxt = lastText + meNodeTxt;
      for (let i = 0; i < length; i) {
          if (meNode && meNode.nextSibling) {
              if (meNode.nextSibling.classList && meNode.nextSibling.classList.contains("statistics")) {
                  console.log("");
              }else {
                  meNode = meNode.nextSibling;
                  // console.log(`第${i}个menode`,meNode);
                  readyTxt += meNode.textContent;
              }
              // meNode = meNode.nextSibling;
              // console.log(`第${i}个menode`,meNode);
              // readyTxt += meNode.textContent;
              i++;
          } else {
              i = length
          }
      }
      return readyTxt.replace(reg, "").replace(reg1, "|");
  } else if (currentTarget.classList.contains("speed-control-content") || currentTarget.classList.contains("duncuo-control-content") || currentTarget.classList.contains("duoyin-control-content") || currentTarget.classList.contains("duoyin-control")) { // 点击的是变速内部或者顿挫调节
      // 确保操作时最外层是 可编辑的div
      // console.log("当前点击的是",selection.anchorNode.parentNode.textContent);
      if (!targetDom.parentNode.parentNode.classList.contains("view_text_box")) {
          for (let j = 0; j < 100; j) {
              if (targetDom.parentNode.classList.contains("speed-control") || targetDom.parentNode.classList.contains("speed-control-content") || targetDom.parentNode.classList.contains("duncuo-control-content") || targetDom.parentNode.classList.contains("duoyin-control") || targetDom.parentNode.classList.contains("duoyin-control-content")) {
                  j++;
                  targetDom = targetDom.parentNode;
              } else {
                  j = 100;
              }
          }
          let currentTargetTxt = targetDom.textContent; // 获取到点击的样式内的文本及携带的协议
          let meNodeTxt = "";
          // let meNode = null; // 下一个节点
          let meNode = targetDom.nextSibling; // 下一个节点
          // if(selectNode.classList.contains("speed-control-content") || selectNode.classList.contains("duoyin-control")) {
          //     meNode = selectNode.nextSibling;
          // }else {
          //     meNode = selection.anchorNode.nextSibling; 
          // }
          if (meNode) {
              meNodeTxt = meNode.textContent;
          }
          let readyTxt = currentTargetTxt + meNodeTxt;
          for (let i = 0; i < length * 2 + 1; i++) {
              if (meNode && meNode.nextSibling) {
                  meNode = meNode.nextSibling;
                  readyTxt += meNode.textContent;
              }
          }
          return readyTxt.replace(reg, "").replace(reg1, "|")
      } else {
          let currentTargetTxt = currentTarget.parentNode.textContent; // 获取到点击的样式内的文本及携带的协议
          let meNodeTxt = "";
          let meNode = currentTarget.parentNode.nextSibling; // 下一个节点
          if (meNode) {
              meNodeTxt = meNode.textContent;
          }
          let readyTxt = currentTargetTxt + meNodeTxt;
          for (let i = 0; i < length * 2 + 1; i++) {
              if (meNode && meNode.nextSibling) {
                  meNode = meNode.nextSibling;
                  readyTxt += meNode.textContent;
              }
          }
          return readyTxt.replace(reg, "").replace(reg1, "|")
      }
  } else if (currentTarget.classList.contains("speed-control-brackets")) {
      // 确保操作时最外层是 可编辑的div
      if (!targetDom.parentNode.parentNode.classList.contains("view_text_box")) {
          for (let j = 0; j < 100; j) {
              if (targetDom.parentNode.classList.contains("speed-control") || targetDom.parentNode.classList.contains("speed-control-content") || targetDom.parentNode.classList.contains("duncuo-control-content")) {
                  j++;
                  targetDom = targetDom.parentNode;
              } else {
                  j = 100;
              }
          }
          let currentTargetTxt = targetDom.textContent; // 获取到点击的样式内的文本及携带的协议
          let meNodeTxt = "";
          let meNode = targetDom.nextSibling; // 下一个节点
          if (meNode) {
              meNodeTxt = meNode.textContent;
          }
          let readyTxt = currentTargetTxt + meNodeTxt;
          for (let i = 0; i < length * 2 + 1; i++) {
              if (meNode && meNode.nextSibling) {
                  meNode = meNode.nextSibling;
                  readyTxt += meNode.textContent;
              }
          }
          return readyTxt.replace(reg, "").replace(reg1, "|")
      } else {
          let meNodeTxt = "";
          let meNode = currentTarget.parentNode.nextSibling; // 下一个节点
          if (meNode) {
              meNodeTxt = meNode.textContent;
          }
          let readyTxt = meNodeTxt;
          for (let i = 0; i < length * 2 + 1; i++) {
              if (meNode && meNode.nextSibling) {
                  meNode = meNode.nextSibling;
                  readyTxt += meNode.textContent;
              }
          }
          return readyTxt.replace(reg, "").replace(reg1, "|");
      }
  } else {
      // 处理回车键的文本
      let patt = /,|，|;|……|！|。|？/
      // 没有效果的文本
      let myText = selection.anchorNode.textContent;
      let myIndex = range.startOffset;
      let lastText = myText.substring(myIndex, myText.length);  // 从光标位置开始分割当前文本
      let punctuationStr = lastText.substring(lastText.length - 2); // 得到点击当前段最后一个字符
      if (punctuationStr.search(patt) == -1) {
          // 若改段文本没有符号,添加一个逗号
          lastText = lastText + "，"
      }
      let meNode = selection.anchorNode.parentNode.nextSibling; // 下一个节点
      let meNodeTxt = "";
      if (meNode) {
          meNodeTxt = meNode.textContent;
      }
      // let readyTxt = lastText + meNodeTxt;
      // for (let i = 0; i < length * 2 + 1; i++) {
      //     if (meNode && meNode.nextSibling) {
      //         meNode = meNode.nextSibling;
      //         readyTxt += meNode.textContent;
      //     }
      // }
      // return readyTxt.replace(reg, "").replace(reg1, "|");
  }
}
// 处理光标的位置前面获取文本
export function getTextBefore(selection, range, length, currentTarget) {
  let reg = /\n/g;
  let targetDom = currentTarget;
  // 如果点击的是 样式外部的文字
  if (currentTarget.classList.contains("view_text_box")) {
      let myText = selection.anchorNode.textContent;
      let myIndex = range.startOffset;
      let lastText = myText.substring(0, myIndex) ? myText.substring(0, myIndex).replace(reg,`<div></div>`) : "";  // 获取光标前面的文本
      let meNode = selection.anchorNode.previousSibling ? selection.anchorNode.previousSibling : ""; // 上一个节点
      if(selection.anchorNode.classList && selection.anchorNode.classList.contains("view_text_box")) return
      //当顿挫协议在文本开头时,拿不到文本,不能
      if(selection.anchorNode.parentNode && selection.anchorNode.parentNode.classList && selection.anchorNode.parentNode.classList.contains("speed-control-close")) {
          return "DCSignInTextStart"
      } 
      let meNodeTxt = "";
      if (meNode) {
          // 是否是出文本的内容
          if (meNode.classList) {
              meNodeTxt = meNode.innerHTML;
          } else {
              meNodeTxt = meNode.textContent;
          }
      }
      let readyTxt = meNodeTxt + lastText;
      for (let i = 0; i < length * 2 + 1; i++) {
          // if(meNode.classList.contains("view_text_box")) {
          //     break;
          // }else {
              if (meNode && meNode.previousSibling) {
                  meNode = meNode.previousSibling;
                  if (meNode.classList) {
                      readyTxt = meNode.innerHTML + readyTxt;
                  } else {
                      readyTxt = meNode.textContent + readyTxt;
                  }
              }
          // }
      }
      return readyTxt.replace(reg,`<div></div>`)
  } else if (currentTarget.classList.contains("speed-control-content") || currentTarget.classList.contains("duncuo-control-content")) { // 点击的是变速内部或者顿挫调节
      // let currentTargetTxt = currentTarget.parentNode.innerHTML; // 获取到点击的样式内的文本及携带的协议
      if (!targetDom.parentNode.parentNode.classList.contains("view_text_box")) {
          for (let j = 0; j < 100; j) {
              if (targetDom.parentNode.classList && (targetDom.parentNode.classList.contains("speed-control-content") || targetDom.parentNode.classList.contains("duncuo-control-content") || targetDom.parentNode.classList.contains("speed-control") || !targetDom.parentNode.classList.contains("view_text_box"))) {
                  j++;
                  targetDom = targetDom.parentNode;
              } else {
                  j = 100;
              }
          }
          // let currentTargetTxt = targetDom.textContent; // 获取到点击的样式内的文本及携带的协议
          let meNodeTxt = "";
          let meNode = targetDom.previousSibling; // 下一个节点
          if (meNode) {
              if (meNode.classList) {
                  meNodeTxt = meNode.innerHTML;
                 
              } else {
                  meNodeTxt = meNode.textContent;
              }
          }
          let readyTxt = meNodeTxt;
          for (let i = 0; i < length * 2 + 1; i++) {
              if (meNode && meNode.previousSibling) {
                  meNode = meNode.previousSibling;
                  if (meNode.classList) {
                      readyTxt = meNode.innerHTML + readyTxt;
                  } else {
                      readyTxt = meNode.textContent + readyTxt;
                  }
              }
          }
          
          return readyTxt.replace(reg,`<div></div>`)
      } else {
          let meNodeTxt = "";
          let meNode = currentTarget.parentNode.previousSibling; // 上一个节点
          if (meNode) {
              if (meNode.classList) {
                  meNodeTxt = meNode.innerHTML;
              } else {
                  meNodeTxt = meNode.textContent;
              }
          }
          let readyTxt = meNodeTxt;
          for (let i = 0; i < length * 2 + 1; i++) {
              if (meNode && meNode.previousSibling) {
                  meNode = meNode.previousSibling;
                  if (meNode.classList) {
                      readyTxt = meNode.innerHTML + readyTxt;
                  } else {
                      readyTxt = meNode.textContent + readyTxt;
                  }
              }
          }
          // .replace(reg,`<div></div>`)   
          return readyTxt.replace(reg,`<div></div>`)
      }
  }
}

// 选中的文本 的前面的文本
export function getTextBefore1(selection, range, length, currentTarget) {
  let reg = /×|「|」/g;
  // let reg2 = /\n/g;
  // let reg1 = /倍\|/g;
  let targetDom = currentTarget;
  // 如果点击的是 样式外部的文字
  if (currentTarget.classList.contains("view_text_box")) {
      let myText = selection.anchorNode.textContent;
      let myIndex = range.startOffset;
      let lastText = myText.substring(0, myIndex) ? myText.substring(0, myIndex).replace(reg,`<div></div>`) : "";  // 获取光标前面的文本
      let meNode = selection.anchorNode.previousSibling ? selection.anchorNode.previousSibling : ""; // 上一个节点
      if(selection.anchorNode.classList && selection.anchorNode.classList.contains("view_text_box")) return
      //当顿挫协议在文本开头时,拿不到文本,不能
      if(selection.anchorNode.parentNode && selection.anchorNode.parentNode.classList && selection.anchorNode.parentNode.classList.contains("speed-control-close")) {
          return "DCSignInTextStart"
      } 
      let meNodeTxt = "";
      if (meNode) {
          // 是否是出文本的内容
          if (meNode.classList) {
              meNodeTxt = meNode.textContent;
          } else {
              meNodeTxt = meNode.textContent;
          }
      }
      let readyTxt = meNodeTxt + lastText;
      for (let i = 0; i < length * 2 + 1; i++) {
              if (meNode && meNode.previousSibling) {
                  meNode = meNode.previousSibling;
                  if (meNode.classList) {
                      readyTxt = meNode.textContent + readyTxt;
                  } else {
                      readyTxt = meNode.textContent + readyTxt;
                  }
              }
          // }
      }
      return readyTxt.replace(reg,"")
  } else if (currentTarget.classList.contains("speed-control-content") || currentTarget.classList.contains("duncuo-control-content")) { // 点击的是变速内部或者顿挫调节
      // let currentTargetTxt = currentTarget.parentNode.textContent; // 获取到点击的样式内的文本及携带的协议
      if (!targetDom.parentNode.parentNode.classList.contains("view_text_box")) {
          for (let j = 0; j < 100; j) {
              if (targetDom.parentNode.classList && (targetDom.parentNode.classList.contains("speed-control-content") || targetDom.parentNode.classList.contains("duncuo-control-content") || targetDom.parentNode.classList.contains("speed-control") || !targetDom.parentNode.classList.contains("view_text_box"))) {
                  j++;
                  targetDom = targetDom.parentNode;
              } else {
                  j = 100;
              }
          }
          // let currentTargetTxt = targetDom.textContent; // 获取到点击的样式内的文本及携带的协议
          let meNodeTxt = "";
          let meNode = targetDom.previousSibling; // 下一个节点
          if (meNode) {
              if (meNode.classList) {
                  meNodeTxt = meNode.textContent;
                 
              } else {
                  meNodeTxt = meNode.textContent;
              }
          }
          let readyTxt = meNodeTxt;
          for (let i = 0; i < length * 2 + 1; i++) {
              if (meNode && meNode.previousSibling) {
                  meNode = meNode.previousSibling;
                  if (meNode.classList) {
                      readyTxt = meNode.textContent + readyTxt;
                  } else {
                      readyTxt = meNode.textContent + readyTxt;
                  }
              }
          }
          
          return readyTxt.replace(reg,"")
      } else {
          let meNodeTxt = "";
          let meNode = currentTarget.parentNode.previousSibling; // 上一个节点
          if (meNode) {
              if (meNode.classList) {
                  meNodeTxt = meNode.textContent;
              } else {
                  meNodeTxt = meNode.textContent;
              }
          }
          let readyTxt = meNodeTxt;
          for (let i = 0; i < length * 2 + 1; i++) {
              if (meNode && meNode.previousSibling) {
                  meNode = meNode.previousSibling;
                  if (meNode.classList) {
                      readyTxt = meNode.textContent + readyTxt;
                  } else {
                      readyTxt = meNode.textContent + readyTxt;
                  }
              }
          }
          return readyTxt.replace(reg,"")
      }
  }
}

/**
*  本页面代码禁止进行格式化
* 改变顿挫的样式以及值
* @export
* @param {*} e 当前点击的元素
*/
export function changeDCStyle(e) {
  // 点击的是顿挫调节的效果
  let myid = "js-duncuo-modal-" + Math.random().toString(16);
  const template = `<div class="speed-modal" id="${myid}">
          <div class="speed-item"><span style="display: none"></span><img src="../../static/images/mood1.png" style="width: 24px; height: 24px" /><div>默认</div></div>
          <div class="speed-item"><span style="display: none">#tone|ww|</span><img src="../../static/images/mood2.png" style="width: 24px; height: 24px" /><div>弱-强</div></div>
          <div class="speed-item"><span style="display: none">#tone|sw|</span><img src="../../static/images/mood3.png" style="width: 24px; height: 24px" /><div>强-弱</div></div>
          <div class="speed-item"><span style="display: none">#tone|sws|</span><img src="../../static/images/mood4.png" style="width: 24px; height: 24px" /><div>弱-弱</div></div>
          <div class="speed-item"><span style="display: none">#tone|sw|</span><img src="../../static/images/mood5.png" style="width: 24px; height: 24px" /><div>强弱强</div></div>
          <div class="speed-item"><span style="display: none">#tone|wsw|</span><img src="../../static/images/mood6.png" style="width: 24px; height: 24px" /><div>弱强弱</div></div>
          <div class="speed-modal-close" style="border: 1px solid #409EFF;font-size: 12px;color: #409EFF;background: #e7f3ff;text-align: center; border-radius: 3px;padding: 0 4px;width:60px; height: 28px;line-height: 28px;">关闭</div>
      </div>`;
  let nodeInstance = document.createRange().createContextualFragment(template);
  document.body.appendChild(nodeInstance);
  let myItemNode = document.querySelectorAll(".speed-item");
  myItemNode.forEach((v) => {
      if (v.children[1].src == e.target.src) {
          v.style = `color: #409EFF;font-size: 12px;margin-right: 20px;text-align: center`;
      } else {
          v.style = `margin-right: 20px;text-align: center; font-size: 12px`;
      }
  });
  let newNode = document.getElementById(myid);
  let b = e.target.getBoundingClientRect();
  let x = b.left;
  let y = b.top + b.height;
  newNode.style = `box-shadow: 0px 0px 6px 1px rgba(0, 0, 0, 0.4);left: ${x * 0.75
      }px; top: ${y + 20
      }px; height: 58px; display: flex;position: absolute;background: #ffffff;z-index: 90;align-items: center;padding: 8px;cursor: pointer; box-sizing: border-box;`;
  newNode.addEventListener("click", function (event) {
      let currentTxt = event.target.innerText;
      if (currentTxt === "关闭") {
          newNode.parentNode.removeChild(newNode);
          return;
      }
      if (event.target.parentNode.classList.contains("speed-item")) {
          e.target.src = event.target.parentNode.children[1].src; // 替换图片
          let currentTargetDom = e.target.parentNode.parentNode.parentNode;
          // 当选中了默认时,去掉顿挫的协议   // 修改协议
          if (event.target.parentNode.children[2].innerText == "默认") {
              for (var i = 0; i < currentTargetDom.children.length; i++) {
                  if (
                      currentTargetDom.children[i].classList.contains("audio_flag")
                  ) {
                      currentTargetDom.children[i].innerText = "";
                  }
              }
          } else {
              let flagStr = event.target.parentNode.children[0].innerText;
              currentTargetDom.children[2].innerText = flagStr;
          }
          newNode.parentNode.removeChild(newNode);
      }
  });
  return;
}

/**
*  本页面代码禁止进行格式化
* 改变变速的样式以及值
* @export
* @param {*} e 当前点击的元素
*/
export function changeSpeedStyle(e) {
  let myid = "js-speed-modal-" + Math.random().toString(16);
  const template = `<div class="speed-modal" id="${myid}">
      <div class="speed-item"><span style="display: none">#%</span>0.5<span style="display: none">|</span><img src="../../static/images/0.5.png" style="display: none" /></div>
      <div class="speed-item"><span style="display: none">#%</span>0.8<span style="display: none">|</span><img src="../../static/images/0.8.png" style="display: none" /></div>
      <div class="speed-item"><span style="display: none">#%</span>0.9<span style="display: none">|</span><img src="../../static/images/0.9.png" style="display: none" /></div>
      <div class="speed-item"><span style="display: none">#%</span>1.0<span style="display: none">|</span><img src="../../static/images/1.0.png" style="display: none" /></div>
      <div class="speed-item"><span style="display: none">#%</span>1.05<span style="display: none">|</span><img src="../../static/images/1.05.png" style="display: none" /></div>
      <div class="speed-item"><span style="display: none">#%</span>1.1<span style="display: none">|</span><img src="../../static/images/1.1.png" style="display: none" /></div>
      <div class="speed-item"><span style="display: none">#%</span>1.2<span style="display: none">|</span><img src="../../static/images/1.2.png" style="display: none" /></div>
      <div class="speed-item"><span style="display: none">#%</span>1.5<span style="display: none">|</span><img src="../../static/images/1.5.png" style="display: none" /></div>
      <div class="speed-modal-close" style="border: 1px solid #DADADA; border-radius: 3px;padding: 0 4px">关闭</div>
      </div>`;
  let nodeInstance = document.createRange().createContextualFragment(template);
  document.body.appendChild(nodeInstance);
  let myItemNode = document.querySelectorAll(".speed-item");
  myItemNode.forEach((v) => {
      if (v.children[0].innerHTML+v.innerText == e.target.parentNode.children[0].innerText) {
          v.style = `height: 24px; padding: 0 4px;border-radius: 4px;margin-right: 5px;background: #07A300; color: #ffffff;`;
      } else {
          v.style = `height: 24px; border: 1px solid #DADADA; padding: 0 4px;border-radius: 4px;margin-right: 5px`;
      }
  });
  let newNode = document.getElementById(myid);
  let b = e.target.getBoundingClientRect();
  let x = b.left;
  let y = b.top + b.height;
  newNode.style = `box-shadow: 0px 0px 6px 1px rgba(0, 0, 0, 0.4);left: ${
      x * 0.75
  }px; top: ${
      y + 20
  }px; height: 37px; display: flex;position: absolute;background: #ffffff;z-index: 90;align-items: center;padding: 6px 8px;cursor: pointer;`;
  newNode.addEventListener("click", function (event) {
      let txt = event.target.innerHTML;
      if (txt === "关闭") {
          newNode.parentNode.removeChild(newNode);
          return;
      }
      if (event.target.classList.contains("speed-item")) {
          e.target.src = event.target.children[2].src;
          let currentTargetDom = e.target.parentNode;
          // 当选中了默认时,去掉顿挫的协议   // 修改协议
          let flagStr = event.target.children[0].innerText+event.target.innerText;
          currentTargetDom.children[0].innerText = flagStr;
          newNode.parentNode.removeChild(newNode);
      }
  });
}

export function changeInsertTimerStyle(e) {
  let myid = "js-timer-modal-" + Math.random().toString(16);
  // #!2000ms#
  const template = `<div class="speed-modal" id="${myid}">
  <div style="display: flex;height: 37px;align-items:center">
      <div class="speed-item"><span style="display: none">#!</span>500ms<span style="display: none">#</span></div>
      <div class="speed-item"><span style="display: none">#!</span>1000ms<span style="display: none">#</span></div>
      <div class="speed-item"><span style="display: none">#!</span>2000ms<span style="display: none">#</span></div>
      <div style="width: 1px; height: 1.7rem; background: #979797; margin-right: 0.5rem"></div>
      <div class="number_input" style="overflow: hidden;height: 2.4rem; min-width: 9.2rem; display: flex; align-items: center; border: 1px solid #D3D3D3; border-radius: 0.3">
      <div class="iconfont icon-jian" style=" border-right: 1px solid #D3D3D3;background: #f5f7fa; width: 2.4rem; height: 2.4rem; text-align: center; line-height: 2.4rem; font-size: 1.5rem;"></div>
      <div style="min-width: 4.4rem; text-align: center;height:2.4rem; line-height: 2.4rem;padding: 0 0.3rem; box-sizing: border-box;">200</div>
      <div class="iconfont icon-jiahao1" style="border-left: 1px solid #D3D3D3;background: #f5f7fa; width: 2.4rem; height: 2.4rem; text-align: center; line-height: 2.4rem; font-size: 1.5rem"></div>
      </div>
    </div>
    <div style="display: flex;align-items: center;justify-content: flex-end;">
      <div class="insertTimer_btn" style="width: 58px; height: 2.4rem; background: #409EFF; color: #ffffff; font-size: 1.2rem; border-radius: 0.3rem; text-align: center; line-height: 2.4rem; margin: 0 0.5rem">插入</div>
      <div class="delect_insertTimer" style="border: 1px solid #409EFF; border-radius: 0.3rem;width: 5.7rem; height: 2.4rem; text-align: center; line-height: 2.4rem; color: #409EFF; background: #e7f3ff; font-size: 1.2rem">恢复默认</div>
    </div>
      </div>`;
  let nodeInstance = document.createRange().createContextualFragment(template);
  document.body.appendChild(nodeInstance);
  let myItemNode = document.querySelectorAll(".speed-item");
  myItemNode.forEach((v) => {
      if (v.innerText == e.target.innerText) {
          v.style = `height: 2.4rem; padding: 0 0.4rem;border-radius: 0.4rem;margin-right: 0.5rem;background: #FF6011; color: #ffffff;line-height: 2.4rem`;
      } else {
          v.style = `height: 2.4rem; border: 1px solid #DADADA; padding: 0 0.4rem;border-radius: 0.4rem;margin-right: 0.5rem;line-height: 2.4rem`;
      }
  });
  let newNode = document.getElementById(myid);
  let b = e.target.getBoundingClientRect();
  let x = b.left;
  let y = b.top + b.height;
  console.log("x=====",x,"yyyyyyy",y);
  if(x>130 && x<150) {
    newNode.style = `left: ${x*0.2}px;`
  }else if(x>= 150) {
      console.log(11111);
    newNode.style = `right: ${x*0.4}px;`
  }
  newNode.style = `box-shadow: 0px 0px 6px 1px rgba(0, 0, 0, 0.4); top: ${
      y + 20
  }px; position: absolute;background: #ffffff;z-index: 90;align-items: center;padding: 6px 8px;cursor: pointer;`;
  newNode.addEventListener("click", function (event) {
      let txt = event.target.innerHTML;
      if (txt === "关闭") {
          newNode.parentNode.removeChild(newNode);
          return;
      }
      // 点击到的是有值的项
      if (event.target.classList.contains("speed-item")) {
          e.target.innerHTML = txt;
          newNode.parentNode.removeChild(newNode);
      }
      // 点击了 减号
      if (event.target.classList.contains("icon-jian")) {
          if (event.target.parentNode.children[1].innerText > 0) {
            event.target.parentNode.children[1].innerText = parseInt(event.target.parentNode.children[1].innerText) - 50;
          }
      }
      // 点击了 加号
      if (event.target.classList.contains("icon-jiahao1")) {
          if (event.target.parentNode.children[1].innerText >= 3000) {
            alert("自定义不能大于3000");
          } else {
            event.target.parentNode.children[1].innerText = parseInt(event.target.parentNode.children[1].innerText) + 50;
          }
      }
      // 点击了 "插入"
      if (event.target.classList.contains("insertTimer_btn")) {
          let myNumInputVal = event.target.parentNode.parentNode.children[0].children[4].children[1].innerText;
          e.target.innerHTML = `<span style="display: none">#!</span>` + myNumInputVal + `ms<span style="display: none">#</span>`;
          newNode.parentNode.removeChild(newNode);
      }
      // 点击了 "恢复默认"
      if (event.target.classList.contains("delect_insertTimer")) {
          e.target.parentNode.parentNode.removeChild(e.target.parentNode);
          newNode.parentNode.removeChild(newNode);
      }
  });
}

export function changeDuoyinStyle(e,res) {
  let myid = "js-duoyin-modal-" + Math.random().toString(16);
  const template = `<div class="speed-modal" id="${myid}"></div>`;
  let nodeInstance = document.createRange().createContextualFragment(template);
  document.body.appendChild(nodeInstance);
  let newNode = document.getElementById(myid);
  let b = e.target.getBoundingClientRect();
  let x = b.left;
  let y = b.top + b.height;
  console.log("x=====",x,"yyyyyyy",y);
  if(x>130 && x<150) {
    newNode.style = `box-shadow: 0px 0px 6px 1px rgba(0, 0, 0, 0.4);left: ${x*0.5}px;top: ${
        y + 20
    }px; height: 57px; display: flex;position: absolute;background: #ffffff;z-index: 90;align-items: center;padding: 0 3px 0 8px;cursor: pointer;`;
  }else if(x>= 150 && x <300) {
    newNode.style = `box-shadow: 0px 0px 6px 1px rgba(0, 0, 0, 0.4);left: ${x*0.4}px;top: ${
      y + 20
  }px; height: 57px; display: flex;position: absolute;background: #ffffff;z-index: 90;align-items: center;padding: 0 3px 0 8px;cursor: pointer;`;
}else if(x>0 && x <= 130) {
    newNode.style = `box-shadow: 0px 0px 6px 1px rgba(0, 0, 0, 0.4);left: ${x*0.86}px;top: ${
      y + 20
  }px; height: 57px; display: flex;position: absolute;background: #ffffff;z-index: 90;align-items: center;padding: 0 3px 0 8px;cursor: pointer;`;
}
  else if(x>= 300) {
      console.log(11111);
      newNode.style = `box-shadow: 0px 0px 6px 1px rgba(0, 0, 0, 0.4);right: ${x*0.1}px;top: ${
        y + 20
    }px; height: 57px; display: flex;position: absolute;background: #ffffff;z-index: 90;align-items: center;padding: 0 3px 0 8px;cursor: pointer;`;
  }
  
  newNode.addEventListener("click", function (event) {
      let txt = event.target.innerHTML;
      if (event.target.innerText === "取消") {
          newNode.parentNode.removeChild(newNode);
             return;
      }
      if (event.target.classList.contains("duoyin_item")) {
          e.target.innerHTML = txt;
          newNode.parentNode.removeChild(newNode);
      }
  });
  let myArr = res.data;
  myArr.push("取消");
  myArr.forEach((u) => {
      let myTemplate = `<div class="duoyin_item"><div style="display: none">#?</div>${u}<div style="display: none">#</div></div>`;
      let myNodeInstance = document.createRange().createContextualFragment(myTemplate);
      newNode.appendChild(myNodeInstance);
  });
  let myItemNode = document.querySelectorAll(".duoyin_item");
  myItemNode.forEach((v) => {
      if (v.innerText == e.target.innerText) {
          v.style = `line-height: 32px; height: 32px; padding: 0 10px;border-radius: 4px;margin-right: 0.5rem;background: #1577FF; color: #ffffff;`;
      } else {
          v.style = `line-height: 32px; height: 32px; border: 1px solid #DADADA; padding: 0 10px;border-radius: 4px;margin-right: 5px`;
      }
  });
}

/**
*  本页面的代码禁止进行格式化!!!!
*
* @export  读音连读
* @param {*} 
* @param {*} range 点击editor时 保存的光标对象
* @param {*} selection 指定的选区
*/
export function myReadByWord(range) {
  const template =`<span class="read_word-control"><span class="read_word_box"  style="white-space: nowrap;display: inline-block;padding-right: 40px;position: relative;"><span class="read_word-control-brackets" contenteditable="false" style="color:#ff6011;">「</span><span style="display: none">#cr</span><span contenteditable="true" class="read_word-control-content" style="outline: none"></span><span style="display:none" class="audio_flag">!cr#</span><span class="read_word-control-brackets" contenteditable="false" style="color:#ff6011;">」</span><img src="./static/images/lianduicon.png" style="width: 38px;height: 17px;cursor: pointer;position: absolute;top:50%;margin-top: -8px;right: 2px" class="read_word_close_control" /></span></span>`;
  let newNode = document.createRange().createContextualFragment(template);
  let content = newNode.querySelector(".read_word-control-content");
  content.appendChild(range.extractContents());
  range.insertNode(newNode);
  // selection.removeAllRanges();
}

/**
*  本页面的代码禁止进行格式化!!!!
*
* @export
* @param {*} val 停顿的值
* @param {*} range 点击editor时 保存的光标对象
* @param {*} selection 指定的选区
*/
export function myInsertTimer(val,range,selection) {
  const template =`<span  class="inserTime-control"><span contenteditable="false" class="inserTime-control-label" style="margin: 0 2px 0 1px;padding: 2px 4px;background:#FF6011;color: #fff;border-radius: .3rem"><span class="insertTimer-control-text"><span style="display: none">#!</span>`+val+`ms<span style="display:none">#</span></span><span class="inserTime-control-close">&#215;</span></span>`;
  let newNode = document.createRange().createContextualFragment(template);
  range.insertNode(newNode);
  selection.removeAllRanges();
}

export function newChangeInsertTimerval(e,val,range,selection) {
    let sIndex = e.target.innerHTML.search(/\d/);
    let eIndex = e.target.innerHTML.search(/ms/)+2;
    let sTxt = e.target.innerHTML.substring(0,sIndex);
    let eTxt = e.target.innerHTML.substring(eIndex);
    e.target.innerHTML = sTxt + val +"ms" + eTxt;
}

/**
*  本页面的代码禁止进行格式化!!!!
*
* @export
* @param {*} val 多音的值
* @param {*} range 点击editor时 保存的光标对象
* @param {*} selection 指定的选区
* @param {*} indexAudiText 要识别的多音字
*/
export function myInsertDuoyin(val,range,selection,indexAudiText) {
  const template =`<span  class="duoyin-control"><span contenteditable="false" class="duoyin-control-content" style="color: #1577FF;font-weight: 700;">`+ indexAudiText +`</span><span contenteditable="false" class="duoyin-control-label" style="margin: 0 2px 0 1px;padding: 2px 4px;border-radius:3px;background:#1577FF;color: #fff;cursor: pointer;"><span class="duoyin-control-text"><span style="display:none">#?</span>`+val+`<span style="display:none">#</span></span><span class="duoyin-control-close">&#215;</span></span></span>`;
  let newNode = document.createRange().createContextualFragment(template);
  // let content = newNode.querySelector(".speed-control-content");
  range.extractContents();
  range.insertNode(newNode);
  selection.removeAllRanges();
}

/**
*  本页面的代码禁止进行格式化!!!!
*
* @export
* @param {*} val 顿挫的值
* @param {*} range 点击editor时 保存的光标对象
* @param {*} selection 指定的选区
* @param {*} tempImg 保存当前的图片
*/
export function myInsertDC(val,range,selection) {
  var tempImg = "";
  if (val == "ww") {
    tempImg = `<img class="duncuo_flag" src='../../static/images/mood2.png' style="width:20px;height:20px;position: absolute;left:4px;top:50%;margin-top: -10px;" alt='' />`;
  } else if (val == "ws") {
    tempImg = `<img class="duncuo_flag" src="../../static/images/mood3.png" style="width:20px;height:20px;position: absolute;left:4px;top:50%;margin-top: -10px;" alt="" />`;
  } else if (val == "sws") {
    tempImg = `<img class="duncuo_flag" src="../../static/images/mood4.png" style="width:20px;height:20px;position: absolute;left:4px;top:50%;margin-top: -10px;" alt="" />`;
  } else if (val == "sw") {
    tempImg = `<img class="duncuo_flag" src="../../static/images/mood5.png" style="width:20px;height:20px;position: absolute;left:4px;top:50%;margin-top: -10px;" alt="" />`;
  } else if (val == "wsw") {
    tempImg = `<img class="duncuo_flag" src="../../static/images/mood6.png" style="width:20px;height:20px;position: absolute;left:4px;top:50%;margin-top: -10px;" alt="" />`;
  } else {
    tempImg = "";
  }
  const template =`<span  class="duncuo-control"><span contenteditable="false" class="duncuo-control-label" style="padding: 2px 4px 2px 34px;border-radius:3px;background:#549DEF;color: #fff;cursor: pointer;position: relative;"><span class="duncuo-control-text">`+tempImg+`</span><span class="duncuo-control-close">&#215;</span></span><span class="duncuo-control-brackets" contenteditable="false" style="color:#1776FF;">「</span><span style="display: none"  class="audio_flag">#tone|`+val+`|</span><span contenteditable="true" style="outline: none" class="duncuo-control-content"></span><span style="display: none"  class="audio_flag end_flag">!tone#</span><span class="duncuo-control-brackets" contenteditable="false" style="color:#1776FF;">」</span></span>`;
  // let editor = document.getElementById("editorDiv");
  let newNode = document.createRange().createContextualFragment(template);
  let content = newNode.querySelector(".duncuo-control-content");
  content.appendChild(range.extractContents());
  range.insertNode(newNode);
  selection.removeAllRanges();
}

/**
* 插入儿化音
*
* @export
* @param {*} 
* @param {*} range
* @param {*} selection
*/
export function myInsertErHuaYin(range,selection) {
  const template =`<span  class="e_rhuayin-control"><span contenteditable="false" class="e_rhuayin-control-label" style="margin: 0 2px 0 1px;padding: 2px 4px;background:#07a300;color: #fff;border-radius: .3rem"><span class="e_rhuayin-control-text"><span style="display: none">[=</span>er<span style="display:none">]</span></span><span class="e_rhuayin-control-close">&#215;</span></span>`;
  let newNode = document.createRange().createContextualFragment(template);
  range.insertNode(newNode);

  selection.removeAllRanges();
}

/**
 *  本页面的代码禁止进行格式化!!!!
 *
 * @export
 * @param {*} val 变速的值
 * @param {*} range 点击editor时 保存的光标对象
 * @param {*} selection 指定的选区
 */
 export function myChangeSpeed(val,range,selection,txt) {
     let textnode = document.createTextNode(txt);
    let tempImg = ``;
    switch(val) {
        case "0.5":
            tempImg = `<img class="speed-control-text" src=${require('../assets/img/0.5.png')} style="position: absolute;top: -16px;height:20px;" alt='' />`
        break;
        case "0.8":
            tempImg = `<img class="speed-control-text" src=${require('../assets/img/0.8.png')} style="position: absolute;top: -16px;height:20px;" alt='' />`
        break;
        case "0.9":
            tempImg = `<img class="speed-control-text" src=${require('../assets/img/0.9.png')} style="position: absolute;top: -16px;height:20px;" alt='' />`
        break;
        case "0.95":
            tempImg = `<img class="speed-control-text" src=${require('../assets/img/0.95.png')} style="position: absolute;top: -16px;height:20px;" alt='' />`
        break;
        case '1.0':
            tempImg = `<img class="speed-control-text" src=${require('../assets/img/1.0.png')} style="position: absolute;top: -16px;height:20px;" alt='' />`
        break;
        case "1.05":
            tempImg = `<img class="speed-control-text" src=${require('../assets/img/1.05.png')} style="position: absolute;top: -16px;height:20px;" alt='' />`
        break;
        case "1.1":
            tempImg = `<img class="speed-control-text" src=${require('../assets/img/1.1.png')} style="position: absolute;top: -16px;height:20px;" alt='' />`
        break;
        case "1.15":
            tempImg = `<img class="speed-control-text" src=${require('../assets/img/1.15.png')} style="position: absolute;top: -16px;height:20px;" alt='' />`
        break;
        case "1.2":
            tempImg = `<img class="speed-control-text" src=${require('../assets/img/1.2.png')} style="position: absolute;top: -16px;height:20px;" alt='' />`
        break;
        case "1.25":
            tempImg = `<img class="speed-control-text" src=${require('../assets/img/1.25.png')} style="position: absolute;top: -16px;height:20px;" alt='' />`
        break;
        case "1.3":
            tempImg = `<img class="speed-control-text" src=${require('../assets/img/1.3.png')} style="position: absolute;top: -16px;height:20px;" alt='' />`
        break;
        case "1.5":
            tempImg = `<img class="speed-control-text" src=${require('../assets/img/1.5.png')} style="position: absolute;top: -16px;height:20px;" alt='' />`
        break;
    }
    const template =`<span  class="speed-control"><span contenteditable="false" class="speed-control-label" style="position: relative;padding: 2px 4px;border-radius:3px;background:#07A300;color: #fff;cursor: pointer;white-space: nowrap;"><span class="speed-control-text" style="position: relative;display: inline-block;width:60px"><span style="display:none" class="audio_flag">#%${val}</span>`+tempImg +`<span style="display:none">|</span></span><span class="speed-control-close" style="position: absolute;position: absolute;right: 6px;font-size: 22px;top: -6px;">&#215;</span></span><span class="speed-control-brackets" contenteditable="false" style="color:#07A300;">「</span><span contenteditable="true" class="speed-control-content" style="outline: none;user-select: text;-webkit-user-select: text;"></span><span style="display:none" class="audio_flag">%#</span><span class="speed-control-brackets" contenteditable="false" style="color:#07A300;">」</span></span>`;
    let newNode = document.createRange().createContextualFragment(template);
    let content = newNode.querySelector(".speed-control-content");
    content.appendChild(range.extractContents());
    range.insertNode(newNode);
    // range.extractContents()
}

export function replaceSpeedVal(text) {
    let patt = /#%/g;
    let tempText = "";
    for(let i=0; i< text.length; i) {
        let startIndex = text.search(patt);
        // 找到变速的开始标志
        if(startIndex != -1) {
            let endIndex = text.search("%#"); // 找到第一个结束的协议所在的位置
            let speedEndIndex = text.indexOf("|");
            let speedVal = text.substring(startIndex+2,speedEndIndex);
            let tempSpeedSententce = text.substring(startIndex,endIndex+2);
            tempText += text.substring(0,startIndex);
            switch(speedVal) {
                case '0.5':
                    tempText += tempSpeedSententce.replace(speedVal,"3");
                break;
                case '0.8':
                    tempText += tempSpeedSententce.replace(speedVal,"4");
                break;
                case '1.0':
                    tempText += tempSpeedSententce.replace(speedVal,"5");
                break;
                case '0.9':
                    tempText += tempSpeedSententce.replace(speedVal,"13");
                break;
                case '0.95':
                    tempText += tempSpeedSententce.replace(speedVal,"14");
                break;
                case '1.05':
                    tempText += tempSpeedSententce.replace(speedVal,"10");
                break;
                case '1.1':
                    tempText += tempSpeedSententce.replace(speedVal,"6");
                break;
                case '1.15':
                    tempText += tempSpeedSententce.replace(speedVal,"15");
                break;
                case '1.2':
                    tempText += tempSpeedSententce.replace(speedVal,"7");
                break;
                case '1.25':
                    tempText += tempSpeedSententce.replace(speedVal,"16");
                break;
                case '1.3':
                    tempText += tempSpeedSententce.replace(speedVal,"17");
                break;
                case '1.5':
                    tempText += tempSpeedSententce.replace(speedVal,"9");
                break;
            }
            
            // 重新分割文本
            if(endIndex+2 == text.length) {
                return tempText
            }else {
                text = text.substring(endIndex+2);
            }
        }else {
            tempText += text;
            i = text.length;
            return tempText;
        }
    }
}

export function isIEBrowser(navigator) {
  let userAgent = navigator.userAgent;
  let isIEBrowserFlag = false;
  let reg = /firefox/gi
  if(userAgent.search(reg) == -1) { //不是火狐
      isIEBrowserFlag = (userAgent.indexOf("Edge")> -1 || userAgent.indexOf("rv")>-1) ? true : false;
  }else { //是火狐
      isIEBrowserFlag = false;
  }
  return isIEBrowserFlag
}

export function TextToHtml(text){
  var html =  pauseTextToHtml(text);
  html = duoyinTextToHtml(html);
  html = erhuayinTextToHtml(html);
  html = toneToHtml(html);
  html = changeSpeedToHtml(html);
  html = readWordToHtml(html);
  html = enterToDIv(html);
  html = changeReadNumberToHtml(html);
  return html;
}

/**
*停顿转html
*
* @export
* @param {*} text
* @return {*} 
*/
export function pauseTextToHtml(text){
  var str = text;
  var matchText = str.match(/(#!\d+ms#)|(\d+ms)/);
  
  while(matchText != null){
      var time1,time2;
      if(matchText[0].search("#!") == -1) {
        //数字+ms
        time1 = matchText[0].slice(0,matchText[0].length-2);
        time2 = matchText[0].slice(matchText[0].length-2,matchText[0].length);
      }else {
        time1 = matchText[0].slice(2,matchText[0].length-3);
        time2 = matchText[0].slice(matchText[0].length-3,matchText[0].length-1);
      }
      const template =`<span  class="inserTime-control"><span contenteditable="false" class="inserTime-control-label" style="margin: 0 2px 0 1px;padding: 2px 4px;border-radius:3px;background:#FF6011;color: #fff;cursor: pointer;"><span class="insertTimer-control-text"><span style="display: none">#!</span>`+time1+`<span>${time2}</span><span style="display:none">#</span></span><span class="inserTime-control-close">&#215;</span></span></span>`;
      str = str.slice(0,matchText.index) + template + str.slice(matchText.index+ matchText[0].length);
      matchText = str.match(/(#!\d+ms#)|(\d+ms)/);
  }
  return str;
}

/**
*儿化音转html
*
* @export
* @param {*} text
* @return {*} 
*/
export function erhuayinTextToHtml(text){
  var str = text;
  var matchText = str.match(/(\[=er\])/);
  while(matchText != null){
      const template =`<span  class="e_rhuayin-control"><span contenteditable="false" class="e_rhuayin-control-label" style="margin: 0 2px 0 1px;padding: 2px 4px;background:#07a300;color: #fff;border-radius: .3rem"><span class="e_rhuayin-control-text"><span style="display: none">[=</span><span>e</span><span>r</span><span style="display:none">]</span></span><span class="e_rhuayin-control-close">&#215;</span></span></span>`;
      str = str.slice(0,matchText.index) + template + str.slice(matchText.index+ matchText[0].length);
      
      matchText = str.match(/(\[=er\])/);
  }
  return str;
}

/**
*变速转html
*
* @export
* @param {*} text
* @return {*} 
*/
export function changeSpeedToHtml(text){
  //  #%0.8|趣味企鹅搭搭撒撒%#
  var str = text;
  var matchText = str.match(/#%[0-9].{2,3}\|/);
  while(matchText != null){
      let tempImg = ``;
      switch(matchText[0]) {
          case "#%0.5|":
              tempImg = `<img class="speed-control-text" src=${require('../assets/img/0.5.png')} style="position: absolute;top: -16px;height:20px;" alt='' />`
          break;
          case "#%0.8|":
              tempImg = `<img class="speed-control-text" src=${require('../assets/img/0.8.png')} style="position: absolute;top: -16px;height:20px;" alt='' />`
          break;
          case "#%0.9|":
              tempImg = `<img class="speed-control-text" src=${require('../assets/img/0.9.png')} style="position: absolute;top: -16px;height:20px;" alt='' />`
          break;
          case "#%0.95|":
              tempImg = `<img class="speed-control-text" src=${require('../assets/img/0.95.png')} style="position: absolute;top: -16px;height:20px;" alt='' />`
          break;
          case '#%1.0|':
              tempImg = `<img class="speed-control-text" src=${require('../assets/img/1.0.png')} style="position: absolute;top: -16px;height:20px;" alt='' />`
          break;
          case "#%1.05|":
              tempImg = `<img class="speed-control-text" src=${require('../assets/img/1.05.png')} style="position: absolute;top: -16px;height:20px;" alt='' />`
          break;
          case "#%1.1|":
              tempImg = `<img class="speed-control-text" src=${require('../assets/img/1.1.png')} style="position: absolute;top: -16px;height:20px;" alt='' />`
          break;
          case "#%1.15|":
              tempImg = `<img class="speed-control-text" src=${require('../assets/img/1.15.png')} style="position: absolute;top: -16px;height:20px;" alt='' />`
          break;
          case "#%1.2|":
              tempImg = `<img class="speed-control-text" src=${require('../assets/img/1.2.png')} style="position: absolute;top: -16px;height:20px;" alt='' />`
          break;
          case "#%1.25|":
              tempImg = `<img class="speed-control-text" src=${require('../assets/img/1.25.png')} style="position: absolute;top: -16px;height:20px;" alt='' />`
          break;
          case "#%1.3|":
              tempImg = `<img class="speed-control-text" src=${require('../assets/img/1.3.png')} style="position: absolute;top: -16px;height:20px;" alt='' />`
          break;
          case "#%1.5|":
              tempImg = `<img class="speed-control-text" src=${require('../assets/img/1.5.png')} style="position: absolute;top: -16px;height:20px;" alt='' />`
          break;
      }
      let speedStr = matchText[0].substring(2,matchText[0].length-1);
      let endSign = str.indexOf("%#",matchText.index); // 结束协议
      str = str.replace("%#","");
      let length = matchText[0].length;
      let content = str.substring(matchText.index+length,endSign);
      const template =`<span  class="speed-control"><span contenteditable="false" class="speed-control-label" style="position: relative;padding: 2px 4px;border-radius:3px;background:#07A300;color: #fff;cursor: pointer;white-space: nowrap;"><span class="speed-control-text" style="position: relative;display: inline-block;width:60px;white-space: nowrap;"><span style="display:none" class="audio_flag">#%${speedStr}</span>`+tempImg+`<span style="display:none">|</span></span><span class="speed-control-close" style="position: absolute;position: absolute;right: 8.1px;">&#215;</span></span><span class="speed-control-brackets" contenteditable="false" style="color:#07A300;">「</span><span contenteditable="true" class="speed-control-content" style="outline: none;-webkit-user-modify: read-write-plaintext-only;-webkit-user-select: text;user-select: text;">`+content+`</span><span style="display:none" class="audio_flag">%<span>#</span></span><span class="speed-control-brackets" contenteditable="false" style="color:#07A300;">」</span></span>`;
      str = str.substring(0,matchText.index) + template+str.substring(content.length+ matchText.index+matchText[0].length);
      matchText = str.match(/#%[0-9].{2,3}\|/);
      
  }
  return str;
}

/**
*连读转html
*
* @export
* @param {*} text
* @return {*} 
*/
export function readWordToHtml(text){
  //  #cr的联合国军，你能想象有哪!cr#
  var str = text;
  var matchText = str.match(/#cr/);
  while(matchText != null){
      let endSign = str.indexOf("!cr#",matchText.index); // 结束协议
      str = str.replace("!cr#","");
      let length = matchText[0].length;
      let content = str.substring(matchText.index+length,endSign);
      const template =`<span class="read_word-control"><span class="read_word_box" style="white-space: nowrap;position: relative;padding-right: 40px"><span class="read_word-control-brackets" contenteditable="false" style="color:#ff6011;">「</span><span style="display: none">#c<span style="display: none">r</span></span><span contenteditable="true" class="read_word-control-content" style="outline: none">`+content+`</span><span style="display:none" class="audio_flag">!c<span style="display: none"></span>r#</span><span class="read_word-control-brackets" contenteditable="false" style="color:#ff6011;">」</span><img src="./static/images/lianduicon.png" style="width: 38px;height: 17px;cursor: pointer;position: absolute;top:50%;margin-top: -8px;right: 2px" class="read_word_close_control" /></span></span>`;
      str = str.substring(0,matchText.index) + template+str.substring(content.length+ matchText.index+matchText[0].length);
      matchText = str.match(/#cr/);
  }
  return str;
}

export function enterToDIv(text){
  var str = text;
  var matchText = str.match(/，\|br，/);
  while(matchText != null){
      // var time = matchText[0].slice(2,matchText[0].length-1);
      const template =`<div  class="my_br"><div><span  style="display: none">，|<span style="display: none">br，</span></span></div></div>`;
      str = str.slice(0,matchText.index) + template + str.slice(matchText.index+ matchText[0].length);
      matchText = str.match(/，\|br，/);
  }
  return str;
}

/**
*
*
* @export 多音转html
* @param {*} text 要处理的文本
* @return {*} 
*/
export function duoyinTextToHtml(text){
  var str = text;
  var matchText = str.match(/.{1}#\?.{1,20}?#/);
  while(matchText != null){
      var word = matchText[0].slice(0,1);
      var pinyin = matchText[0].slice(3,matchText[0].length-1);
      const template =`<span  class="duoyin-control"><span contenteditable="false" class="duoyin-control-content" style="color: #1577FF;font-weight: bold;">`+ word +`</span><span contenteditable="false" class="duoyin-control-label" style="margin: 0 2px 0 1px;padding: 2px 4px;border-radius:3px;background:#1577FF;color: #fff;cursor: pointer;"><span class="duoyin-control-text"><span style="display:none">#?</span>`+pinyin+`<span style="display:none">#</span></span><span class="duoyin-control-close">&#215;</span></span></span>`;
      str = str.slice(0,matchText.index) + template + str.slice(matchText.index+ matchText[0].length);
      matchText = str.match(/.{1}#\?.{1,20}?#/);
  }
  return str;
}

/**
*
*
* @export
* @param {*} text 要处理的文本
* @return {*} 
*/
export function shortSentenceduoyin(text){
  var str = text;
  if(str.search("×") != -1) {
      str = str.replace("×","");
      var matchText = str.match(/([a-z]{1,6}[01234])/);
      let pinyin = "#?"+matchText[0]+"#";
      str = str.replace(matchText[0],pinyin)
      return str;
  }else {
      return str
  } 
}

/**
* 将顿挫协议转化成html代码
*
* @export
* @param {*} text 要转化的文本
* @return {*} 
*/
export function toneToHtml(text){
  var str = text;
  var matchText = str.match(/#tone\|+[a-z]+\|/);
  while(matchText != null){
      // 根据不同的协议定义图片
      let tempImg = null;

      switch(matchText[0]) {
          case "#tone|ww|": //弱弱
              tempImg = `<img class="duncuo_flag" src='../../static/images/mood2.png' style="width:20px;height:20px;position: absolute;left:4px;top:50%;margin-top: -10px;" alt='' />`;
          break;
          case "#tone|ws|": //弱强
              tempImg = `<img class="duncuo_flag" src='../../static/images/mood3.png' style="width:20px;height:20px;position: absolute;left:4px;top:50%;margin-top: -10px;" alt='' />`;
          break;
          case "#tone|sws|": //强弱强
              tempImg = `<img class="duncuo_flag" src='../../static/images/mood4.png' style="width:20px;height:20px;position: absolute;left:4px;top:50%;margin-top: -10px;" alt='' />`;
          break;
          case "#tone|sw|": //强弱
              tempImg = `<img class="duncuo_flag" src='../../static/images/mood5.png' style="width:20px;height:20px;position: absolute;left:4px;top:50%;margin-top: -10px;" alt='' />`;
          break;
          case "#tone|wsw|": //弱强弱
              tempImg = `<img class="duncuo_flag" src='../../static/images/mood6.png' style="width:20px;height:20px;position: absolute;left:4px;top:50%;margin-top: -10px;" alt='' />`;
          break;
      }
      let endSign = str.indexOf("!tone#",matchText.index); // 结束协议 !tone#
      str = str.replace("!tone#","");
      let length = matchText[0].length;
      let content = str.substring(matchText.index+length,endSign);
      // 
      const template =`<span  class="duncuo-control"><span contenteditable="false" class="duncuo-control-label" style="padding: 2px 4px 2px 34px;border-radius:3px;background:#549DEF;color: #fff;cursor: pointer;position: relative;"><span class="duncuo-control-text">`+tempImg+`</span><span class="duncuo-control-close">&#215;</span></span><span class="duncuo-control-brackets" contenteditable="false" style="color:#1776FF;">「</span><span style="display: none"  class="audio_flag">`+matchText[0].substring(0,5)+`<span style="display: none"  class="audio_flag">`+matchText[0].substring(5)+`</span></span><span contenteditable="true" style="outline: none" class="duncuo-control-content">`+content+`</span><span style="display: none"  class="audio_flag end_flag">!to</span><span style="display: none"  class="audio_flag end_flag">ne#</span><span class="duncuo-control-brackets" contenteditable="false" style="color:#1776FF;">」</span></span>`;
      str = str.substring(0,matchText.index) + template+str.substring(content.length+ matchText.index+matchText[0].length);
      matchText = str.match(/#tone\|+[a-z]+\|/);
  }
  return str;
}

/**
 *数字读音html
 *
 * @export
 * @param {*} text
 * @return {*} 
 */
 export function changeReadNumberToHtml(text){
    var str = text;
    var matchText = str.match(/\[r=d\]|\[r=t\]|\[r=c\]/);
    while(matchText != null){
        let tempImg = ``;
        switch(matchText[0]) {
            case "[r=t]":   // 电话
                tempImg = `<img  class="number-control-text" style="cursor: pointer;width: 60px;height: 24px;position: relative;top: 5px;right: 0;margin-left: 3px" src=${require("../assets/img/telephone_img.png")} />`
            break;
            case "[r=d]":   // 数值
                tempImg = `<img  class="number-control-text" style="cursor: pointer;width: 60px;height: 24px;position: relative;top: 5px;right: 0;margin-left: 3px" src=${require("../assets/img/digit_img.png")} />`
            break;
            case "[r=c]":    // 序列
                tempImg = `<img  class="number-control-text" style="cursor: pointer;width: 60px;height: 24px;position: relative;top: 5px;right: 0;margin-left: 3px" src=${require("../assets/img/order_img.png")} />`
            break;
            default: 
            break;
        }
        let startSign1 = matchText[0].substring(0,2);
        let startSign2 = matchText[0].substring(2);
        let endSign = str.indexOf("[r]",matchText.index); // 结束协议
        if(endSign != -1) {
            str = str.replace("[r]","");
            let length = matchText[0].length;
            let content = str.substring(matchText.index+length,endSign);
            const template = `<span style="white-space: nowrap;"><span class="number_flag" style="display: none">${startSign1}<span style="display: none">${startSign2}</span></span><span contenteditable="false" class="number-control-content" style="outline: none;text-decoration: underline;text-decoration-color: #189DFF;color: #189DFF;font-size: 16px;">${content}</span><span class="number_flag" style="display: none">[</span><span style="display: none">r]</span><span contenteditable="false" class="number-control-label" style="position: relative;color: #fff;cursor: pointer;margin-right: 10px;"><span class="number-control-text" style="position: relative;display: inline-block;width:60px">${tempImg}</span><span class="number-control-close" style="position: absolute;position: absolute;right: 2px;">&#215;</span></span></span>`;
            str = str.substring(0,matchText.index) + template+str.substring(content.length+ matchText.index+matchText[0].length);
            matchText = str.match(/\[r=d\]|\[r=t\]|\[r=c\]/);
        }else {
            matchText = null;
        }
    }
    return str;
}

/**
*计算视图模式的字数
*
* @export
* @param {*} id
* @return {*} 
*/
export function computeAmount(id) {
  let text = document.getElementById(id).textContent;
  let reg = /×|「|」/g;
  let reg1 = /倍\|/g;
  text = text.replace(reg,"").replace(reg1,"|");
  return text
}

export function computeAmount1(id) {
    let text = document.getElementById(id).textContent;
    let reg = /×|「|」/g;
    let reg1 = /倍\|/g;
    let reg2 = /\n/g
    text = text.replace(reg,"").replace(reg1,"|").replace(reg2,"，|br，");
    return text
  }

export function delectOneStyle(dom,childrenIndex) {
  // let dom = e.target.parentNode;
  if (dom) {
      let tempStr = document.createTextNode(dom.parentNode.children[childrenIndex].textContent);
      dom.parentNode.parentNode.insertBefore(tempStr,dom.parentNode);
      dom.parentNode.parentNode.removeChild(dom.parentNode);
      var selection = getSelection();
      selection.removeAllRanges();
  }
}
/**
 *
 *
 * @export
 * @param {*} txt1  光标选中的文本节点1
 * @param {*} txt2 光标选中的文本节点2
 * @param {*} index1  光标index1
 * @param {*} index2  光标index1
 * @param {*} selectTxt 选中的文本
 */
export function isTextContainBr(selectionDirection,txt1,txt2,index1,index2,selectTxt) {
    
    let myTxt = {};
    // 如果相等，就证明所选的区域只有一个节点，即没有换行
    if (txt1 == txt2) {
        // 从后往前选中
        if(selectionDirection == "goBack") {
            myTxt.content1 = txt1.substring(index1,selectTxt.length+index1);
            myTxt.content2 = "";
        }else {
            myTxt.content1 = txt1.substring(index1,selectTxt.length+index1);
            myTxt.content2 = "";
        }
    }else {
        
        //从后往前选
        if(selectionDirection == "goBack") {
            myTxt.content1 = txt1.substring(index1);
            myTxt.content2 = txt2.substring(0,index2);
        }else {
            myTxt.content1 = txt1.substring(index1);
            myTxt.content2 = txt2.substring(0,index2);
        }
    }
    return myTxt;
}

/**
 *
 *
 * @export   删除局部变速的节点
 * @param {*} dom  当前节点
 * @return {*} 
 */
export function getNodeTxtwhenDeleteSpeed(dom) {
    let currentNodeTxt = dom.children[2].textContent;
    let currentNode = dom;
    let previousSiblingTxt = "";
    let nextSiblingTxt = "";
    for(var i = 0; i< 1000; i++) {
        // 如果一直有上一个节点，那就一直找，找到没有上一个节点为止
        if(currentNode.previousSibling) {
            previousSiblingTxt = currentNode.previousSibling.textContent + previousSiblingTxt;
            currentNode = currentNode.previousSibling;
        }else {
            i = 1000;
        }
    }
    for(var j=0; j<1000; j++) {
        if(dom.nextSibling) {
            nextSiblingTxt += dom.nextSibling.textContent;
            dom = dom.nextSibling
        }else {
            j = 1000;
        }
    }
    return previousSiblingTxt + currentNodeTxt + nextSiblingTxt;
}
/**
 *
 *
 * @export      删除数字读音的节点
 * @param {*} dom 当前的数字读音的节点
 * @return {*} 
 */
export function getNodeTxtwhenDeleteReadNumber(dom) {
    console.log("pppppppppppppppp",dom);
    let currentNodeTxt = dom.children[1].textContent;
    let currentNode = dom;
    let previousSiblingTxt = "";
    let nextSiblingTxt = "";
    for(var i = 0; i< 1000; i++) {
        // 如果一直有上一个节点，那就一直找，找到没有上一个节点为止
        if(currentNode.previousSibling) {
            previousSiblingTxt = currentNode.previousSibling.textContent + previousSiblingTxt;
            currentNode = currentNode.previousSibling;
        }else {
            i = 1000;
        }
    }
    for(var j=0; j<1000; j++) {
        if(dom.nextSibling) {
            nextSiblingTxt += dom.nextSibling.textContent;
            dom = dom.nextSibling
        }else {
            j = 1000;
        }
    }
    return previousSiblingTxt + currentNodeTxt + nextSiblingTxt;
}

export function openChooseModeDialog(currentTarget,type,val) {
    let dom = document.getElementById("editor");
    
    // 变速
    if(type == "changeSpeed") {
        let currentNode = currentTarget.parentNode.parentNode.parentNode;
        // 切换对应变速的对应协议
        currentNode.children[0].children[0].children[0].innerHTML = val;
        // 切换对应的图片
        switch(val) {
            case "#%0.5":
                currentNode.children[0].children[0].children[1].src = `${require('../assets/img/0.5.png')}`;
            break;
            case "#%0.8":
                currentNode.children[0].children[0].children[1].src = `${require('../assets/img/0.8.png')}`;
            break;
            case "#%0.9":
                currentNode.children[0].children[0].children[1].src = `${require('../assets/img/0.9.png')}`;
            break;
            case "#%0.95":
                currentNode.children[0].children[0].children[1].src = `${require('../assets/img/0.95.png')}`;
            break;
            case "#%1.0":
                currentNode.children[0].children[0].children[1].src = `${require('../assets/img/1.0.png')}`;
            break;
            case "#%1.05":
                currentNode.children[0].children[0].children[1].src = `${require('../assets/img/1.05.png')}`;
            break;
            case "#%1.1":
                currentNode.children[0].children[0].children[1].src = `${require('../assets/img/1.1.png')}`;
            break;
            case "#%1.15":
                currentNode.children[0].children[0].children[1].src = `${require('../assets/img/1.15.png')}`;
            break;
            case "#%1.2":
                currentNode.children[0].children[0].children[1].src = `${require('../assets/img/1.2.png')}`;
            break;
            case "#%1.25":
                currentNode.children[0].children[0].children[1].src = `${require('../assets/img/1.25.png')}`;
            break;
            case "#%1.3":
                currentNode.children[0].children[0].children[1].src = `${require('../assets/img/1.3.png')}`;
            break;
            case "#%1.5":
                currentNode.children[0].children[0].children[1].src = `${require('../assets/img/1.5.png')}`;
            break;
            default:
                break;
        }

    }else if(type == 'digital') {
        switch(val) {
            case "=t]": 
                currentTarget.parentNode.parentNode.parentNode.children[4].children[0].children[0].src = 
                `${require('../assets/img/telephone_img.png')}`;
            break;
            case "=c]": 
                currentTarget.parentNode.parentNode.parentNode.children[4].children[0].children[0].src = 
                `${require('../assets/img/order_img.png')}`;
            break;
            case "=d]": 
                currentTarget.parentNode.parentNode.parentNode.children[4].children[0].children[0].src = 
                `${require('../assets/img/digit_img.png')}`;
            break;
        }
        currentTarget.parentNode.parentNode.parentNode.children[0].children[0].innerHTML=val
    }
    
    dom.focus(); 
}


export function simillarWord(list) {
    let text = "";
    list.forEach((v,i) => {
        if(v.hasSynonym) {
            let dom = `<span style="color: #0093FF;position: relative;" class="my_simillar_word_contronl_box" contenteditable="false"><span class="origin_word" style="display: none">${v.word}</span><span class="my_simillar_word_contronl">${v.word}</span></span>`;
            text += dom;
        }else {
            text += v.word;
        }
    })
    return text
}

export function createSimillarWordItem(e) {
   
    let id = new Date().getTime();
    let b = e.target.getBoundingClientRect();
    let x1 = b.left;
    let y1 = b.top + b.height;
   
    

    let template = `<div class="toastCon" id="${id}" style="position: fixed;top:${y1+8}px; width:19rem;border-radius: .7rem;background: #FFFFFF;box-shadow: 0 .3rem .6rem .1rem rgba(0,0,0,0.16);"><div class="upCon" style="width:19rem;padding: .5rem  0 1.2rem 1rem;background: linear-gradient(132deg, #C3E0F1 0%, #FFFFFF 100%);border-radius: .7rem .7rem 0 0 ;display: flex;flex-wrap: wrap;align-items: center;justify-content: center;"></div><div class="downCon" style="height: 3.2rem;width: 100%;background: transparent;border-radius: 0 0 .7rem .7rem ;padding:.8rem;text-align: center;box-sizing: border-box;font-size: 1.1rem;font-family: Microsoft YaHei-Regular, Microsoft YaHei;font-weight: 400;color: #333333;">恢复原文案</div></div>`
    let newNode = document.createRange().createContextualFragment(template);
    
    let param = {
        text: e.target.innerText
    }
    server.editor.getSimillarListByWord(param).then(res => {
        let box = document.getElementsByClassName("upCon");
        res.data.data.forEach(v => {
            let itemDom = document.createElement("div");
            itemDom.style = `white-space: nowrap;height: 2.2rem;line-height: 2.2rem;background: #FFFFFF;border-radius: .6rem;border: .1rem solid #212121;text-align: center;padding:0 .7rem;font-size: 1.3rem;font-family: Microsoft YaHei-Regular, Microsoft YaHei;font-weight: 400;color: #333333;margin-right: 1rem;margin-top:.7rem`;
            itemDom.innerText = v;
            
            box[0].appendChild(itemDom);
            itemDom.addEventListener("click",function(q) {
                q.preventDefault();
                e.target.parentNode.children[1].innerText = q.target.innerText;
                closeMyDialog("toastCon");
            })
        })
        
        let bottomBtn = document.getElementsByClassName("downCon")[0];
        bottomBtn.addEventListener("click",function(o) {
            o.preventDefault();
            e.target.parentNode.children[1].innerText = e.target.parentNode.children[0].innerText;
            closeMyDialog("toastCon");
        })
    })
    document.body.appendChild(newNode);
    let myBox = document.getElementById(id);
    if(y1>=437){
        y1 = b.top-myBox.getBoundingClientRect().height-70;
        myBox.style.top = y1 + "px";
        
   }
    
   
   if(x1>200){
        myBox.style.right = 19+ "px";
        
   }else{
        x1 = b.left;
        myBox.style.left = x1-8 + "px";
       
   }

    // if(x1>= 190 && x1<= 260) {
    //     x1 = x1/1.5;
    //     myBox.style.left = x1 + "px";
    // }else if(x1< 190) {
    //     x1 = b.left;
    //     myBox.style.left = x1 + "px";
    // }else {
    //     myBox.style.right = window.screen.width- x1 - x1*0.13 + "px";
    // }

}

export function closeMyDialog(className) {
    if(className == "toastCon" && document.getElementsByClassName("toastCon")[0]) {
        document.getElementsByClassName("toastCon")[0].parentNode.removeChild(document.getElementsByClassName("toastCon")[0]);
    }
}
