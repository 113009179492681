<template>
	<div class="main">
    <!-- <myNavbar title="多音字"></myNavbar> -->
		<div class="top">
			<div>点击需要纠正的多音字，选择正确的发音 </div>
			<div>【<span style="color: red;">(拼音 声调)声调5代表轻声，v代表ü</span>】</div>
		</div>

		<div class="center">
			<span v-for="(item,index) in textList" :key="index" style="margin-right: 0.9rem;">
				<span v-if="item.isMulti" style="color:red"
					@click="goSelect(item,index)">{{item.word}}{{item.select?'#?'+item.select+'#':''}}</span>
				<span v-if="item.word != '，|br，'&& !item.isMulti">{{item.word}}</span>
			</span>
		</div>
		<div class="bottom-btn">
			<div class="my-btn-pain" @click="goBack" :class="channel== 'fromWX'? 'wx_btn_border1':'app_btn_border1'">返回</div>
			<div class="my-btn" :class="channel=='fromWX'? 'wx_btn_bgc': 'app_btn_bgc'" @click="goFinish">确认使用该文案</div>
		</div>
		<van-action-sheet v-model="showSheetFlag" :actions="pinyinList" cancel-text="取消" @select="onSelect" close-on-click-action @cancel="onCancel" />
	</div>
</template>

<script>
import { Toast } from 'vant';
// import myNavbar from "../components/navbar/navbar.vue"
	import {
		editor
	} from "../api/server.js"
	export default {
    components: {
      // myNavbar
    },
		data() {
			return {
				pinyinDialog: true,
				textList: [],
				selectList: [],
				tampWordArr: [],
				origin: "",
				pinyinList: [],
				showSheetFlag: false,
				currentIndex: 0,
				channel: "",
			}
		},
		methods: {
			goBack() {
				this.$emit("goFinish",100);
			},
      onClickLeft() {
        console.log("111");
      },
      onClickRight() {
        console.log("222");
      },
			goFinish() {
				let content = "";
				this.textList.forEach(v => {
					content = v.select ? `${content}${v.word}#?${v.select}#` : `${content}${v.word}`
				})
				localStorage.setItem("content",content);
				this.$emit("goFinish",100);
			},
			goSelect(item, index) {
				// let that = this;
				if (item.selectList && item.selectList.length > 0) {
					// uni.showActionSheet({
					// 	itemList: item.selectList,
					// 	success(res) {
					// 		console.log(res.tapIndex)
					// 		item.select = item.selectList[res.tapIndex];
					// 		const tampList = JSON.parse(JSON.stringify(that.textList));
					// 		tampList[index] = item;
					// 		that.textList = JSON.parse(JSON.stringify(tampList))
					// 	},
					// 	fail(res) {
					// 		item.select = '';
					// 		const tampList = JSON.parse(JSON.stringify(that.data.textList));
					// 		tampList[index] = item;
					// 		this.textList = JSON.parse(JSON.stringify(tampList))
					// 	}
					// })
				} else {
					this.getChineseToPinyin(item, index);
				}
			},
			getChineseToPinyin(item, index) {
				this.currentIndex = index;
				editor.getChineseToPinyin({
					text: item.word
				}).then(res => {
					Toast.clear();
					if (res.data && res.data.data.length > 0) {
						res.data.data.forEach(e => {
							this.pinyinList.push({name: e})
						})
						this.showSheetFlag = true;
					}
				})
			},
			getArrayLast(arr) {
				return arr && arr.length > 0 ? arr[arr.length - 1] : null;
			},
			getBeginContent() {
				let text = localStorage.getItem("content");
				let startText = "";
				let sign = "";
				let signText = "";
				let tampArr = [];
				let matchText = text.match(/#\?.{1,20}?#/);
				while(matchText != null) {
					startText = text.substring(0,matchText.index-1);
					signText = text.substring(matchText.index-1,matchText.index);
					sign = matchText[0].substring(2,matchText[0].length-1);
					text = text.substring(matchText.index+matchText[0].length);
					matchText = text.match(/#\?.{1,20}?#/);
					let arr = startText.split("");
					tampArr = tampArr.concat(arr);
					let multiArr = {};
					multiArr.word = signText;
					multiArr.select = sign;
					tampArr.push(multiArr);
					
				}
				tampArr = tampArr.concat(text.split(""));
				return tampArr
			},

			isChineseToPinyin() {
				Toast.loading({
					message: '加载中...',
					forbidClick: true,
					loadingType: 'spinner'
				});
				let reg = /#\?.{1,20}?#/g;
				editor.isChineseToPinyin({
					text: localStorage.getItem("content").replace(reg,"")
				}).then(res => {
					Toast.clear();
					if (res) {
						for(var i = 0; i< res.data.data.length; i++) {
							if(res.data.data[i].word == "，" && res.data.data[i+1].word == "|") {
								res.data.data[i].word = res.data.data[i].word + res.data.data[i+1].word + 
																				res.data.data[i+2].word + res.data.data[i+3].word + 
																				res.data.data[i+4].word
								res.data.data.splice(i+1,1);
								res.data.data.splice(i+1,1);
								res.data.data.splice(i+1,1);
								res.data.data.splice(i+1,1);
							}
						}
						res.data.data.forEach((v,i) => {
							v.select = '';
							v.selectList = [];
						});
						// if (this.tampWordArr.length > 0) {
						// 	const tampArr = this.tampWordArr.filter(v => v.select);
						// 	tampArr.forEach(v => {
						// 		res[v.index].select = v.select;
						// 	})
						// }
						let tampArr = this.getBeginContent();
						tampArr.findIndex(v => {
							res.data.data.forEach((e,i) => {
								if(v.word == e.word) {
									res.data.data[i].select = v.select;
								}
							})
						})
						
						this.textList = res.data.data;
					}
				}).catch(err => {
					console.log("morevoiceerr=", err);
					Toast.clear();
					Toast.fail({message:"网络异常"});
				})
			},

			onCancel() {
				this.pinyinList = [];
				this.showSheetFlag = false;
			},

			onSelect(e) {
				console.log("xxxxxxx",e);
				this.textList[this.currentIndex].select = e.name;
				this.pinyinList = [];
			},
			
			
		},
		mounted() {
			this.isChineseToPinyin();
			this.channel = localStorage.getItem("channel");
		}
	}
</script>

<style lang="less" scoped>
/deep/.van-action-sheet__item {
	font-size: 1.4rem;
	// padding: 1.5rem;
	height: 4rem;
	border-bottom: 1px solid #dadada;
}
/deep/.van-action-sheet__item:last-child {
	font-size: 1.4rem;
	// padding: 1.5rem;
	height: 4rem;
	border-bottom: 0;
}
/deep/.van-action-sheet__cancel::before {
	background-color: #fff;
}
/deep/.van-action-sheet__cancel {
	font-size: 1.4rem;
	// padding: 1rem;
	height: 4rem;
}
	.main {
		width: 100%;
		padding: 0.4rem;
		box-sizing: border-box;
		font-size: 1.4rem;
		color: #333;
	}

	.top {
		padding: 1.4rem 0.9rem;
		width: 100%;
		box-sizing: border-box;
		display: flex;
		align-items: center;
		flex-direction: column;
		border-bottom: 1rpx solid rgba(0, 0, 0, .3);
	}

	.center {
		width: 100%;
		padding: 1.4rem 0.9rem;
		box-sizing: border-box;
		height: 70vh;
		overflow: scroll;
    border-top: 1px solid #dadada;
	}

	.bottom-btn {
		display: flex;
		align-items: center;
		justify-content: center;
		margin-top: 1.8rem;
	}

	.my-btn {

		width: 45%;
		height: 4.9rem;
		line-height: 4.9rem;
		border-radius: 0.5rem;
		text-align: center;
		// background-color: #1AAD19;
		color: white;
		font-size: 1.4rem;
	}

	.my-btn-pain {
    margin-right: 1rem;
		width: 45%;
		height: 4.9rem;
		line-height: 4.9rem;
		border-radius: 0.5rem;
		text-align: center;
		// border: 1px solid rgba(26, 173, 25, 100);
		// color: #1AAD19;
		font-size: 1.4rem;
		box-sizing: border-box;
	}
	
	.pinyin_dialog {
		padding: 1rem;
	}
</style>
