<template>
  
    <!-- <van-popup v-model="show" round position="bottom" :style="{ height: '40.1rem' }" > -->
       <!-- <div class="container" v-if="show"> -->
       <div class="container">
           <div class="header">
               <span>检测出{{dataList?dataList.length:0}}个问题</span>
               <div style="display:flex">
                     <div class="keyborad" @click="inputFocus">
                            <img src="../../assets/img/keyboard.png" style="width:2rem;height:1.6rem;">
                    </div>
                    <div @click="close">
                            <img src="../../assets/img/verifyListClose.png" style="width:1.3rem;height:.8rem">
                    </div>
               </div>
           </div>

         <!--校验输出列表-->

         <div class="panel" >
               <div class="panel-list"   v-for="(item,index) in dataList" :key="index">

                       <div class="panel-list-item"   v-show="true" :id="'pList'+index" @click="showOperate(index)" >
                           <span>{{item.word}}</span>
                           <span class="errorType">{{item.remark}}</span>
                       </div>
                       <div class="panel-list-operate" v-show="false" :id="'pListOpe'+index">
                           <div class="top">
                               <span class="originText">{{item.word}}</span>
                               <img src="../../assets/img/verifyRight.png" class="icon">
                               <div class="verifyText">{{item.correctWord}}</div>
                           </div>
                           <div class="centerTip">检测到{{item.remark}}，建议将“{{item.word}}”修改为“{{item.correctWord}}”</div>
                           <div class="bottom">
                               <div class="feeOfChange" @click="toFeeofChange(item.word)">
                                   <img src="../../assets/img/TofeeOfChange.png" style="height:1.4rem;width:1.4rem;">
                                   <span>设为免改词</span>
                               </div>
                               <div class="btn">
                                   <div class="ignore" @click="toIgnoreVerifyWord(item.word,index)">忽略</div>
                                   <div class="change" @click="toChangeVerifyWord(item.correctWord,index)">修改</div>
                               </div>
                           </div>
                       </div>
               </div>

         </div>




       </div>


   <!-- </van-popup> -->


</template>

<script>
import {
   editor
} from "../../api/server.js"
import { Toast } from 'vant';
export default {

   props:['verifyDataList','isShow'],
   data(){
       return{
           text:"",
           currentIndex:-1,
           dataList:[],
          
           
       }
   },
   watch:{
    verifyDataList() {
        this.dataList = this.verifyDataList;
    },
    isShow(){
        if(this.isShow){
            if(this.currentIndex != -1){
            let curListInd = 'pList'+this.currentIndex;
            let curListOpeInd = 'pListOpe'+this.currentIndex;
            document.getElementById(curListInd).style.display ='block';//显示上一个list
            document.getElementById(curListOpeInd).style.display = 'none';//隐藏上一个operate
            this.currentIndex = -1;
        }
        }
        
    }
   },
   methods:{
    inputFocus() {
        this.$emit("focusMyEditor");
    },
       showOperate(i){
           let listInd = 'pList'+i;
           let listOpeInd = 'pListOpe'+i;

           if(this.currentIndex!= -1 ){
               let curListInd = 'pList'+this.currentIndex;
               let curListOpeInd = 'pListOpe'+this.currentIndex;
               document.getElementById(curListInd).style.display ='block';//显示上一个list
               document.getElementById(curListOpeInd).style.display = 'none';//隐藏上一个operate

           }
            document.getElementById(listInd).style.display = 'none';//隐藏当前点击的list
            document.getElementById(listOpeInd).style.display ='block';//显示当前点击位置的ope
            this.currentIndex = i;

            this.$emit('clickVerifyList',i);

       },

       toFeeofChange(word){

           this.$emit('todo'); //关闭校验弹窗
           this.$emit('showFeeofChange','2',word);//开启免改的弹窗
       },
       //修改内容
       toChangeVerifyWord(val,i){
            this.currentIndex = -1;
            this.$emit('toChangeVerifyWord',val,i);
       },
       toIgnoreVerifyWord(val,i){
            this.currentIndex = -1;
            this.$emit('toIgnoreVerifyWord',val,i)
       },
       close(){
            this.$emit('toCloseVerifyList');
       }
       //进行校验
    //    toVerifyWord(){
    //        Toast.loading({
    //            message: '校验检测中...',
    //            forbidClick: true,
    //            duration: 0,
    //            loadingType: 'spinner'
    //        });
    //        editor.verifyWord({
    //            text:this.text
    //        }).then(res => {
    //            Toast.clear();
    //            if(res.data.data.resultList === null || res.data.data.resultList.length === 0) return;
    //            let newContent="";
    //            this.dataList = res.data.data.resultList;

    //            for(let i =0;i<this.dataList.length;i++){
    //                let correctWord = this.dataList[i].word;
    //                let template = `<span class="verifyWord" style="display:inline-block;border-bottom:.2rem solid #9C87D9;">${correctWord}</span>`;
    //                let txt="";
    //                if(i==0){
    //                     txt = this.text.substring(0,this.dataList[0].offset)+template;
    //                }else{
    //                    txt = this.text.substring(this.dataList[i-1].offset+this.dataList[i-1].word.length,this.dataList[i].offset)+template;
    //                }
    //                newContent =newContent+txt;
    //            }
    //            let last = this.dataList.length-1;

    //            newContent =newContent + this.text.substring(this.dataList[last].offset+this.dataList[last].word.length);
    //            newContent = newContent.replace(/\\n/g,`<br>`);
    //            document.getElementById('editor').innerHTML = newContent;

    //            this.$nextTick(_=>{
    //                 let arr = document.querySelectorAll("span.verifyWord");
    //                 let that = this;
    //                 for(let i = 0;i<arr.length;i++){
    //                     arr[i].onclick = function(e){
    //                         //显示背景颜色
    //                         arr[i].style.background = "#9C87D9";
                            // that.verifyToastContent = arr[i].innerText;
                            // that.verifyCurrrentDom = arr[i];
                            // that.isShowverifyChangeToast = true;
                            // //设置弹窗的位置
                            // let i = that ;
                            // that.$nextTick(_=>{
                            //     let b = e.target.getBoundingClientRect();
                            //     let dom = document.getElementById('verifyChangeToast');
                            //     dom.style.top = (b.top+b.height+7)/10 +'rem';
                            //     if(b.left>220){
                            //         dom.style.left = '20rem';
                            //     }else{
                            //         dom.style.left = (b.left-14)/10 +'rem';
                            //     }

                            // })
//         }
//       }
//                })


//            }).catch(err =>{
//                console.log("checkerr=", err);
//                Toast.clear();
//                Toast.fail("网络异常");

//            })
//     }

   },
   mounted(){
    //    this.text = localStorage.getItem('verifyTxt');
    //    this.text? this.toVerifyWord():'';
   }

}
</script>

<style lang="less" scoped>
.container{
   position: absolute;
   bottom: 0;
   width: 100%;
   height: 37.5rem;
   background: linear-gradient(135deg, #EFECFA 0%, #F1F8FB 100%);
   border-radius: 1.4rem 1.4rem 0 0;
   z-index: 2;

}

.header{
       width: 100%;
       height: 6.1rem;
       padding: 0 2rem;
       display: flex;
       align-items: center;
       justify-content: space-between;
       box-sizing: border-box;
       font-size: 1.6rem;
       font-family:  Microsoft YaHei;
       color: #333333;
       border-bottom: .2rem solid #fff;

       .keyborad{
           width: 4.1rem;
           height: 3rem;
           background: #FFFFFF;
           border-radius: 1.2rem;
           display: flex;
           justify-content: center;
           align-items: center;
           margin-right: 1.4rem;

       }
}
.panel{
   width: 100%;
   height: 31.4rem;
   background: transparent;
   padding:.8rem;
   overflow-y: auto;
   .panel-list{

       .panel-list-item{

           height: 4rem;
           line-height: 4rem;
           background: #FFFFFF;
           border-radius: 1rem;
           padding:0 1.2rem;
           box-sizing: border-box;
           font-size: 1.4rem;
           font-family:Microsoft YaHei;
           color: #333333;
           margin-bottom: .5rem;

           .errorType{
               font-size: 1.1rem;
               display: inline-block;
               margin-left: 1.2rem;

           }



       }

       .panel-list-operate{

        //    height: 12.5rem;
           background: #FFFFFF;
           border-radius: 1rem;
           padding:1.6rem 1.2rem 1rem 1.2rem;
           margin-bottom: .5rem;
           position: relative;

           .top{
               display: flex;

               .originText{
                //    height: 2.8rem;
                    min-width: 0;
                    max-width:15.3rem;
                   line-height: 2.8rem;
                   font-size: 1.7rem;
                   font-family: Microsoft YaHei-Regular, Microsoft YaHei;
                   font-weight: 400;

               }
               .icon{
                   width: 1.7rem;
                   height: 1.4rem;
                  
                   margin:.7rem 1.2rem 0 1.2rem;
               }
               .verifyText{
                   padding:.3rem 1.4rem;
                    min-width: 0;
                    max-width:15.3rem;
                   
                   box-sizing: border-box;
                   background: #D3C4FF;
                   border-radius: 1.2rem;
                   text-align: center;
                   font-size: 1.7rem;
                   font-family: Microsoft YaHei-Bold, Microsoft YaHei;
                   font-weight: bold;
                   color: #333333;


               }

           }

           .centerTip{
               margin-top:1.2rem;
               font-size: 1.2rem;
               font-family: Microsoft YaHei;
               color: #666666;

           }

           .bottom{
            //    position: absolute;
            //    bottom:1rem;
                margin-top:2rem;
               width: calc(100vw - 4rem);
               display: flex;
               justify-content: space-between;

               .feeOfChange{
                   width: 8.8rem;
                   height: 2.6rem;
                   background: #F9F9F9;
                   border-radius: .8rem;
                   padding: 0 .5rem  0  .5rem;
                   box-sizing: border-box;
                   display: flex;
                   align-items: center;
                   justify-content: space-between;
                   font-size: 1.2rem;
                   font-family:  Microsoft YaHei;
                   font-weight: 400;
                   color: #333333;
                   
               }

               .btn{
                   display: flex;
                   .ignore,.change{
                   width: 4.8rem;
                   height: 2.6rem;
                   line-height: 2.6rem;
                   border-radius: .8rem;
                   text-align: center;
                   font-size: 1.2rem;
                   font-family:  Microsoft YaHei;

               }
               .ignore{
                   background: #F9F9F9;
                   color: #333333;
                   margin-right: 1.2rem;

               }
               .change{
                   background: #000000;
                   color: #FFFFFF;
               }
           }
           }
       }
   }
}




</style>